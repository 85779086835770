import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as SvgRefreshIcon } from '../assets/images/icons8-synchronize.svg';
import { selectIsLoggedIn } from '../reducers/accountReducer';
import ConfirmationModal from './ConfirmationModal';
import useBooleanState from '../hooks/useBooleanState';

import styles from './BuildChangeMonitor.module.scss';

const BuildChangeMonitor = () => {
  const [showRefreshModal, setShowRefreshModalTrue] = useBooleanState(false);
  const isLoggedIn = useSelector(selectIsLoggedIn);

  const currentPage = useRef();

  useEffect(() => {
    if (!isLoggedIn) return;

    fetch('/')
      .then((res) => res.text())
      .then((bodyText) => {
        currentPage.current = bodyText;
      });

    // Every minute, check to see if the page has changed
    const interval = setInterval(() => {
      fetch('/')
        .then((res) => res.text())
        .then((bodyText) => {
          if (currentPage.current !== bodyText) {
            setShowRefreshModalTrue();
          }
        });
    }, 60000);

    return () => clearInterval(interval);
  }, [isLoggedIn, setShowRefreshModalTrue]);

  if (!isLoggedIn) return null;

  function handleReload() {
    window.location.reload();
  }

  return (
    <ConfirmationModal show={showRefreshModal}>
      <div className={styles.container}>
        <h4>New Updates available!</h4>
        <p>
          We've released a new version of Elastic Path Studio.
          <br />
          Please load the latest changes to avoid lost
          <br />
          work or potential errors.
        </p>
        <button className={styles.reloadPageButton} type="button" onClick={handleReload}>
          <SvgRefreshIcon />
          <span>Reload the app now</span>
        </button>
      </div>
    </ConfirmationModal>
  );
};

export default BuildChangeMonitor;
