/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useState } from 'react';
import classnames from 'classnames';

import Select from './Select';
import ColorSelector from './StyleGuideEditor/ColorSelector';

import CapitalizeSelect from './CapitalizeSelect';
import LetterSpacingField from './LetterSpacingField';

import { ReactComponent as SvgIconDesktopThumbnail } from '../../../assets/images/icon-desktop-styleguide.svg';
import { ReactComponent as SvgIconMobileThumbnail } from '../../../assets/images/icon-mobile-styleguide.svg';

import styles from './StyleGuideEditor.module.scss';

const headerActionStyleOptions = [
  { value: 'text-action', label: 'Action link' },
  { value: 'button-primary', label: 'Primary button' },
  { value: 'button-secondary', label: 'Secondary button' },
];

function getActionStyleClassName(actionStyle) {
  switch (actionStyle) {
    case 'button-primary':
      return 'button button-primary';
    case 'button-secondary':
      return 'button button-secondary';
    case 'text-action':
    default:
      return 'text-action';
  }
}

export default ({
  fontFamilyOptions,
  styleGuide,
  fontWeightOptions,
  handleChange,
  handleChangeEvent,
  darkBgColor,
  name,
  ...props
}) => {
  const [activeStyleTabs, setActiveStyleTabs] = useState('fieldText');
  const [activeBackground, setActiveBackground] = useState('light');

  // Reusable components

  function fontFamilySelector({ label, name }) {
    // Use paragraph's font family by default
    const index = styleGuide.styles[name] ?? styleGuide.paragraph_font_family_index;

    return (
      <div className={styles.styleOption}>
        <span>{label}</span>
        <Select
          options={fontFamilyOptions}
          defaultValue={fontFamilyOptions[index]}
          value={fontFamilyOptions[index]}
          onChange={(option) => handleChange([name], option.value)}
        />
      </div>
    );
  }

  function fontWeightSelector({ label, name }) {
    return (
      <div className={styles.styleOption}>
        <span>{label}</span>
        <Select
          options={fontWeightOptions}
          defaultValue={fontWeightOptions.find((weight) => weight.value === Number(styleGuide.styles[name]))}
          value={fontWeightOptions.find((weight) => weight.value === Number(styleGuide.styles[name]))}
          onChange={(option) => handleChange([name], option.value)}
        />
      </div>
    );
  }

  function numberSelector({ label, name, min, max, step }) {
    return (
      <div className={styles.styleOption}>
        <span>{label}</span>
        <input
          type="number"
          name={name}
          value={styleGuide.styles[name]}
          min={min}
          max={max}
          step={step}
          onChange={(e) => handleChange([name], e.target.value)}
        />
      </div>
    );
  }

  function colorSelector({ label, name }) {
    return (
      <div className={styles.styleOption}>
        <span>{label}</span>
        <ColorSelector
          name={name}
          onChange={(value) => handleChange([name], value)}
          value={styleGuide.styles[name]}
          placeholder="Color"
          handleColorSelection={(value) => handleChange([name], value)}
        />
      </div>
    );
  }

  // Render

  return (
    <>
      <header ref={props.scrollRef} className={styles.topSpacing}>
        <h1>Forms</h1>
        <small>Set the form styles used across your site.</small>
      </header>

      {/* Check list */}
      <article className={styles.StyleGuideEditor}>
        <section className={styles.styleSection}>
          <div className={styles.styleSectionHeader}>
            <p>Form</p>
            <div className={styles.device}>
              <span
                onClick={() => setActiveBackground('light')}
                className={classnames(styles.lightBackground, {
                  [styles.active]: activeBackground === 'light',
                })}
              >
                <SvgIconDesktopThumbnail />
              </span>
              <span
                onClick={() => setActiveBackground('mobile')}
                className={classnames(styles.darkBackground, {
                  [styles.active]: activeBackground === 'mobile',
                })}
              >
                <SvgIconMobileThumbnail />
              </span>
            </div>

            {/* <p>&nbsp;</p>
            <div className={styles.background}>
              <span
                className={classnames(styles.lightBackground, {
                  [styles.active]: activeBackground,
                })}
                onClick={() => setActiveBackground(true)}
              >
                Light bg
              </span>
              <span
                className={classnames(styles.darkBackground, {
                  [styles.active]: !activeBackground,
                })}
                onClick={() => setActiveBackground(false)}
              >
                Dark bg
              </span>
            </div> */}
          </div>
          {/* Example container */}
          <div
            style={{
              ...(activeBackground === 'dark' && {
                backgroundColor: darkBgColor.code,
                padding: '1rem 2rem 0.1rem',
                marginBottom: '2rem',
                borderRadius: '2px',
              }),
            }}
          >
            <div
              className={`sriracha ${
                activeBackground === 'dark' ? 'dark-bg' : activeBackground === 'mobile' ? 'mobile' : 'light-bg'
              }`}
            >
              {/* Example */}
              <div className="form-embed form-container unstack-form-container form-embed-card">
                <div className="unstack-form">
                  <div className="row">
                    <div className="col">
                      <fieldset>
                        <label>Label</label>
                        <input type="text" placeholder="Placeholder" />
                      </fieldset>
                    </div>
                    <div className="col">
                      <fieldset>
                        <label>Label</label>
                        <input type="text" placeholder="Placeholder" />
                      </fieldset>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <fieldset>
                        <label>Label</label>
                        <input type="text" placeholder="Placeholder" />
                        <small>Help text underneath fields</small>
                      </fieldset>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <fieldset>
                        <input type="submit" className="button button-primary button-full" value="Submit" />
                      </fieldset>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Tabs */}
          <div className={styles.optionsHeader}>
            <span
              onClick={() => setActiveStyleTabs('fieldText')}
              className={classnames({
                [styles.activeStyleTab]: activeStyleTabs === 'fieldText',
              })}
            >
              Field text
            </span>
            <span
              onClick={() => setActiveStyleTabs('fieldShape')}
              className={classnames({
                [styles.activeStyleTab]: activeStyleTabs === 'fieldShape',
              })}
            >
              Field shape
            </span>
            <span
              onClick={() => setActiveStyleTabs('label')}
              className={classnames({
                [styles.activeStyleTab]: activeStyleTabs === 'label',
              })}
            >
              Labels
            </span>
            <span
              onClick={() => setActiveStyleTabs('helpText')}
              className={classnames({
                [styles.activeStyleTab]: activeStyleTabs === 'helpText',
              })}
            >
              Help text
            </span>
            <span
              onClick={() => setActiveStyleTabs('spacing')}
              className={classnames({
                [styles.activeStyleTab]: activeStyleTabs === 'spacing',
              })}
            >
              Spacing
            </span>
          </div>
          <div className={styles.styleSectionOptions}>
            {/* FIELD TEXT */}
            {activeStyleTabs === 'fieldText' &&
              (activeBackground === 'light' ? (
                <>
                  {fontFamilySelector({
                    label: 'Font family',
                    name: 'form_field_font_family_index',
                  })}
                  {fontWeightSelector({
                    label: 'Font weight',
                    name: 'form_field_font_weight',
                  })}
                  {numberSelector({
                    label: 'Font size',
                    name: 'form_field_font_size',
                    min: '1',
                    max: '3',
                    step: '0.1',
                  })}
                  {colorSelector({
                    label: 'Font color',
                    name: 'form_field_font_color',
                  })}
                  {colorSelector({
                    label: 'Placeholder color',
                    name: 'form_field_placeholder_color',
                  })}
                </>
              ) : (
                <>
                  {numberSelector({
                    label: 'Font size',
                    name: 'form_field_font_size_mobile',
                    min: '1',
                    max: '3',
                    step: '0.1',
                  })}
                </>
              ))}

            {/* FIELD SHAPE */}
            {activeStyleTabs === 'fieldShape' &&
              (activeBackground === 'light' ? (
                <>
                  {numberSelector({
                    label: 'Padding x',
                    name: 'form_field_padding_x',
                    min: '0',
                    max: '3',
                    step: '0.1',
                  })}
                  {numberSelector({
                    label: 'Padding y',
                    name: 'form_field_padding_y',
                    min: '0',
                    max: '3',
                    step: '0.1',
                  })}
                  {numberSelector({
                    label: 'Border radius',
                    name: 'form_field_border_radius',
                    min: '0',
                    max: '99999',
                    step: '1',
                  })}
                  {colorSelector({
                    label: 'Background',
                    name: 'form_field_background_color',
                  })}
                  {colorSelector({
                    label: 'Border',
                    name: 'form_field_border_color',
                  })}
                  {colorSelector({
                    label: 'Focus border',
                    name: 'form_field_focus_border_color',
                  })}
                </>
              ) : (
                <>
                  {numberSelector({
                    label: 'Padding x',
                    name: 'form_field_padding_x_mobile',
                    min: '0',
                    max: '3',
                    step: '0.1',
                  })}
                  {numberSelector({
                    label: 'Padding y',
                    name: 'form_field_padding_y_mobile',
                    min: '0',
                    max: '3',
                    step: '0.1',
                  })}
                  <div className={styles.styleOption}>
                    <span>{'Border radius'}</span>
                    <input
                      type="number"
                      name={'form_field_border_radius_mobile'}
                      value={
                        styleGuide.styles['form_field_border_radius_mobile'] ||
                        styleGuide.styles['form_field_border_radius']
                      }
                      min={'0'}
                      max={'99999'}
                      step={'1'}
                      onChange={(e) => handleChange(['form_field_border_radius_mobile'], e.target.value)}
                    />
                  </div>
                </>
              ))}

            {/* LABEL */}
            {activeStyleTabs === 'label' &&
              (activeBackground === 'light' ? (
                <>
                  {fontFamilySelector({
                    label: 'Font family',
                    name: 'form_label_font_family_index',
                  })}
                  {fontWeightSelector({
                    label: 'Font weight',
                    name: 'form_label_font_weight',
                  })}
                  {numberSelector({
                    label: 'Font size',
                    name: 'form_label_font_size',
                    min: '1',
                    max: '3',
                    step: '0.1',
                  })}
                  <CapitalizeSelect
                    value={styleGuide.styles.form_label_text_transform}
                    handleChange={(value) => handleChange(['form_label_text_transform'], value)}
                  />
                  <LetterSpacingField
                    name="form_label_letter_spacing"
                    value={styleGuide.styles.form_label_letter_spacing}
                    handleChange={(value) => handleChange(['form_label_letter_spacing'], value)}
                  />
                  {colorSelector({
                    label: 'Font color',
                    name: 'form_label_font_color',
                  })}
                </>
              ) : (
                <>
                  {numberSelector({
                    label: 'Font size',
                    name: 'form_label_font_size_mobile',
                    min: '1',
                    max: '3',
                    step: '0.1',
                  })}
                  <LetterSpacingField
                    name="form_label_letter_spacing_mobile"
                    value={
                      styleGuide.styles.form_label_letter_spacing_mobile || styleGuide.styles.form_label_letter_spacing
                    }
                    handleChange={(value) => handleChange(['form_label_letter_spacing_mobile'], value)}
                  />
                </>
              ))}

            {/* HELP TEXT */}
            {activeStyleTabs === 'helpText' &&
              (activeBackground === 'light' ? (
                <>
                  {fontFamilySelector({
                    label: 'Font family',
                    name: 'form_help_font_family_index',
                  })}
                  {fontWeightSelector({
                    label: 'Font weight',
                    name: 'form_help_font_weight',
                  })}
                  {numberSelector({
                    label: 'Font size',
                    name: 'form_help_font_size',
                    min: '1',
                    max: '3',
                    step: '0.1',
                  })}
                  {colorSelector({
                    label: 'Font color',
                    name: 'form_help_font_color',
                  })}
                </>
              ) : (
                <>
                  {numberSelector({
                    label: 'Font size',
                    name: 'form_help_font_size_mobile',
                    min: '1',
                    max: '3',
                    step: '0.1',
                  })}
                </>
              ))}

            {/* SPACING */}
            {activeStyleTabs === 'spacing' &&
              (activeBackground === 'light' ? (
                <>
                  {numberSelector({
                    label: 'Form width',
                    name: 'form_width',
                    min: '250',
                    max: '800',
                    step: '10',
                  })}
                  {numberSelector({
                    label: 'Horizontal margin',
                    name: 'form_spacing_margin_x',
                    min: '0',
                    max: '50',
                    step: '1',
                  })}
                  {numberSelector({
                    label: 'Vertical margin',
                    name: 'form_spacing_margin_y',
                    min: '0',
                    max: '50',
                    step: '1',
                  })}
                </>
              ) : (
                <>
                  <div className={styles.styleOption}>
                    <span>{'Form width'}</span>
                    <input
                      type="number"
                      name={'form_width_mobile'}
                      value={styleGuide.styles['form_width_mobile'] || styleGuide.styles['form_width']}
                      min={'250'}
                      max={'800'}
                      step={'10'}
                      onChange={(e) => handleChange(['form_width_mobile'], e.target.value)}
                    />
                  </div>
                  {numberSelector({
                    label: 'Horizontal margin',
                    name: 'form_spacing_margin_x_mobile',
                    min: '0',
                    max: '50',
                    step: '1',
                  })}
                  {numberSelector({
                    label: 'Vertical margin',
                    name: 'form_spacing_margin_y_mobile',
                    min: '0',
                    max: '50',
                    step: '1',
                  })}
                </>
              ))}
          </div>
        </section>
      </article>
    </>
  );
};
