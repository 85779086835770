import React from 'react';
import { Link } from 'react-router-dom';
import ZeroState, { Group, Header, Image } from '../ZeroState';
import useUrlSite from 'hooks/useUrlSite';
import icon from 'assets/images/icon-onboarding-forms.svg';

export function FormsListZeroState() {
  const urlSite = useUrlSite();

  return (
    <div style={{ margin: '0 auto', maxWidth: '700px' }}>
      <ZeroState>
        <Image src={icon} />
        <Header>Capture customer data with Elastic Path Studio forms</Header>
        <Group>
          Elastic Path Studio's forms allow you to easily collect customer information on your website, landing pages,
          and blog. Form submissions are stored in <Link to={`/${urlSite}/customers`}>customers</Link> and you can also
          push leads to your connected integrations like MailChimp or Hubspot.
        </Group>
        <Group>
          <Link to={`/${urlSite}/forms/new`} className="button button-primary" style={{ margin: '0' }}>
            Create your first form
          </Link>
        </Group>
      </ZeroState>
    </div>
  );
}
