import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import RequireLogin from './RequireLogin';
import DocumentTitle from 'react-document-title';
import { CSSTransition } from 'react-transition-group';

import * as siteSelectors from '../reducers/sitesReducer';
import * as blogSelectors from '../reducers/blogsReducer';
import * as blogActions from '../actions/blogActions';
import * as mediaActions from '../actions/mediaActions';
import * as articleSelectors from '../reducers/articlesReducer';
import * as categoriesActions from '../actions/categoriesActions';
import * as integrationActions from '../actions/integrationActions';

import Spinner from './base/Spinner';
import AdminBar from './Editor/BlogEditorAdminBar';
import BlogCanvas from './Editor/BlogCanvas';

import idx from 'lodash/get';

import styles from './Editor.module.scss';
import { useParams } from 'react-router-dom';
import { OnMount } from 'hooks/mountUnmountHooks';

(window as any).articleSelectors = articleSelectors;

function BlogEditor() {
  const { urlSite, blogId } = useParams();
  // @ts-ignore
  const site = useSelector((state) => siteSelectors.selectSiteByDomain(state, urlSite));
  const blog = useSelector((state) => blogSelectors.selectDraft(state, blogId));
  const articles = useSelector((state) => articleSelectors.selectBlogArticles(state, blogId));
  const dispatch = useDispatch();

  const sitesFetching = site == null || site.id == null;
  const blogName = idx(blog, 'name');
  const blogContent = blog && blog.content;

  const isLoading = sitesFetching || !blogContent;

  const documentTitle = isLoading ? 'Elastic Path Studio' : `Editing "${blogName || 'New blog'}"`;

  OnMount(() => {
    dispatch(mediaActions.requestPDFs());
    dispatch(categoriesActions.requestListIfNeeded());
    dispatch(blogActions.requestIfNeeded(blogId));
    dispatch(integrationActions.requestAllIfNeeded());
    dispatch({
      type: 'READ_RESOURCES_REQUEST_IF_NEEDED',
      resourceType: 'sectionTypes',
      requestKey: 'fetch',
    });
  });

  useEffect(() => {
    dispatch(blogActions.requestIfNeeded(blogId));
  }, [blogId]);

  return (
    <RequireLogin>
      <DocumentTitle title={documentTitle}>
        <div className="Editor">
          <div className="admin">
            <AdminBar blogId={blogId} />
          </div>
          {isLoading && (
            <div className={styles.spinnerContainer}>
              <Spinner size={1} />
            </div>
          )}
          <CSSTransition in={!isLoading} timeout={500} classNames={styles.canvas} mountOnEnter unmountOnExit appear>
            {() => <BlogCanvas blogId={blogId} />}
          </CSSTransition>
        </div>
      </DocumentTitle>
    </RequireLogin>
  );
}

export default BlogEditor;
