import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router';

import UnstackSplash from 'components/UnstackSplash';

import isInIFrame from 'lib/isInIFrame';
import Spinner from 'components/base/Spinner';
import * as integrationActions from 'actions/integrationActions';

import { ReactComponent as SvgIconEP } from '../../../../assets/images/elastic-path-mark.svg';
import { ReactComponent as SvgIconShopify } from '../../../../assets/images/icon-shopify.svg';
import { ReactComponent as SvgIconAdd } from '../../../../assets/images/icon-plus.svg';

import InAppView from 'lazy/InAppView';

import styles from '../../Integrations.module.scss';

const PolarisStyle = React.lazy(() => import('./PolarisStyleModule'));

const IS_IN_IFRAME = isInIFrame();

const PolarisStyleProvider = ({ children }) => {
  return (
    <React.Suspense fallback={() => null}>
      {IS_IN_IFRAME && (
        <>
          <PolarisStyle />
          {children}
        </>
      )}
    </React.Suspense>
  );
};

export default () => {
  const dispatch = useDispatch();
  const [token, setToken] = useState(null);
  const { search } = useLocation();
  const [data, setData] = useState({});
  const navigate = useNavigate();
  const basePath = location.pathname.split('/')[1];

  useEffect(() => {
    const promise = new Promise((res, rej) => {
      dispatch(integrationActions.shopifyOAuthConnect({ search, isInIframe: IS_IN_IFRAME, basePath }, res, rej));
    });
    promise.then(({ status, url, token, site, integration, shop, user, subscription }) => {
      if (!IS_IN_IFRAME) {
        if (status === 201) window.location = url;
        else if (status === 400) navigate(`/${basePath}/failure`);
        else if (status === 409) navigate('/');
        else navigate(`/${basePath}/subscribe?subdomain=${site?.subdomain}`);
      } else {
        setToken(token);
        setData({ site, token, integration, shop, user, subscription });
      }
    });
  }, []);

  return IS_IN_IFRAME ? (
    token !== null ? (
      <PolarisStyleProvider>
        <React.Suspense fallback={<div />}>
          <InAppView updateData={(key, value) => setData({ ...data, [key]: value })} token={token} {...data} />
        </React.Suspense>
      </PolarisStyleProvider>
    ) : (
      <div>
        <UnstackSplash isLoading />
      </div>
    )
  ) : (
    <div className={styles.popup}>
      <div className={styles.logos}>
        <SvgIconEP className={styles.elasticpath} />
        <SvgIconAdd className={styles.add} />
        <SvgIconShopify className={styles.shopify} />
      </div>
      <p className={styles.title}>Setting up Elastic Path Studio</p>
      <p className={styles.instructions}>
        Do not close this window. You will be redirected to Elastic Path Studio when this process completes.
      </p>
      <div className={styles.spinner}>
        <Spinner className="fixed" />
      </div>
    </div>
  );
};
