import { select, delay, call, put, all, takeEvery } from 'redux-saga/effects';
import * as api from '../../services/spark-api';
import { actionTypes, getStatus } from 'redux-resource';
import requireSiteId from 'sagas/utils/requireSiteId';

const endpointByResourceType = {
  sectionTypes: 'component',
  resourceArticles: 'article',
  elements: 'element',
  categories: 'component_category',
  recentSections: 'component/recently_used',
};

function* fetchIfNeeded(action) {
  const { requestKey, resourceType } = action;

  // HACK: If multiple components mount and dispatch this action at the same
  // time, the status selector will yield 'IDLE' each time,  even though the
  // 'READ_RESOURCES_PENDING' action is dispatched after the first occurence. As
  // a result, each one makes the API call unnecessarily. This probably has to
  // do  with the redux store batching actions. Adding this delay effect isn't
  // seems  ideal, but appears to fix the problem for now.
  yield delay(0);

  const status = yield select((state) => getStatus(state, `${resourceType}.requests.${requestKey}.status`));

  if (status.idle) {
    yield put({
      ...action,
      type: actionTypes.READ_RESOURCES_PENDING,
    });
  }
}

function* fetch(action) {
  const { requestKey, resourceType, list, mergeResources, mergeMeta, mergeListIds } = action;
  const sideId = yield call(requireSiteId);

  const endpoint = endpointByResourceType[resourceType];

  const response = yield call(
    api.authorizedGet,
    resourceType === 'categories' ? `${endpoint}/` : `${endpoint}/?perpage=1000&site_id=${sideId}`
  );

  const requestProperties = {
    statusCode: response.status,
    receivedAt: new Date(),
  };

  if (response.ok) {
    yield put({
      type: actionTypes.READ_RESOURCES_SUCCEEDED,
      requestKey,
      resourceType,
      resources: response.json.results || response.json,
      requestProperties,
      list,
      mergeResources,
      mergeMeta,
      mergeListIds,
    });
  } else {
    yield put({
      type: actionTypes.READ_RESOURCES_FAILED,
      requestKey,
      resourceType,
      requestProperties,
    });
  }
}

function* reduxResourceSaga() {
  yield all([takeEvery('READ_RESOURCES_REQUEST_IF_NEEDED', fetchIfNeeded)]);
  yield all([takeEvery('READ_RESOURCES_REQUEST', fetch)]);
  yield all([takeEvery(actionTypes.READ_RESOURCES_PENDING, fetch)]);
}

export default reduxResourceSaga;
