import React, { useState, useEffect, useRef } from 'react';
import classnames from 'classnames';
import scrollToElement from 'scroll-to-element';

import * as Klaviyo from '../../lib/tracking/klaviyo';

import { ReactComponent as SvgCheckmark } from '../../assets/images/icon-checked-circle.svg';
import { ReactComponent as SvgIconBusinessTypeAgency } from '../../assets/images/signup/icon-business-type-agency.svg';
import { ReactComponent as SvgIconBusinessTypeEcommerce } from '../../assets/images/signup/icon-business-type-ecommerce.svg';
import { ReactComponent as SvgIconBusinessTypeOther } from '../../assets/images/signup/icon-business-type-other.svg';
import { ReactComponent as SvgIconBusinessTypeSoftware } from '../../assets/images/signup/icon-business-type-software.svg';
import { ReactComponent as SvgIconChallengeContent } from '../../assets/images/signup/icon-challenge-content.svg';
import { ReactComponent as SvgIconChallengeConverting } from '../../assets/images/signup/icon-challenge-converting.svg';
import { ReactComponent as SvgIconChallengeTraffic } from '../../assets/images/signup/icon-challenge-traffic.svg';

import styles from './TellUs.module.scss';

function TellUs(props) {
  const [business, setBusiness] = useState();
  const [challenge, setChallenge] = useState([]);
  const prevStateRef = useRef({});

  useEffect(() => {
    props.updateBusiness(business);
    // Get previous state object from ref
    const prevState = prevStateRef.current;

    // If a section was selected for the first time, scroll to the next one.
    if (prevState.business === undefined && business)
      scrollToElement('#challenges', { duration: 300, ease: 'outSine' });
    else if (prevState.challenge === undefined && challenge)
      scrollToElement('#buttons', { duration: 300, ease: 'outSine' });

    // If either one has changed, send the data to Intercom and Klaviyo
    if (prevState.business !== business || prevState.challenge !== challenge) {
      const data = {
        'business-type': business,
        'biggest-challenge': challenge.join(','),
      };

      try {
        if (process.env.NODE_ENV !== 'development') {
          Klaviyo.identify(data);
        }
      } catch (e) {
        console.log(e);
      }
    }

    // Now that we're done using it, store new state in ref
    prevStateRef.current = { business, challenge };

    // Only update when `business` or `challenge` change
  }, [business, challenge]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div className={styles.container}>
        <h2>What type of business are you?</h2>
        <p>Elastic Path Studio uses this to customize your design and content recommendations.</p>
        <div className={styles.cards}>
          <Card
            active={business === 'software'}
            onClick={() => setBusiness('software')}
            text="Software"
            Icon={SvgIconBusinessTypeSoftware}
          />
          <Card
            active={business === 'ecommerce'}
            onClick={() => setBusiness('ecommerce')}
            text="Ecommerce"
            Icon={SvgIconBusinessTypeEcommerce}
          />
          <Card
            active={business === 'agency/consultant'}
            onClick={() => setBusiness('agency/consultant')}
            text="Agency/Consultant"
            Icon={SvgIconBusinessTypeAgency}
          />
          <Card
            active={business === 'other'}
            onClick={() => setBusiness('other')}
            text="Other"
            Icon={SvgIconBusinessTypeOther}
          />
        </div>
      </div>
      {business && (
        <>
          <div id="challenges" className={styles.container}>
            <h2>What’s your biggest challenge?</h2>
            <p>We’ll provide better content recommendations based on your needs.</p>
            <div className={styles.cards}>
              <Card
                active={challenge.includes('traffic')}
                onClick={() => {
                  setChallenge(
                    !challenge.includes('traffic')
                      ? [...challenge, 'traffic']
                      : challenge.filter((item) => item !== 'traffic')
                  );
                }}
                text="Driving traffic"
                Icon={SvgIconChallengeTraffic}
              />
              <Card
                active={challenge.includes('converting')}
                onClick={() => {
                  setChallenge(
                    !challenge.includes('converting')
                      ? [...challenge, 'converting']
                      : challenge.filter((item) => item !== 'converting')
                  );
                }}
                text="Converting visitors"
                Icon={SvgIconChallengeConverting}
              />
              <Card
                active={challenge.includes('content')}
                onClick={() => {
                  setChallenge(
                    !challenge.includes('content')
                      ? [...challenge, 'content']
                      : challenge.filter((item) => item !== 'content')
                  );
                }}
                text="Creating content"
                Icon={SvgIconChallengeContent}
              />
            </div>
          </div>
          {challenge.length && (
            <div id="buttons" className={styles.container}>
              <input
                className="button button-success"
                value="Next"
                aria-label="next"
                onClick={() => props.gotoNextStep()}
                readOnly
                style={{ minWidth: '260px' }}
              />
            </div>
          )}
        </>
      )}
    </>
  );
}

export default TellUs;

function Card(props) {
  const { active, text, Icon, onClick } = props;

  return (
    <div className={classnames(styles.card, { [styles.active]: active })} onClick={onClick}>
      <div className={styles.top}>
        <Icon className={styles.icon} />
        {active && <SvgCheckmark className={styles.checkmark} />}
      </div>
      <div className={styles.bottom}>{text}</div>
    </div>
  );
}
