import React, { useState, useRef, useEffect } from 'react';
import { set, del, insert, push, assign } from 'object-path-immutable';
import * as PopperJS from '@popperjs/core';
import { useDispatch } from 'react-redux';
import { usePopper } from 'react-popper';
import classNames from 'classnames';
import { getVisibility, setInDynamic } from 'components/unstack-components/Component/util/helpers/deviceHelper';

import useOnClickOutside from 'hooks/useClickOutside';
import * as toolbarActions from 'actions/toolbarActions';

import {
  IconDeviceCheck,
  IconDeviceMobileMenu,
  IconDeviceDesktopMenu,
  IconDeviceMobilePlusMenu,
  IconDeviceMobileToolbar,
  IconDeviceDesktopToolbar,
  IconDeviceMobilePlusToolbar,
  IconDeviceTabletToolbar,
  IconDeviceTabletMenu,
} from '../../../../../../Editor/Toolbars2/icons';

import styles from './Toolbar.module.scss';
import { UBoxInfo, USectionDevices } from 'types/USection';

type Props = {
  dataRef: UBoxInfo;
  onChange: (content: USectionDevices, key?: string, multi?: boolean) => void;
  isSectionToolbar: boolean;
  contentKey: string;
  content: USectionDevices;
};

type VisibilityTypes = {
  ALL: string;
  MOBILE_ONLY: string;
  MOBILE_TABLET: string;
  TABLET_DESKTOP: string;
  DESKTOP_ONLY: string;
  TABLET_ONLY: string;
};

const Visibility: VisibilityTypes = {
  ALL: 'all',
  MOBILE_ONLY: 'mobile-only',
  MOBILE_TABLET: 'mobile-tablet',
  TABLET_DESKTOP: 'tablet-desktop',
  DESKTOP_ONLY: 'desktop-only',
  TABLET_ONLY: 'tablet-only',
};

function getMenuTitle(visibility: keyof VisibilityTypes) {
  switch (visibility) {
    case Visibility.DESKTOP_ONLY:
      return 'Desktop only';
    case Visibility.TABLET_ONLY:
      return 'Tablet only';
    case Visibility.MOBILE_ONLY:
      return 'Mobile only';
  }
}

function getMenuIcon(visibility: keyof VisibilityTypes) {
  switch (visibility) {
    case Visibility.DESKTOP_ONLY:
      return <IconDeviceDesktopMenu style={{ height: '20px' }} />;
    case Visibility.TABLET_ONLY:
      return <IconDeviceTabletMenu style={{ height: '20px' }} />;
    case Visibility.MOBILE_ONLY:
      return <IconDeviceMobileMenu style={{ height: '20px' }} />;
  }
}

function hideAllExceptDevice(content: USectionDevices, device: string, splitKey: string[]) {
  const path = device === 'all' ? [] : [device, ...splitKey.slice(1), 'visible'];
  let newContent = device === 'all' ? { ...content } : setInDynamic(content, path, true);
  const hideDevices = ['sm', 'md', 'lg'].filter((key: string) => key !== device);
  hideDevices.forEach((hideDevice) => {
    const path = [hideDevice, ...splitKey.slice(1), 'visible'];
    newContent = setInDynamic(newContent, path, device === 'all' ? true : false);
  });
  return newContent;
}

const VisibilityOptions: React.FunctionComponent<Props> = ({
  dataRef,
  onChange,
  isSectionToolbar,
  content,
  contentKey,
}) => {
  const newRef = useRef();
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const [referenceElement, setReferenceElement] = useState<Element | PopperJS.VirtualElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const { styles: popperStyles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-end',
  });

  let visibility = dataRef.visibility;
  if (contentKey.includes('.header.textBox')) {
    const visibilityJSON = {
      sm: content?.sm?.section?.header?.textBox.visible as boolean,
      md: content?.md?.section?.header?.textBox.visible as boolean,
      lg: content?.lg?.section?.header?.textBox.visible as boolean,
      base: true,
    };
    visibility = getVisibility(visibilityJSON);
  }

  const trayLabel =
    visibility === Visibility.DESKTOP_ONLY
      ? 'Show on Desktop only'
      : visibility === Visibility.MOBILE_ONLY
      ? 'Show on Mobile only'
      : visibility === Visibility.TABLET_ONLY
      ? 'Show on Tablet only'
      : 'Show on All devices';
  const trayIcon =
    visibility === Visibility.DESKTOP_ONLY ? (
      <IconDeviceDesktopToolbar style={{ height: '20px' }} active />
    ) : visibility === Visibility.MOBILE_ONLY ? (
      <IconDeviceMobileToolbar style={{ height: '20px' }} active />
    ) : visibility === Visibility.TABLET_ONLY ? (
      <IconDeviceTabletToolbar style={{ height: '20px' }} active />
    ) : (
      <IconDeviceMobilePlusToolbar style={{ height: '20px' }} />
    );

  useOnClickOutside(newRef, () => setDrawerIsOpen(false));

  const handleChange = (visibility: string) => {
    const splitKey = contentKey.split('.');
    const newContent = hideAllExceptDevice(
      content,
      visibility === 'mobile-only'
        ? 'sm'
        : visibility === 'desktop-only'
        ? 'lg'
        : visibility === 'tablet-only'
        ? 'md'
        : 'all',
      splitKey
    );

    onChange(newContent, `content.${splitKey[0]}`);
  };

  const dispatch = useDispatch();
  useEffect(() => {
    if (drawerIsOpen && isSectionToolbar) dispatch(toolbarActions.requestDrawerOpen());
    else dispatch(toolbarActions.requestDrawerClose());
  }, [drawerIsOpen]);

  return (
    <div
      ref={setReferenceElement}
      data-tip={trayLabel}
      data-test-id={trayLabel.toLowerCase().replaceAll(' ', '-')}
      className={classNames(styles.trayIcon, { [styles.open]: drawerIsOpen })}
      onClick={() => setDrawerIsOpen(!drawerIsOpen)}
    >
      <div ref={newRef} className={styles.visibilityContainer}>
        {trayIcon}
        {drawerIsOpen && (
          <div ref={setPopperElement} style={{ ...popperStyles.popper, zIndex: 9 }} {...attributes.popper}>
            <div className={styles.visibilityDrawer} data-test-id="show-hide-picker-drawer">
              <div className={styles.menuHeading}>Show on...</div>
              <div
                className={classNames(styles.menuItem, { [styles.itemActive]: visibility === Visibility.ALL })}
                onClick={() => handleChange(Visibility.ALL)}
              >
                {visibility === Visibility.ALL || visibility === undefined ? (
                  <IconDeviceCheck style={{ height: '20px' }} />
                ) : (
                  <div></div>
                )}
                <IconDeviceMobilePlusMenu style={{ height: '20px' }} />
                All devices
              </div>
              <>
                {[Visibility.DESKTOP_ONLY, Visibility.TABLET_ONLY, Visibility.MOBILE_ONLY].map((loopedVisibility) => (
                  <div
                    className={classNames(styles.menuItem, {
                      [styles.itemActive]: visibility === loopedVisibility,
                    })}
                    onClick={() => handleChange(loopedVisibility)}
                  >
                    {visibility === loopedVisibility ? <IconDeviceCheck style={{ height: '20px' }} /> : <div></div>}
                    {getMenuIcon(loopedVisibility as keyof VisibilityTypes)}
                    {getMenuTitle(loopedVisibility as keyof VisibilityTypes)}
                  </div>
                ))}
              </>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export { Visibility, VisibilityOptions };
