import React, { ChangeEvent, LegacyRef, useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';

import Select from './Select';
import MarginField from './MarginField';
import CapitalizeSelect from './CapitalizeSelect';
import LetterSpacingField from './LetterSpacingField';
import ColorSelector from './StyleGuideEditor/ColorSelector';
import { selectHasPermission } from 'reducers/policyReducer';
import { FontOptions, ColorOptions, Heading } from './types';

import { ReactComponent as SvgIconAdd } from '../../../assets/images/icon-plus.svg';
import { ReactComponent as SvgIconTrash } from '../../../assets/images/icon-remove-header.svg';
import { ReactComponent as SvgIconDesktopThumbnail } from '../../../assets/images/icon-desktop-styleguide.svg';
import { ReactComponent as SvgIconMobileThumbnail } from '../../../assets/images/icon-mobile-styleguide.svg';

import styles from './StyleGuideEditor.module.scss';

export default React.memo(
  ({
    fontFamilyOptions,
    styleGuide,
    fontWeightOptions,
    handleChange,
    handleChangeEvent,
    darkBgColor,
    ...props
  }: Heading) => {
    const [activeBackground, setActiveBackground] = useState({
      blog_header_1: 'light',
      blog_header_2: 'light',
      blog_header_3: 'light',
      blog_header_4: 'light',
      blog_header_5: 'light',
      blog_header_6: 'light',
    });

    const hasPermission = useSelector(selectHasPermission);
    const [click, setClick] = useState(false);

    const myRef4 = useRef(null);
    const myRef5 = useRef(null);
    const myRef6 = useRef(null);

    useEffect(() => {
      if (click) {
        if (!styleGuide.blog_header_count || styleGuide.blog_header_count === 4) {
          const element = myRef4.current;
          element?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        } else if (styleGuide.blog_header_count === 5) {
          const element = myRef5.current;
          element?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        } else {
          const element = myRef6.current;
          element?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        }
      }
    }, [styleGuide.blog_header_count]);

    return (
      <>
        {hasPermission('Article:create', 'subscription') && (
          <>
            <header ref={props.scrollRef} className={styles.fontsStylesHeader}>
              <h1>Blog Headings</h1>
              <small>Set the text styles used across your site.</small>
              {(!styleGuide.blog_header_count || styleGuide.blog_header_count < 6) && (
                <button
                  onClick={() => {
                    setClick(true);
                    if (!styleGuide.blog_header_count || styleGuide.blog_header_count === 3) {
                      handleChange([], {
                        ...styleGuide.typography,
                        blog_header_count: 4,
                        blog_header_4: {
                          font_family_index: styleGuide.blog_header_3_font_family_index,
                          font_weight: styleGuide.blog_header_3_font_weight,
                          font_size: styleGuide.blog_header_3_font_size,
                          color: styleGuide.blog_header_3_color,
                          line_height: styleGuide.blog_header_3_line_height,
                          text_transform: styleGuide.blog_header_3_text_transform,
                          letter_spacing: styleGuide.blog_header_3_letter_spacing,
                          margin: styleGuide.blog_header_3_margin,
                          dark_bg_color: styleGuide.blog_header_3_dark_bg_color,
                        },
                      });
                    } else if (styleGuide.blog_header_count === 4) {
                      handleChange([], {
                        ...styleGuide.typography,
                        blog_header_count: 5,
                        blog_header_5: {
                          font_family_index: styleGuide.blog_header_4_font_family_index,
                          font_weight: styleGuide.blog_header_4_font_weight,
                          font_size: styleGuide.blog_header_4_font_size,
                          color: styleGuide.blog_header_4_color,
                          line_height: styleGuide.blog_header_4_line_height,
                          text_transform: styleGuide.blog_header_4_text_transform,
                          letter_spacing: styleGuide.blog_header_4_letter_spacing,
                          margin: styleGuide.blog_header_4_margin,
                          dark_bg_color: styleGuide.blog_header_4_dark_bg_color,
                        },
                      });
                    } else {
                      handleChange([], {
                        ...styleGuide.typography,
                        blog_header_count: 6,
                        blog_header_6: {
                          font_family_index: styleGuide.blog_header_5_font_family_index,
                          font_weight: styleGuide.blog_header_5_font_weight,
                          font_size: styleGuide.blog_header_5_font_size,
                          color: styleGuide.blog_header_5_color,
                          line_height: styleGuide.blog_header_5_line_height,
                          text_transform: styleGuide.blog_header_5_text_transform,
                          letter_spacing: styleGuide.blog_header_5_letter_spacing,
                          margin: styleGuide.blog_header_5_margin,
                          dark_bg_color: styleGuide.blog_header_5_dark_bg_color,
                        },
                      });
                    }
                  }}
                  className="button button-primary button-icon"
                >
                  <SvgIconAdd height={15} width={15} />
                  <span>New Blog Heading</span>
                </button>
              )}
            </header>

            {/* Blog Header 1 */}
            <article className={styles.StyleGuideEditor}>
              <section className={styles.styleSection}>
                <div className={styles.styleSectionHeader}>
                  <p>Blog H1</p>
                  <div className={styles.bgToggles}>
                    <div className={styles.background}>
                      <span
                        className={classnames(styles.lightBackground, {
                          [styles.active]: activeBackground.blog_header_1 === 'light',
                        })}
                        onClick={() => setActiveBackground({ ...activeBackground, blog_header_1: 'light' })}
                      >
                        Light
                      </span>
                      <span
                        className={classnames(styles.darkBackground, {
                          [styles.active]: activeBackground.blog_header_1 === 'dark',
                        })}
                        onClick={() => setActiveBackground({ ...activeBackground, blog_header_1: 'dark' })}
                      >
                        Dark
                      </span>
                    </div>

                    <div className={styles.device}>
                      <span
                        onClick={() => setActiveBackground({ ...activeBackground, blog_header_1: 'light' })}
                        className={classnames(styles.lightBackground, {
                          [styles.active]: activeBackground.blog_header_1 === 'light',
                        })}
                      >
                        <SvgIconDesktopThumbnail />
                      </span>
                      <span
                        onClick={() => setActiveBackground({ ...activeBackground, blog_header_1: 'mobile' })}
                        className={classnames(styles.darkBackground, {
                          [styles.active]: activeBackground.blog_header_1 === 'mobile',
                        })}
                      >
                        <SvgIconMobileThumbnail />
                      </span>
                    </div>
                  </div>
                </div>
                <div>
                  <div
                    className={`sriracha ${
                      activeBackground.blog_header_1 === 'dark'
                        ? 'dark-bg'
                        : activeBackground.blog_header_1 === 'mobile'
                        ? 'mobile'
                        : ''
                    }`}
                    style={{
                      padding: activeBackground.blog_header_1 === 'dark' ? '1rem 2rem 0.5rem' : '0',
                      backgroundColor: activeBackground.blog_header_1 === 'dark' ? darkBgColor.code : 'inherit',
                    }}
                  >
                    <div className="blog-content">
                      <header style={{ margin: 0 }}>
                        <h1 style={{ textAlign: 'left' }}>Blog header 1</h1>
                      </header>
                    </div>
                  </div>
                </div>
                <div className={styles.styleSectionOptions}>
                  {activeBackground.blog_header_1 === 'light' ? (
                    <>
                      <div className={styles.styleOption}>
                        <span>Font family</span>
                        <Select
                          options={fontFamilyOptions}
                          defaultValue={fontFamilyOptions[styleGuide.blog_header_1_font_family_index]}
                          value={fontFamilyOptions[styleGuide.blog_header_1_font_family_index]}
                          onChange={(option: FontOptions) =>
                            handleChange(['blog_header_1', 'font_family_index'], option.value)
                          }
                        />
                      </div>
                      <div className={styles.styleOption}>
                        <span>Font Weight</span>
                        <Select
                          options={fontWeightOptions}
                          defaultValue={fontWeightOptions.find(
                            (weight) => weight.value === Number(styleGuide.blog_header_1_font_weight)
                          )}
                          value={fontWeightOptions.find(
                            (weight) => weight.value === Number(styleGuide.blog_header_1_font_weight)
                          )}
                          onChange={(option: FontOptions) =>
                            handleChange(['blog_header_1', 'font_weight'], option.value)
                          }
                        />
                      </div>
                      <div className={styles.styleOption}>
                        <span>Font size</span>
                        <input
                          type="number"
                          name="blog_header_1_font_size"
                          value={styleGuide.blog_header_1_font_size}
                          min="0.8"
                          max="10"
                          step="0.1"
                          onChange={(e) => handleChangeEvent(e, ['blog_header_1', 'font_size'])}
                        />
                      </div>
                      <div className={styles.styleOption}>
                        <span>Line height</span>
                        <input
                          type="number"
                          name="blog_header_1_line_height"
                          value={styleGuide.blog_header_1_line_height}
                          min="0.8"
                          max="2"
                          step="0.1"
                          onChange={(e) => handleChangeEvent(e, ['blog_header_1', 'line_height'])}
                        />
                      </div>
                      <CapitalizeSelect
                        value={styleGuide.blog_header_1_text_transform}
                        handleChange={(value) => handleChange(['blog_header_1', 'text_transform'], value)}
                      />
                      <LetterSpacingField
                        name="blog_header_1_letter_spacing"
                        value={styleGuide.blog_header_1_letter_spacing}
                        handleChange={(value) => handleChange(['blog_header_1', 'letter_spacing'], value)}
                      />
                      <MarginField
                        name="blog_header_1_margin"
                        value={styleGuide.blog_header_1_margin}
                        handleChange={(value) => handleChange(['blog_header_1', 'margin'], value)}
                      />
                      <div className={styles.styleOption}>
                        <span>Color</span>
                        <ColorSelector
                          name="blog_header_1_color"
                          onChange={(value: ColorOptions) => handleChange(['blog_header_1', 'color'], value)}
                          value={styleGuide.blog_header_1_color}
                          placeholder="Color"
                          handleColorSelection={(value: ColorOptions) =>
                            handleChange(['blog_header_1', 'color'], value)
                          }
                        />
                      </div>
                    </>
                  ) : activeBackground.blog_header_1 === 'dark' ? (
                    <div className={styles.styleOption}>
                      <span>Color</span>
                      <ColorSelector
                        name="blog_header_1_dark_bg_color"
                        onChange={(value: ColorOptions) => handleChange(['blog_header_1', 'dark_bg_color'], value)}
                        value={styleGuide.blog_header_1_dark_bg_color}
                        placeholder="Color"
                        handleColorSelection={(value: ColorOptions) =>
                          handleChange(['blog_header_1', 'dark_bg_color'], value)
                        }
                      />
                    </div>
                  ) : (
                    <>
                      <div className={styles.styleOption}>
                        <span>Font size</span>
                        <input
                          type="number"
                          name="blog_header_1_font_size_mobile"
                          value={styleGuide.blog_header_1_font_size_mobile}
                          min="0.8"
                          max="10"
                          step="0.1"
                          onChange={(e) => handleChangeEvent(e, ['blog_header_1', 'mobile', 'font_size'])}
                        />
                      </div>
                      <div className={styles.styleOption}>
                        <span>Line height</span>
                        <input
                          type="number"
                          name="blog_header_1_line_height_mobile"
                          value={styleGuide.blog_header_1_line_height_mobile || styleGuide.blog_header_1_line_height}
                          min="0.8"
                          max="2"
                          step="0.1"
                          onChange={(e) => handleChangeEvent(e, ['blog_header_1', 'mobile', 'line_height'])}
                        />
                      </div>
                      <LetterSpacingField
                        name="blog_header_1_letter_spacing_mobile"
                        value={
                          styleGuide.blog_header_1_letter_spacing_mobile || styleGuide.blog_header_1_letter_spacing
                        }
                        handleChange={(value) => handleChange(['blog_header_1', 'mobile', 'letter_spacing'], value)}
                      />
                      <MarginField
                        name="blog_header_1_margin_mobile"
                        value={styleGuide.blog_header_1_margin_mobile || styleGuide.blog_header_1_margin}
                        handleChange={(value) => handleChange(['blog_header_1', 'mobile', 'margin'], value)}
                      />
                    </>
                  )}
                </div>
              </section>
            </article>

            {/* Blog Header 2 */}
            <article className={styles.StyleGuideEditor}>
              <section className={styles.styleSection}>
                <div className={styles.styleSectionHeader}>
                  <p>Blog H2</p>
                  <div className={styles.bgToggles}>
                    <div className={styles.background}>
                      <span
                        className={classnames(styles.lightBackground, {
                          [styles.active]: activeBackground.blog_header_2 === 'light',
                        })}
                        onClick={() => setActiveBackground({ ...activeBackground, blog_header_2: 'light' })}
                      >
                        Light
                      </span>
                      <span
                        className={classnames(styles.darkBackground, {
                          [styles.active]: activeBackground.blog_header_2 === 'dark',
                        })}
                        onClick={() => setActiveBackground({ ...activeBackground, blog_header_2: 'dark' })}
                      >
                        Dark
                      </span>
                    </div>

                    <div className={styles.device}>
                      <span
                        onClick={() => setActiveBackground({ ...activeBackground, blog_header_2: 'light' })}
                        className={classnames(styles.lightBackground, {
                          [styles.active]: activeBackground.blog_header_2 === 'light',
                        })}
                      >
                        <SvgIconDesktopThumbnail />
                      </span>
                      <span
                        onClick={() => setActiveBackground({ ...activeBackground, blog_header_2: 'mobile' })}
                        className={classnames(styles.darkBackground, {
                          [styles.active]: activeBackground.blog_header_2 === 'mobile',
                        })}
                      >
                        <SvgIconMobileThumbnail />
                      </span>
                    </div>
                  </div>
                </div>
                <div>
                  <div
                    className={`sriracha ${
                      activeBackground.blog_header_2 === 'dark'
                        ? 'dark-bg'
                        : activeBackground.blog_header_2 === 'mobile'
                        ? 'mobile'
                        : ''
                    }`}
                    style={{
                      padding: activeBackground.blog_header_2 === 'dark' ? '1rem 2rem 0.5rem' : '0',
                      backgroundColor: activeBackground.blog_header_2 === 'dark' ? darkBgColor.code : 'inherit',
                    }}
                  >
                    <div className="blog-content">
                      <header style={{ margin: 0 }}>
                        <h2 style={{ textAlign: 'left' }}>Blog header 2</h2>
                      </header>
                    </div>
                  </div>
                </div>
                <div className={styles.styleSectionOptions}>
                  {activeBackground.blog_header_2 === 'light' ? (
                    <>
                      <div className={styles.styleOption}>
                        <span>Font family</span>
                        <Select
                          options={fontFamilyOptions}
                          defaultValue={fontFamilyOptions[styleGuide.blog_header_2_font_family_index]}
                          value={fontFamilyOptions[styleGuide.blog_header_2_font_family_index]}
                          onChange={(option: FontOptions) =>
                            handleChange(['blog_header_2', 'font_family_index'], option.value)
                          }
                        />
                      </div>
                      <div className={styles.styleOption}>
                        <span>Font Weight</span>
                        <Select
                          options={fontWeightOptions}
                          defaultValue={fontWeightOptions.find(
                            (weight) => weight.value === Number(styleGuide.blog_header_2_font_weight)
                          )}
                          value={fontWeightOptions.find(
                            (weight) => weight.value === Number(styleGuide.blog_header_2_font_weight)
                          )}
                          onChange={(option: FontOptions) =>
                            handleChange(['blog_header_2', 'font_weight'], option.value)
                          }
                        />
                      </div>
                      <div className={styles.styleOption}>
                        <span>Font size</span>
                        <input
                          type="number"
                          name="blog_header_2_font_size"
                          value={styleGuide.blog_header_2_font_size}
                          min="0.8"
                          max="10"
                          step="0.1"
                          onChange={(e) => handleChangeEvent(e, ['blog_header_2', 'font_size'])}
                        />
                      </div>
                      <div className={styles.styleOption}>
                        <span>Line height</span>
                        <input
                          type="number"
                          name="blog_header_2_line_height"
                          value={styleGuide.blog_header_2_line_height}
                          min="0.8"
                          max="2"
                          step="0.1"
                          onChange={(e) => handleChangeEvent(e, ['blog_header_2', 'line_height'])}
                        />
                      </div>
                      <CapitalizeSelect
                        value={styleGuide.blog_header_2_text_transform}
                        handleChange={(value) => handleChange(['blog_header_2', 'text_transform'], value)}
                      />
                      <LetterSpacingField
                        name="blog_header_2_letter_spacing"
                        value={styleGuide.blog_header_2_letter_spacing}
                        handleChange={(value) => handleChange(['blog_header_2', 'letter_spacing'], value)}
                      />
                      <MarginField
                        name="blog_header_2_margin"
                        value={styleGuide.blog_header_2_margin}
                        handleChange={(value) => handleChange(['blog_header_2', 'margin'], value)}
                      />
                      <div className={styles.styleOption}>
                        <span>Color</span>
                        <ColorSelector
                          name="blog_header_2_color"
                          onChange={(value: ColorOptions) => handleChange(['blog_header_2', 'color'], value)}
                          value={styleGuide.blog_header_2_color}
                          placeholder="Color"
                          handleColorSelection={(value: ColorOptions) =>
                            handleChange(['blog_header_2', 'color'], value)
                          }
                        />
                      </div>
                    </>
                  ) : activeBackground.blog_header_2 === 'dark' ? (
                    <div className={styles.styleOption}>
                      <span>Color</span>
                      <ColorSelector
                        name="blog_header_2_dark_bg_color"
                        onChange={(value: ColorOptions) => handleChange(['blog_header_2', 'dark_bg_color'], value)}
                        value={styleGuide.blog_header_2_dark_bg_color}
                        placeholder="Color"
                        handleColorSelection={(value: ColorOptions) =>
                          handleChange(['blog_header_2', 'dark_bg_color'], value)
                        }
                      />
                    </div>
                  ) : (
                    <>
                      <div className={styles.styleOption}>
                        <span>Font size</span>
                        <input
                          type="number"
                          name="blog_header_2_font_size_mobile"
                          value={styleGuide.blog_header_2_font_size_mobile}
                          min="0.8"
                          max="10"
                          step="0.1"
                          onChange={(e) => handleChangeEvent(e, ['blog_header_2', 'mobile', 'font_size'])}
                        />
                      </div>
                      <div className={styles.styleOption}>
                        <span>Line height</span>
                        <input
                          type="number"
                          name="blog_header_2_line_height_mobile"
                          value={styleGuide.blog_header_2_line_height_mobile || styleGuide.blog_header_2_line_height}
                          min="0.8"
                          max="2"
                          step="0.1"
                          onChange={(e) => handleChangeEvent(e, ['blog_header_2', 'mobile', 'line_height'])}
                        />
                      </div>
                      <LetterSpacingField
                        name="blog_header_2_letter_spacing_mobile"
                        value={
                          styleGuide.blog_header_2_letter_spacing_mobile || styleGuide.blog_header_2_letter_spacing
                        }
                        handleChange={(value) => handleChange(['blog_header_2', 'mobile', 'letter_spacing'], value)}
                      />
                      <MarginField
                        name="blog_header_2_margin_mobile"
                        value={styleGuide.blog_header_2_margin_mobile || styleGuide.blog_header_2_margin}
                        handleChange={(value) => handleChange(['blog_header_2', 'mobile', 'margin'], value)}
                      />
                    </>
                  )}
                </div>
              </section>
            </article>

            {/* Blog Header 3 */}
            <article className={styles.StyleGuideEditor}>
              <section className={styles.styleSection}>
                <div className={styles.styleSectionHeader}>
                  <p>Blog H3</p>
                  <div className={styles.bgToggles}>
                    <div className={styles.background}>
                      <span
                        className={classnames(styles.lightBackground, {
                          [styles.active]: activeBackground.blog_header_3 === 'light',
                        })}
                        onClick={() => setActiveBackground({ ...activeBackground, blog_header_3: 'light' })}
                      >
                        Light
                      </span>
                      <span
                        className={classnames(styles.darkBackground, {
                          [styles.active]: activeBackground.blog_header_3 === 'dark',
                        })}
                        onClick={() => setActiveBackground({ ...activeBackground, blog_header_3: 'dark' })}
                      >
                        Dark
                      </span>
                    </div>

                    <div className={styles.device}>
                      <span
                        onClick={() => setActiveBackground({ ...activeBackground, blog_header_3: 'light' })}
                        className={classnames(styles.lightBackground, {
                          [styles.active]: activeBackground.blog_header_3 === 'light',
                        })}
                      >
                        <SvgIconDesktopThumbnail />
                      </span>
                      <span
                        onClick={() => setActiveBackground({ ...activeBackground, blog_header_3: 'mobile' })}
                        className={classnames(styles.darkBackground, {
                          [styles.active]: activeBackground.blog_header_3 === 'mobile',
                        })}
                      >
                        <SvgIconMobileThumbnail />
                      </span>
                    </div>
                  </div>
                </div>
                <div>
                  <div
                    className={`sriracha ${
                      activeBackground.blog_header_3 === 'dark'
                        ? 'dark-bg'
                        : activeBackground.blog_header_3 === 'mobile'
                        ? 'mobile'
                        : ''
                    }`}
                    style={{
                      padding: activeBackground.blog_header_3 === 'dark' ? '1rem 2rem 0.5rem' : '0',
                      backgroundColor: activeBackground.blog_header_3 === 'dark' ? darkBgColor.code : 'inherit',
                    }}
                  >
                    <div className="blog-content">
                      <header style={{ margin: 0 }}>
                        <h3 style={{ textAlign: 'left' }}>Blog header 3</h3>
                      </header>
                    </div>
                  </div>
                </div>
                <div className={styles.styleSectionOptions}>
                  {activeBackground.blog_header_3 === 'light' ? (
                    <>
                      <div className={styles.styleOption}>
                        <span>Font family</span>
                        <Select
                          options={fontFamilyOptions}
                          defaultValue={fontFamilyOptions[styleGuide.blog_header_3_font_family_index]}
                          value={fontFamilyOptions[styleGuide.blog_header_3_font_family_index]}
                          onChange={(option: FontOptions) =>
                            handleChange(['blog_header_3', 'font_family_index'], option.value)
                          }
                        />
                      </div>
                      <div className={styles.styleOption}>
                        <span>Font Weight</span>
                        <Select
                          options={fontWeightOptions}
                          defaultValue={fontWeightOptions.find(
                            (weight) => weight.value === Number(styleGuide.blog_header_3_font_weight)
                          )}
                          value={fontWeightOptions.find(
                            (weight) => weight.value === Number(styleGuide.blog_header_3_font_weight)
                          )}
                          onChange={(option: FontOptions) =>
                            handleChange(['blog_header_3', 'font_weight'], option.value)
                          }
                        />
                      </div>
                      <div className={styles.styleOption}>
                        <span>Font size</span>
                        <input
                          type="number"
                          name="blog_header_3_font_size"
                          value={styleGuide.blog_header_3_font_size}
                          min="0.8"
                          max="10"
                          step="0.1"
                          onChange={(e) => handleChangeEvent(e, ['blog_header_3', 'font_size'])}
                        />
                      </div>
                      <div className={styles.styleOption}>
                        <span>Line height</span>
                        <input
                          type="number"
                          name="blog_header_3_line_height"
                          value={styleGuide.blog_header_3_line_height}
                          min="0.8"
                          max="2"
                          step="0.1"
                          onChange={(e) => handleChangeEvent(e, ['blog_header_3', 'line_height'])}
                        />
                      </div>
                      <CapitalizeSelect
                        value={styleGuide.blog_header_3_text_transform}
                        handleChange={(value) => handleChange(['blog_header_3', 'text_transform'], value)}
                      />
                      <LetterSpacingField
                        name="blog_header_3_letter_spacing"
                        value={styleGuide.blog_header_3_letter_spacing}
                        handleChange={(value) => handleChange(['blog_header_3', 'letter_spacing'], value)}
                      />
                      <MarginField
                        name="blog_header_3_margin"
                        value={styleGuide.blog_header_3_margin}
                        handleChange={(value) => handleChange(['blog_header_3', 'margin'], value)}
                      />
                      <div className={styles.styleOption}>
                        <span>Color</span>
                        <ColorSelector
                          name="blog_header_3_color"
                          onChange={(value: ColorOptions) => handleChange(['blog_header_3', 'color'], value)}
                          value={styleGuide.blog_header_3_color}
                          placeholder="Color"
                          handleColorSelection={(value: ColorOptions) =>
                            handleChange(['blog_header_3', 'color'], value)
                          }
                        />
                      </div>
                    </>
                  ) : activeBackground.blog_header_3 === 'dark' ? (
                    <div className={styles.styleOption}>
                      <span>Color</span>
                      <ColorSelector
                        name="blog_header_3_dark_bg_color"
                        onChange={(value: ColorOptions) => handleChange(['blog_header_3', 'dark_bg_color'], value)}
                        value={styleGuide.blog_header_3_dark_bg_color}
                        placeholder="Color"
                        handleColorSelection={(value: ColorOptions) =>
                          handleChange(['blog_header_3', 'dark_bg_color'], value)
                        }
                      />
                    </div>
                  ) : (
                    <>
                      <div className={styles.styleOption}>
                        <span>Font size</span>
                        <input
                          type="number"
                          name="blog_header_3_font_size_mobile"
                          value={styleGuide.blog_header_3_font_size_mobile}
                          min="0.8"
                          max="10"
                          step="0.1"
                          onChange={(e) => handleChangeEvent(e, ['blog_header_3', 'mobile', 'font_size'])}
                        />
                      </div>
                      <div className={styles.styleOption}>
                        <span>Line height</span>
                        <input
                          type="number"
                          name="blog_header_3_line_height_mobile"
                          value={styleGuide.blog_header_3_line_height_mobile || styleGuide.blog_header_3_line_height}
                          min="0.8"
                          max="2"
                          step="0.1"
                          onChange={(e) => handleChangeEvent(e, ['blog_header_3', 'mobile', 'line_height'])}
                        />
                      </div>
                      <LetterSpacingField
                        name="blog_header_3_letter_spacing_mobile"
                        value={
                          styleGuide.blog_header_3_letter_spacing_mobile || styleGuide.blog_header_3_letter_spacing
                        }
                        handleChange={(value) => handleChange(['blog_header_3', 'mobile', 'letter_spacing'], value)}
                      />
                      <MarginField
                        name="blog_header_3_margin_mobile"
                        value={styleGuide.blog_header_3_margin_mobile || styleGuide.blog_header_3_margin}
                        handleChange={(value) => handleChange(['blog_header_3', 'mobile', 'margin'], value)}
                      />
                    </>
                  )}
                </div>
              </section>
            </article>

            {/* Header 4 */}
            {styleGuide.blog_header_count > 3 && (
              <article className={styles.StyleGuideEditor}>
                <section className={styles.styleSection} ref={myRef4}>
                  <div className={styles.styleSectionHeader}>
                    <p>Blog H4</p>
                    <div className={styles.controlsContainer}>
                      <div className={styles.bgToggles}>
                        <div className={styles.background}>
                          <span
                            className={classnames(styles.lightBackground, {
                              [styles.active]: activeBackground.blog_header_4 === 'light',
                            })}
                            onClick={() => setActiveBackground({ ...activeBackground, blog_header_4: 'light' })}
                          >
                            Light
                          </span>
                          <span
                            className={classnames(styles.darkBackground, {
                              [styles.active]: activeBackground.blog_header_4 === 'dark',
                            })}
                            onClick={() => setActiveBackground({ ...activeBackground, blog_header_4: 'dark' })}
                          >
                            Dark
                          </span>
                        </div>

                        <div className={styles.device}>
                          <span
                            onClick={() => setActiveBackground({ ...activeBackground, blog_header_4: 'light' })}
                            className={classnames(styles.lightBackground, {
                              [styles.active]: activeBackground.blog_header_4 === 'light',
                            })}
                          >
                            <SvgIconDesktopThumbnail />
                          </span>
                          <span
                            onClick={() => setActiveBackground({ ...activeBackground, blog_header_4: 'mobile' })}
                            className={classnames(styles.darkBackground, {
                              [styles.active]: activeBackground.blog_header_4 === 'mobile',
                            })}
                          >
                            <SvgIconMobileThumbnail />
                          </span>
                        </div>
                      </div>
                      {styleGuide.blog_header_count === 4 && (
                        <div
                          className={styles.deleteIcon}
                          onClick={() => {
                            handleChange(['blog_header_count'], styleGuide.blog_header_count - 1);
                          }}
                        >
                          <SvgIconTrash />
                        </div>
                      )}
                    </div>
                  </div>
                  <div>
                    <div
                      className={`sriracha ${
                        activeBackground.blog_header_4 === 'dark'
                          ? 'dark-bg'
                          : activeBackground.blog_header_4 === 'mobile'
                          ? 'mobile'
                          : ''
                      }`}
                      style={{
                        padding: activeBackground.blog_header_4 === 'dark' ? '1rem 2rem 0.5rem' : '0',
                        backgroundColor: activeBackground.blog_header_4 === 'dark' ? darkBgColor.code : 'inherit',
                      }}
                    >
                      <div className="blog-content">
                        <header style={{ margin: 0 }}>
                          <h4 style={{ textAlign: 'left' }}>Blog header 4</h4>
                        </header>
                      </div>
                    </div>
                  </div>
                  <div className={styles.styleSectionOptions}>
                    {activeBackground.blog_header_4 === 'light' ? (
                      <>
                        <div className={styles.styleOption}>
                          <span>Font family</span>
                          <Select
                            options={fontFamilyOptions}
                            defaultValue={fontFamilyOptions[styleGuide.blog_header_4_font_family_index]}
                            value={fontFamilyOptions[styleGuide.blog_header_4_font_family_index]}
                            onChange={(option: FontOptions) =>
                              handleChange(['blog_header_4', 'font_family_index'], option.value)
                            }
                          />
                        </div>
                        <div className={styles.styleOption}>
                          <span>Font Weight</span>
                          <Select
                            options={fontWeightOptions}
                            defaultValue={fontWeightOptions.find(
                              (weight) => weight.value === Number(styleGuide.blog_header_4_font_weight)
                            )}
                            value={fontWeightOptions.find(
                              (weight) => weight.value === Number(styleGuide.blog_header_4_font_weight)
                            )}
                            onChange={(option: FontOptions) =>
                              handleChange(['blog_header_4', 'font_weight'], option.value)
                            }
                          />
                        </div>
                        <div className={styles.styleOption}>
                          <span>Font size</span>
                          <input
                            type="number"
                            name="blog_header_4_font_size"
                            value={styleGuide.blog_header_4_font_size}
                            min="0.8"
                            max="10"
                            step="0.1"
                            onChange={(e) => handleChangeEvent(e, ['blog_header_4', 'font_size'])}
                          />
                        </div>
                        <div className={styles.styleOption}>
                          <span>Line height</span>
                          <input
                            type="number"
                            name="blog_header_4_line_height"
                            value={styleGuide.blog_header_4_line_height}
                            min="0.8"
                            max="2"
                            step="0.1"
                            onChange={(e) => handleChangeEvent(e, ['blog_header_4', 'line_height'])}
                          />
                        </div>
                        <CapitalizeSelect
                          value={styleGuide.blog_header_4_text_transform}
                          handleChange={(value) => handleChange(['blog_header_4', 'text_transform'], value)}
                        />
                        <LetterSpacingField
                          name="blog_header_4_letter_spacing"
                          value={styleGuide.blog_header_4_letter_spacing}
                          handleChange={(value) => handleChange(['blog_header_4', 'letter_spacing'], value)}
                        />
                        <MarginField
                          name="blog_header_4_margin"
                          value={styleGuide.blog_header_4_margin}
                          handleChange={(value) => handleChange(['blog_header_4', 'margin'], value)}
                        />
                        <div className={styles.styleOption}>
                          <span>Color</span>
                          <ColorSelector
                            name="blog_header_4_color"
                            onChange={(value: ColorOptions) => handleChange(['blog_header_4', 'color'], value)}
                            value={styleGuide.blog_header_4_color}
                            placeholder="Color"
                            handleColorSelection={(value: ColorOptions) =>
                              handleChange(['blog_header_4', 'color'], value)
                            }
                          />
                        </div>
                      </>
                    ) : activeBackground.blog_header_4 === 'dark' ? (
                      <div className={styles.styleOption}>
                        <span>Color</span>
                        <ColorSelector
                          name="blog_header_4_dark_bg_color"
                          onChange={(value: ColorOptions) => handleChange(['blog_header_4', 'dark_bg_color'], value)}
                          value={styleGuide.blog_header_4_dark_bg_color}
                          placeholder="Color"
                          handleColorSelection={(value: ColorOptions) =>
                            handleChange(['blog_header_4', 'dark_bg_color'], value)
                          }
                        />
                      </div>
                    ) : (
                      <>
                        <div className={styles.styleOption}>
                          <span>Font size</span>
                          <input
                            type="number"
                            name="blog_header_4_font_size_mobile"
                            value={styleGuide.blog_header_4_font_size_mobile}
                            min="0.8"
                            max="10"
                            step="0.1"
                            onChange={(e) => handleChangeEvent(e, ['blog_header_4', 'mobile', 'font_size'])}
                          />
                        </div>
                        <div className={styles.styleOption}>
                          <span>Line height</span>
                          <input
                            type="number"
                            name="blog_header_4_line_height_mobile"
                            value={styleGuide.blog_header_4_line_height_mobile || styleGuide.blog_header_4_line_height}
                            min="0.8"
                            max="2"
                            step="0.1"
                            onChange={(e) => handleChangeEvent(e, ['blog_header_4', 'mobile', 'line_height'])}
                          />
                        </div>
                        <LetterSpacingField
                          name="blog_header_4_letter_spacing_mobile"
                          value={
                            styleGuide.blog_header_4_letter_spacing_mobile || styleGuide.blog_header_4_letter_spacing
                          }
                          handleChange={(value) => handleChange(['blog_header_4', 'mobile', 'letter_spacing'], value)}
                        />
                        <MarginField
                          name="blog_header_4_margin_mobile"
                          value={styleGuide.blog_header_4_margin_mobile || styleGuide.blog_header_4_margin}
                          handleChange={(value) => handleChange(['blog_header_4', 'mobile', 'margin'], value)}
                        />
                      </>
                    )}
                  </div>
                </section>
              </article>
            )}

            {/* Header 5 */}
            {styleGuide.blog_header_count > 4 && (
              <article className={styles.StyleGuideEditor}>
                <section className={styles.styleSection} ref={myRef5}>
                  <div className={styles.styleSectionHeader}>
                    <p>Blog H5</p>
                    <div className={styles.controlsContainer}>
                      <div className={styles.bgToggles}>
                        <div className={styles.background}>
                          <span
                            className={classnames(styles.lightBackground, {
                              [styles.active]: activeBackground.blog_header_5 === 'light',
                            })}
                            onClick={() => setActiveBackground({ ...activeBackground, blog_header_5: 'light' })}
                          >
                            Light
                          </span>
                          <span
                            className={classnames(styles.darkBackground, {
                              [styles.active]: activeBackground.blog_header_5 === 'dark',
                            })}
                            onClick={() => setActiveBackground({ ...activeBackground, blog_header_5: 'dark' })}
                          >
                            Dark
                          </span>
                        </div>

                        <div className={styles.device}>
                          <span
                            onClick={() => setActiveBackground({ ...activeBackground, blog_header_5: 'light' })}
                            className={classnames(styles.lightBackground, {
                              [styles.active]: activeBackground.blog_header_5 === 'light',
                            })}
                          >
                            <SvgIconDesktopThumbnail />
                          </span>
                          <span
                            onClick={() => setActiveBackground({ ...activeBackground, blog_header_5: 'mobile' })}
                            className={classnames(styles.darkBackground, {
                              [styles.active]: activeBackground.blog_header_5 === 'mobile',
                            })}
                          >
                            <SvgIconMobileThumbnail />
                          </span>
                        </div>
                      </div>
                      {styleGuide.blog_header_count === 5 && (
                        <div
                          className={styles.deleteIcon}
                          onClick={() => {
                            handleChange(['blog_header_count'], styleGuide.blog_header_count - 1);
                          }}
                        >
                          <SvgIconTrash />
                        </div>
                      )}
                    </div>
                  </div>
                  <div>
                    <div
                      className={`sriracha ${
                        activeBackground.blog_header_5 === 'dark'
                          ? 'dark-bg'
                          : activeBackground.blog_header_5 === 'mobile'
                          ? 'mobile'
                          : ''
                      }`}
                      style={{
                        padding: activeBackground.blog_header_5 === 'dark' ? '1rem 2rem 0.5rem' : '0',
                        backgroundColor: activeBackground.blog_header_5 === 'dark' ? darkBgColor.code : 'inherit',
                      }}
                    >
                      <div className="blog-content">
                        <header style={{ margin: 0 }}>
                          <h5 style={{ textAlign: 'left' }}>Blog header 5</h5>
                        </header>
                      </div>
                    </div>
                  </div>
                  <div className={styles.styleSectionOptions}>
                    {activeBackground.blog_header_5 === 'light' ? (
                      <>
                        <div className={styles.styleOption}>
                          <span>Font family</span>
                          <Select
                            options={fontFamilyOptions}
                            defaultValue={fontFamilyOptions[styleGuide.blog_header_5_font_family_index]}
                            value={fontFamilyOptions[styleGuide.blog_header_5_font_family_index]}
                            onChange={(option: FontOptions) =>
                              handleChange(['blog_header_5', 'font_family_index'], option.value)
                            }
                          />
                        </div>
                        <div className={styles.styleOption}>
                          <span>Font Weight</span>
                          <Select
                            options={fontWeightOptions}
                            defaultValue={fontWeightOptions.find(
                              (weight) => weight.value === Number(styleGuide.blog_header_5_font_weight)
                            )}
                            value={fontWeightOptions.find(
                              (weight) => weight.value === Number(styleGuide.blog_header_5_font_weight)
                            )}
                            onChange={(option: FontOptions) =>
                              handleChange(['blog_header_5', 'font_weight'], option.value)
                            }
                          />
                        </div>
                        <div className={styles.styleOption}>
                          <span>Font size</span>
                          <input
                            type="number"
                            name="blog_header_5_font_size"
                            value={styleGuide.blog_header_5_font_size}
                            min="0.8"
                            max="10"
                            step="0.1"
                            onChange={(e) => handleChangeEvent(e, ['blog_header_5', 'font_size'])}
                          />
                        </div>
                        <div className={styles.styleOption}>
                          <span>Line height</span>
                          <input
                            type="number"
                            name="blog_header_5_line_height"
                            value={styleGuide.blog_header_5_line_height}
                            min="0.8"
                            max="2"
                            step="0.1"
                            onChange={(e) => handleChangeEvent(e, ['blog_header_5', 'line_height'])}
                          />
                        </div>
                        <CapitalizeSelect
                          value={styleGuide.blog_header_5_text_transform}
                          handleChange={(value) => handleChange(['blog_header_5', 'text_transform'], value)}
                        />
                        <LetterSpacingField
                          name="blog_header_5_letter_spacing"
                          value={styleGuide.blog_header_5_letter_spacing}
                          handleChange={(value) => handleChange(['blog_header_5', 'letter_spacing'], value)}
                        />
                        <MarginField
                          name="blog_header_5_margin"
                          value={styleGuide.blog_header_5_margin}
                          handleChange={(value) => handleChange(['blog_header_5', 'margin'], value)}
                        />
                        <div className={styles.styleOption}>
                          <span>Color</span>
                          <ColorSelector
                            name="blog_header_5_color"
                            onChange={(value: ColorOptions) => handleChange(['blog_header_5', 'color'], value)}
                            value={styleGuide.blog_header_5_color}
                            placeholder="Color"
                            handleColorSelection={(value: ColorOptions) =>
                              handleChange(['blog_header_5', 'color'], value)
                            }
                          />
                        </div>
                      </>
                    ) : activeBackground.blog_header_5 === 'dark' ? (
                      <div className={styles.styleOption}>
                        <span>Color</span>
                        <ColorSelector
                          name="blog_header_5_dark_bg_color"
                          onChange={(value: ColorOptions) => handleChange(['blog_header_5', 'dark_bg_color'], value)}
                          value={styleGuide.blog_header_5_dark_bg_color}
                          placeholder="Color"
                          handleColorSelection={(value: ColorOptions) =>
                            handleChange(['blog_header_5', 'dark_bg_color'], value)
                          }
                        />
                      </div>
                    ) : (
                      <>
                        <div className={styles.styleOption}>
                          <span>Font size</span>
                          <input
                            type="number"
                            name="blog_header_5_font_size_mobile"
                            value={styleGuide.blog_header_5_font_size_mobile}
                            min="0.8"
                            max="10"
                            step="0.1"
                            onChange={(e) => handleChangeEvent(e, ['blog_header_5', 'mobile', 'font_size'])}
                          />
                        </div>
                        <div className={styles.styleOption}>
                          <span>Line height</span>
                          <input
                            type="number"
                            name="blog_header_5_line_height_mobile"
                            value={styleGuide.blog_header_5_line_height_mobile || styleGuide.blog_header_5_line_height}
                            min="0.8"
                            max="2"
                            step="0.1"
                            onChange={(e) => handleChangeEvent(e, ['blog_header_5', 'mobile', 'line_height'])}
                          />
                        </div>
                        <LetterSpacingField
                          name="blog_header_5_letter_spacing_mobile"
                          value={
                            styleGuide.blog_header_5_letter_spacing_mobile || styleGuide.blog_header_5_letter_spacing
                          }
                          handleChange={(value) => handleChange(['blog_header_5', 'mobile', 'letter_spacing'], value)}
                        />
                        <MarginField
                          name="blog_header_5_margin_mobile"
                          value={styleGuide.blog_header_5_margin_mobile || styleGuide.blog_header_5_margin}
                          handleChange={(value) => handleChange(['blog_header_5', 'mobile', 'margin'], value)}
                        />
                      </>
                    )}
                  </div>
                </section>
              </article>
            )}

            {/* Header 6 */}
            {styleGuide.blog_header_count > 5 && (
              <article className={styles.StyleGuideEditor}>
                <section className={styles.styleSection} ref={myRef6}>
                  <div className={styles.styleSectionHeader}>
                    <p>Blog H6</p>
                    <div className={styles.controlsContainer}>
                      <div className={styles.bgToggles}>
                        <div className={styles.background}>
                          <span
                            className={classnames(styles.lightBackground, {
                              [styles.active]: activeBackground.blog_header_6 === 'light',
                            })}
                            onClick={() => setActiveBackground({ ...activeBackground, blog_header_6: 'light' })}
                          >
                            Light
                          </span>
                          <span
                            className={classnames(styles.darkBackground, {
                              [styles.active]: activeBackground.blog_header_6 === 'dark',
                            })}
                            onClick={() => setActiveBackground({ ...activeBackground, blog_header_6: 'dark' })}
                          >
                            Dark
                          </span>
                        </div>

                        <div className={styles.device}>
                          <span
                            onClick={() => setActiveBackground({ ...activeBackground, blog_header_6: 'light' })}
                            className={classnames(styles.lightBackground, {
                              [styles.active]: activeBackground.blog_header_6 === 'light',
                            })}
                          >
                            <SvgIconDesktopThumbnail />
                          </span>
                          <span
                            onClick={() => setActiveBackground({ ...activeBackground, blog_header_6: 'mobile' })}
                            className={classnames(styles.darkBackground, {
                              [styles.active]: activeBackground.blog_header_6 === 'mobile',
                            })}
                          >
                            <SvgIconMobileThumbnail />
                          </span>
                        </div>
                      </div>
                      {styleGuide.blog_header_count === 6 && (
                        <div
                          className={styles.deleteIcon}
                          onClick={() => {
                            handleChange(['blog_header_count'], styleGuide.blog_header_count - 1);
                          }}
                        >
                          <SvgIconTrash />
                        </div>
                      )}
                    </div>
                  </div>
                  <div>
                    <div
                      className={`sriracha ${
                        activeBackground.blog_header_6 === 'dark'
                          ? 'dark-bg'
                          : activeBackground.blog_header_6 === 'mobile'
                          ? 'mobile'
                          : ''
                      }`}
                      style={{
                        padding: activeBackground.blog_header_6 === 'dark' ? '1rem 2rem 0.5rem' : '0',
                        backgroundColor: activeBackground.blog_header_6 === 'dark' ? darkBgColor.code : 'inherit',
                      }}
                    >
                      <div className="blog-content">
                        <header style={{ margin: 0 }}>
                          <h6 style={{ textAlign: 'left' }}>Blog header 6</h6>
                        </header>
                      </div>
                    </div>
                  </div>
                  <div className={styles.styleSectionOptions}>
                    {activeBackground.blog_header_6 === 'light' ? (
                      <>
                        <div className={styles.styleOption}>
                          <span>Font family</span>
                          <Select
                            options={fontFamilyOptions}
                            defaultValue={fontFamilyOptions[styleGuide.blog_header_6_font_family_index]}
                            value={fontFamilyOptions[styleGuide.blog_header_6_font_family_index]}
                            onChange={(option: FontOptions) =>
                              handleChange(['blog_header_6', 'font_family_index'], option.value)
                            }
                          />
                        </div>
                        <div className={styles.styleOption}>
                          <span>Font Weight</span>
                          <Select
                            options={fontWeightOptions}
                            defaultValue={fontWeightOptions.find(
                              (weight) => weight.value === Number(styleGuide.blog_header_6_font_weight)
                            )}
                            value={fontWeightOptions.find(
                              (weight) => weight.value === Number(styleGuide.blog_header_6_font_weight)
                            )}
                            onChange={(option: FontOptions) =>
                              handleChange(['blog_header_6', 'font_weight'], option.value)
                            }
                          />
                        </div>
                        <div className={styles.styleOption}>
                          <span>Font size</span>
                          <input
                            type="number"
                            name="blog_header_6_font_size"
                            value={styleGuide.blog_header_6_font_size}
                            min="0.8"
                            max="10"
                            step="0.1"
                            onChange={(e) => handleChangeEvent(e, ['blog_header_6', 'font_size'])}
                          />
                        </div>
                        <div className={styles.styleOption}>
                          <span>Line height</span>
                          <input
                            type="number"
                            name="blog_header_6_line_height"
                            value={styleGuide.blog_header_6_line_height}
                            min="0.8"
                            max="2"
                            step="0.1"
                            onChange={(e) => handleChangeEvent(e, ['blog_header_6', 'line_height'])}
                          />
                        </div>
                        <CapitalizeSelect
                          value={styleGuide.blog_header_6_text_transform}
                          handleChange={(value) => handleChange(['blog_header_6', 'text_transform'], value)}
                        />
                        <LetterSpacingField
                          name="blog_header_6_letter_spacing"
                          value={styleGuide.blog_header_6_letter_spacing}
                          handleChange={(value) => handleChange(['blog_header_6', 'letter_spacing'], value)}
                        />
                        <MarginField
                          name="blog_header_6_margin"
                          value={styleGuide.blog_header_6_margin}
                          handleChange={(value) => handleChange(['blog_header_6', 'margin'], value)}
                        />
                        <div className={styles.styleOption}>
                          <span>Color</span>
                          <ColorSelector
                            name="blog_header_6_color"
                            onChange={(value: ColorOptions) => handleChange(['blog_header_6', 'color'], value)}
                            value={styleGuide.blog_header_6_color}
                            placeholder="Color"
                            handleColorSelection={(value: ColorOptions) =>
                              handleChange(['blog_header_6', 'color'], value)
                            }
                          />
                        </div>
                      </>
                    ) : activeBackground.blog_header_6 === 'dark' ? (
                      <div className={styles.styleOption}>
                        <span>Color</span>
                        <ColorSelector
                          name="blog_header_6_dark_bg_color"
                          onChange={(value: ColorOptions) => handleChange(['blog_header_6', 'dark_bg_color'], value)}
                          value={styleGuide.blog_header_6_dark_bg_color}
                          placeholder="Color"
                          handleColorSelection={(value: ColorOptions) =>
                            handleChange(['blog_header_6', 'dark_bg_color'], value)
                          }
                        />
                      </div>
                    ) : (
                      <>
                        <div className={styles.styleOption}>
                          <span>Font size</span>
                          <input
                            type="number"
                            name="blog_header_6_font_size_mobile"
                            value={styleGuide.blog_header_6_font_size_mobile}
                            min="0.8"
                            max="10"
                            step="0.1"
                            onChange={(e) => handleChangeEvent(e, ['blog_header_6', 'mobile', 'font_size'])}
                          />
                        </div>
                        <div className={styles.styleOption}>
                          <span>Line height</span>
                          <input
                            type="number"
                            name="blog_header_6_line_height_mobile"
                            value={styleGuide.blog_header_6_line_height_mobile || styleGuide.blog_header_6_line_height}
                            min="0.8"
                            max="2"
                            step="0.1"
                            onChange={(e) => handleChangeEvent(e, ['blog_header_6', 'mobile', 'line_height'])}
                          />
                        </div>
                        <LetterSpacingField
                          name="blog_header_6_letter_spacing_mobile"
                          value={
                            styleGuide.blog_header_6_letter_spacing_mobile || styleGuide.blog_header_6_letter_spacing
                          }
                          handleChange={(value) => handleChange(['blog_header_6', 'mobile', 'letter_spacing'], value)}
                        />
                        <MarginField
                          name="blog_header_6_margin_mobile"
                          value={styleGuide.blog_header_6_margin_mobile || styleGuide.blog_header_6_margin}
                          handleChange={(value) => handleChange(['blog_header_6', 'mobile', 'margin'], value)}
                        />
                      </>
                    )}
                  </div>
                </section>
              </article>
            )}
          </>
        )}
      </>
    );
  }
);
