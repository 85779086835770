import { useDispatch, useSelector } from 'react-redux';
import { selectHasPermission } from 'reducers/policyReducer';
import * as siteSelectors from '../../../reducers/sitesReducer';
import * as siteActions from '../../../actions/siteActions';
import * as uiDomainSettingsActions from '../../../actions/uiDomainSettingsActions';
import { Dispatch, useState } from 'react';
import React from 'react';

import { ReactComponent as SvgIconOptions } from '../../../assets/images/icon-options.svg';

import styles from './Domain.module.scss';
import withSite from 'containers/withSite';
import CheckPermissionOnClick from 'components/SubscriptionManager/CheckPermissionOnClick';
import Table from 'components/react-table/Table';
import DomainSettings from '../DomainSettings/DomainSettings';
import { USite } from 'types/USite';

interface DomainProps {
  site: USite;
}

function siteHasPendingDnsRecords(site: USite) {
  return Object.values(site.dns).some((r) => !r[3]);
}

function Domain(props: DomainProps) {
  const { site } = { ...props };
  const dispatch = useDispatch();

  const hasPermission = useSelector(selectHasPermission);
  const siteMapUrl = siteSelectors.getSiteMapUrlFromSite(site);
  const isDnsPending = siteSelectors.getIsDnsPendingFromSite(site);

  const [showDns, setShowDns] = useState<boolean>(isDnsPending || siteHasPendingDnsRecords(site));

  const updateDomainIsActive = (isActive: boolean) => {
    const updatedJSON: { is_domain_active: boolean; site_status?: string } = { is_domain_active: isActive };
    updatedJSON['site_status'] = isActive ? 'domain' : 'subdomain';
    dispatch(siteActions.update(updatedJSON));
  };
  const openDomainSettings = () => {
    // @ts-ignore
    dispatch(uiDomainSettingsActions.open());
  };

  const handleUnstackDomainClicked = () => {
    let doUpdate = true;
    if (!isDnsPending && site.is_domain_active) {
      doUpdate = window.confirm(
        `Are you sure you want to disable ${site.domain} and redirect all traffic to ${site.subdomain}.unstack.com?`
      );
    }
    doUpdate && updateDomainIsActive(false);
  };

  const handledomainClicked = () => {
    if (site.domain) {
      updateDomainIsActive(true);
    } else {
      openDomainSettings();
    }
  };

  const handleDomainOptionsClick = () => {
    openDomainSettings();
  };

  const handleToggleDnsClick = () => {
    setShowDns(!showDns);
    return true;
  };

  const onCopy = (event: React.ClipboardEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (event.clipboardData) {
      const target = event.target as HTMLElement;
      event.clipboardData.setData('text/plain', target.textContent);
    }
  };

  // DNS Table Data
  let data = site.dns ? Object.entries(site.dns) : [];
  data.sort((a, b) => a.length - b.length);
  let mappedData = data.map(function (row, i) {
    return {
      name: row[0],
      type: row[1][0],
      value: row[1][1] + (row[1][0] == 'CNAME' ? '.' : ''),
      status: row[1][3] ? <strong>properly configured</strong> : <strong>pending</strong>,
    };
  });

  if (!mappedData.length) {
    mappedData = null;
  }
  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
      Cell: (cellProps: any) => (
        <div
          onCopy={onCopy}
          onClick={() => document.execCommand('copy')}
          data-tip="Click to copy"
          className={styles.copyStatement}
        >
          {cellProps.value}
        </div>
      ),
      sortable: false,
      wrap: true,
    },
    {
      Header: 'Type',
      accessor: 'type',
      sortable: false,
      wrap: true,
    },
    {
      Header: 'Value',
      accessor: 'value',
      Cell: (cellProps: any) => (
        <div
          onCopy={onCopy}
          onClick={() => document.execCommand('copy')}
          data-tip="Click to copy"
          className={styles.copyStatement}
        >
          {cellProps.value}
        </div>
      ),
      sortable: false,
      wrap: true,
    },
    {
      Header: 'Status',
      accessor: 'status',
      sortable: false,
      wrap: true,
    },
  ];

  // Domain settings status indicators
  const domainActive =
    site.site_status == 'domain' && site.is_domain_active && hasPermission('Site:set_domain', 'subscription');
  const domainStatus = domainActive ? 'active' : isDnsPending ? site.dns_status : 'available';
  const domainClass = styles[domainStatus] || '';

  const proxyLabel = site.is_shopify ? 'Point to Shopify' : 'Reverse Proxy';
  const proxyActive = site.site_status === 'proxy' && hasPermission('Site:set_proxy', 'subscription');
  const proxyStatus = proxyActive ? 'active' : site.is_shopify ? 'available' : 'unavailable';
  const proxyStatusClass = styles[proxyStatus];

  const subdomainActive = !(domainActive || proxyActive);
  const subdomainStatus = subdomainActive ? 'active' : 'available';
  const subdomainClass = styles[subdomainStatus];

  return (
    <>
      <section id="domain">
        <header>
          <h1>Domain</h1>
        </header>
        <article className={styles.domain}>
          <form>
            <fieldset>
              {hasPermission('Site:set_subdomain', 'subscription') && (
                <div>
                  <label>
                    <input type="radio" checked={subdomainActive} onChange={handleUnstackDomainClicked} />
                    <span className="label-body">Elastic Path Studio domain</span>
                  </label>
                  <small>{site.subdomain}.unstack.website</small>
                  <span className={`${styles.status} ${subdomainClass}`}>{subdomainStatus}</span>
                </div>
              )}
              {hasPermission('Site:set_domain', 'subscription') && (
                <div>
                  <label htmlFor="custom-domain">
                    <input id="custom-domain" type="radio" checked={domainActive} onChange={handledomainClicked} />
                    <span className="label-body">Custom domain</span>
                  </label>
                  <small>{site.domain}</small>
                  {domainStatus && (
                    <>
                      <span className={`${styles.status} ${domainClass}`}>{domainStatus.replace('_', ' ')}</span>
                      {site.load_balancer && (
                        <small>
                          Hosted in Web Zone{' '}
                          {site.load_balancer.toLocaleString('en-US', {
                            minimumIntegerDigits: 2,
                            useGrouping: false,
                          })}{' '}
                          <a href="https://status.unstack.com/" target="_blank">
                            Status
                          </a>
                        </small>
                      )}
                      <span className={styles.siteMapLinks}>
                        {hasPermission('Site:sitemap') && (
                          <>
                            <a href={siteMapUrl} target="_blank" rel="noopener">
                              View Sitemap
                            </a>
                            <a
                              href="https://support.google.com/webmasters/answer/7451001"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Submitting to Google
                            </a>
                          </>
                        )}
                        {site.dns && mappedData && !isDnsPending && (
                          <a href="javascript:void(0)" onClick={handleToggleDnsClick}>
                            {showDns ? 'Hide' : 'Show'} DNS Settings
                          </a>
                        )}
                        {!site.certificate_id && (
                          <CheckPermissionOnClick
                            action="Site:add_ssl"
                            onClick={handleDomainOptionsClick}
                            render={(handleClick: (ev: React.MouseEvent) => void) => {
                              return (
                                <a href="javascript:void(0)" onClick={handleClick}>
                                  Configure SSL
                                </a>
                              );
                            }}
                          />
                        )}
                      </span>
                      {site.domain && (
                        <span
                          className={styles.options}
                          data-test-id="domain-settings"
                          onClick={handleDomainOptionsClick}
                        >
                          <SvgIconOptions />
                        </span>
                      )}
                    </>
                  )}
                </div>
              )}
              {hasPermission('Site:set_proxy', 'subscription') && (
                <div>
                  <label>
                    <input id="custom-domain" type="radio" checked={proxyActive} readOnly />
                    <span className="label-body">{proxyLabel}</span>
                  </label>
                  <small>
                    {site.domain}
                    {site.proxy_paths.page}
                  </small>
                  <span className={`${styles.status} ${proxyStatusClass}`}>{proxyStatus}</span>
                </div>
              )}
              {!hasPermission('Site:set_domain', 'subscription') && !hasPermission('Site:set_proxy', 'subscription') && (
                <div>
                  <label htmlFor="custom_domain_input">
                    <input type="radio" id="custom_domain_input" disabled />
                    <del className="label-body">Custom domain</del>
                  </label>
                  <small>Upgrade below to use a custom domain for your website</small>
                </div>
              )}
            </fieldset>
            {site.dns && mappedData && (showDns || isDnsPending) && (
              <div className={styles.dns}>
                {!isDnsPending ? (
                  <label>DNS Settings</label>
                ) : (
                  <>
                    <label>Action required</label>
                    <div className={styles.actions}>
                      <p>
                        To publish content to this domain, you must update the records below with your DNS provider.
                      </p>
                      <a
                        href="https://elasticpath.dev/docs/cx-studio/domain-management/setting-up-your-custom-domain"
                        target="_blank"
                        rel="noopener"
                      >
                        View our DNS help guide
                      </a>
                    </div>
                  </>
                )}
                <Table columns={columns} data={mappedData} />
              </div>
            )}
          </form>
        </article>
      </section>
      {hasPermission('Site:set_domain') && <DomainSettings />}
    </>
  );
}

export default withSite(Domain);
