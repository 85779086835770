import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import DocumentTitle from 'react-document-title';

import * as api from '../../services/spark-api';

import styles from './Invitation.module.scss';

import Header from './Header';
import Spinner from '../base/Spinner';
import { loginSuccess } from '../../actions/accountActions';
import { initializeApp } from '../../actions/appActions';

const Invitation = () => {
  const [state, setState] = useState({
    hasValidToken: false,
    isLoading: true,
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    account: '',
    logo: '',
    message: '',
    errors: {} as { [key: string]: any },
    is_active: false,
  });

  const { token } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    getInvitation();
  }, []);

  const getInvitation = () => {
    api.getInvitation(token).then((response) => {
      setState({
        hasValidToken: response.status === 200,
        isLoading: false,
        ...response.json,
      });
    });
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setState((prevState) => ({
      ...prevState,
      isFetching: true,
    }));

    const user = {
      first_name: state.first_name,
      last_name: state.last_name,
      password: state.password,
    };

    api.acceptInvitation(token, user).then((response) => {
      if (response.status === 200) {
        const jwtToken = response.json.token;
        dispatch(loginSuccess(jwtToken));
        dispatch(initializeApp());
        navigate('/');
      } else {
        const errors = response.json.errors;
        setState((prevState) => ({
          ...prevState,
          isFetching: false,
          errors,
        }));
      }
    });
  };

  return (
    <div className="admin">
      <DocumentTitle title="Welcome" />
      <Header center={state.account && `Join ${state.account} on Elastic Path Studio`} />
      <main className={styles.main}>
        {state.isLoading ? (
          <div className={styles.message}>
            <Spinner />
          </div>
        ) : state.hasValidToken === false ? (
          <div className={styles.message}>
            <p>This invitation is no longer available. {state.message}</p>
            <p>Please ask your account administrator for further assistance.</p>
          </div>
        ) : (
          <>
            {state.logo && (
              <div className={styles.logo}>
                <img src={state.logo} alt="" />
              </div>
            )}
            <form className={styles.form} onSubmit={handleSubmit}>
              {!state.is_active && (
                <div className={styles.twoFields}>
                  <fieldset>
                    <label htmlFor="first_name_input">First name</label>
                    <input
                      id="first_name_input"
                      name="first_name"
                      value={state.first_name}
                      type="text"
                      onChange={handleChange}
                      autoComplete="given-name"
                      className={state.errors && state.errors.first_name ? 'fieldWithErrors' : ''}
                      autoFocus
                    />
                    {state.errors && state.errors.first_name && (
                      <small className="errorMessage">{state.errors.first_name}</small>
                    )}
                  </fieldset>
                  <fieldset>
                    <label htmlFor="last_name_input">Last name</label>
                    <input
                      id="last_name_input"
                      name="last_name"
                      value={state.last_name}
                      type="text"
                      onChange={handleChange}
                      autoComplete="family-name"
                      className={state.errors && state.errors.last_name ? 'fieldWithErrors' : ''}
                    />
                    {state.errors && state.errors.last_name && (
                      <small className="errorMessage">{state.errors.last_name}</small>
                    )}
                  </fieldset>
                </div>
              )}
              <fieldset>
                <label htmlFor="email_input">Email</label>
                <input id="email_input" name="email" value={state.email} disabled readOnly type="email" />
              </fieldset>
              {!state.is_active && (
                <fieldset>
                  <label htmlFor="password_input">Password</label>
                  <input
                    id="password_input"
                    name="password"
                    value={state.password}
                    type="password"
                    onChange={handleChange}
                    autoComplete="new-password"
                    className={state.errors && state.errors.password ? 'fieldWithErrors' : ''}
                  />
                  {state.errors && state.errors.password && (
                    <small className="errorMessage">
                      {state.errors.password}
                      <br />
                    </small>
                  )}
                </fieldset>
              )}
              <input type="submit" className="button button-success button-full" value="Accept invite" />
              <small className={styles.terms}>
                By signing up for Elastic Path Studio you agree to its{' '}
                <a href="https://www.unstack.com/terms-of-use/" target="_blank" rel="noopener noreferrer">
                  Terms of Service
                </a>{' '}
                and{' '}
                <a href="https://www.unstack.com/privacy-policy/" target="_blank" rel="noopener noreferrer">
                  Privacy Policy
                </a>
              </small>
            </form>
          </>
        )}
      </main>
    </div>
  );
};

export default Invitation;
