import React from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { set as immutableSet } from 'object-path-immutable';

import FormControl from '../base/FormHelpers/FormControl';
import NonFieldErrors from '../base/FormHelpers/NonFieldErrors';
import PrefixedInput from '../base/FormHelpers/PrefixedInput';
import { Checkbox } from '../Checkbox';

import { selectHasPermission } from '../../reducers/policyReducer';
import { selectSubmissionStateAndEvent } from '../../reducers/uiSubmitButtonsStateReducer';
import { selectAllActiveProducts } from '../../reducers/paymentReducer';
import * as siteLayoutSelector from 'reducers/siteLayoutReducer';
import styles from '../DangerZone.module.scss';
import settingStyles from '../Articles/ArticleSetting.module.scss';
import BottomDrawer from '../base/BottomDrawer';
import MediaDrawerBottom from '../base/Media/Drawer/MediaDrawerBottom';
import { set } from '../../lib/immutable-operations/operations';
import LinkField from './LinkField';
import isUUID from 'lib/isUUID';
import TemplateUsage from './TemplateUsage';
import DatatableControl from './DatatableControl';

function mapStateToProps(state, ownProps) {
  return {
    hasPermission: selectHasPermission(state),
    submittingStateAndEvent: selectSubmissionStateAndEvent(state),
    products: selectAllActiveProducts(state),
    headerSections: siteLayoutSelector.selectHeaderSectionsList(state),
    footerSections: siteLayoutSelector.selectFooterSectionsList(state),
    layout: siteLayoutSelector.selectLayoutByItemType(ownProps.page.item_type)(state),
  };
}

class PageForm extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isDrawerOpen: false,
    };
  }

  componentDidMount() {
    const { page } = this.props;
    const isPdp = page.item_type === 'product';
    const isPlp = page.item_type === 'collection';

    if ((isPdp || isPlp) && !page.meta.description) {
      this.handleChange('meta.description', `{{ ${isPdp ? 'product' : 'category'}.attributes.description }}`);
    }
    if ((isPdp || isPlp) && !page.meta.title) {
      const ops = [
        set('slug', `${isPdp ? 'product' : 'category'}/%`),
        set('name', `${isPdp ? 'Product' : 'Category'}`),
        set('meta.title', `{{ ${isPdp ? 'product' : 'category'}.attributes.name }}`),
      ];
      this.props.addOps(ops);
    }
  }

  handleChange = (nameOrEvent, value) => {
    if (!this.props.isFetching) {
      // Accept (name, value) argument pair or event
      let name, event;
      if (nameOrEvent.target) {
        event = nameOrEvent;
        name = nameOrEvent.target.name;
        value = nameOrEvent.target.value;
      } else {
        name = nameOrEvent;
      }

      if (name === 'is_indexable') {
        value = !event.target.checked;
      }

      if (name === 'meta.is_premium') this.props.addOps(set('is_restricted', value === 'yes'));

      if (name === 'is_restricted') {
        value = event.target.checked;
        if (!value)
          this.setState((prevState) => ({
            page: immutableSet(prevState.page, 'required_subscriptions', []),
          }));
      }

      if (['header', 'footer'].includes(name)) {
        if (!value) {
          this.props.addOps([set(name, null), set(`config.${name}_layout`, null)]);
        } else if (isUUID(value)) {
          this.props.addOps([set(name, value), set(`config.${name}_layout`, null)]);
        } else {
          this.props.addOps([set(name, null), set(`config.${name}_layout`, value)]);
        }
        return;
      }

      if (name === 'template_objects') {
        this.props.addOps(set(name, value));
        return;
      }

      if (name === 'meta.image') {
        value = value || null;
      }

      this.props.addOps(set(name, value));
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.props.handleSubmit();
  };

  handlePublish = (e) => {
    e.preventDefault();
    this.props.handleSubmit(true);
  };

  render() {
    const {
      page,
      errors,
      featuredMedia,
      fullDomain,
      hasPermission,
      submittingStateAndEvent,
      headerSections,
      footerSections,
      layout = { default_header: null, default_footer: null },
    } = this.props;

    const isPdp = page.item_type === 'product';
    const isPlp = page.item_type === 'collection';

    const defaultBuiltin = page.item_type === 'landing_page' ? 'minimal' : 'full';

    const pageHeaderSelectedValue = (page.config && page.config.header_layout) || page.header || '';
    const pageFooterSelectedValue = (page.config && page.config.footer_layout) || page.footer || '';

    const headerTypes = {
      full: 'Full header',
      minimal: 'Minimal',
      none: 'No header',
    };
    const footerTypes = {
      full: 'Full footer',
      minimal: 'Minimal',
      none: 'No footer',
    };

    const isHomePage = page.slug === '' && page.status === 'published';

    return (
      <form onSubmit={this.handleSubmit}>
        <fieldset>
          <label htmlFor="page_title_input">Title</label>
          <FormControl
            id="page_title_input"
            type="text"
            name="meta.title"
            value={page.meta.title || ''}
            onChange={this.handleChange}
            errors={errors.title}
            autoComplete="off"
            maxLength={65}
            autoFocus
          />
          <small className={settingStyles.helpText}>65 characters or less. Used in the browser tab.</small>
        </fieldset>
        <fieldset>
          <label htmlFor="page_name_input">Elastic Path name</label>
          <FormControl
            id="page_name_input"
            type="text"
            name="name"
            imitate={isPdp || isPlp ? undefined : page.meta.title}
            value={page.name || ''}
            errors={errors.name}
            onChange={this.handleChange}
            autoComplete="off"
            maxLength={65}
            alwaysImitate
          />
          <small className={settingStyles.helpText}>Only used in your Elastic Path Studio Admin portal.</small>
        </fieldset>
        <LinkField
          id="page_url_input"
          isHomePage={isHomePage}
          PrefixedInput={PrefixedInput}
          prefix={fullDomain}
          page={page}
          handleChange={this.handleChange}
          errors={errors}
          pageUrl={page.absolute_url}
        />
        {page.item_type === 'data_page' && <DatatableControl page={page} handleChange={this.handleChange} />}
        {!isPdp && (
          <>
            <fieldset>
              <label htmlFor="canonical_input">Canonical URL</label>
              <FormControl
                id="canonical_input"
                type="text"
                name="meta.canonical"
                value={page.meta.canonical || ''}
                onChange={this.handleChange}
                autoComplete="off"
              />
              <small className={settingStyles.helpText}>Leave blank to be prefill with page's actual URL.</small>
            </fieldset>
            <fieldset>
              <label htmlFor="keywords_input">Meta Keywords</label>
              <FormControl
                id="keywords_input"
                type="text"
                name="meta.keywords"
                value={page.meta.keywords || ''}
                onChange={this.handleChange}
                errors={errors.keywords}
                autoComplete="off"
              />
            </fieldset>
          </>
        )}
        {
          <fieldset>
            <label htmlFor="page_description_input">Meta Description</label>
            <FormControl
              id="page_description_input"
              type="text"
              name="meta.description"
              value={page.meta.description}
              onChange={this.handleChange}
              errors={errors.description}
              autoComplete="off"
            />
          </fieldset>
        }
        {
          <fieldset>
            <label htmlFor="page_header_layout_input">Header layout</label>
            <FormControl
              id="page_header_layout_input"
              name="header"
              onChange={this.handleChange}
              value={pageHeaderSelectedValue}
              autoComplete="off"
              tag="select"
              placeholder="Header layout"
            >
              {headerSections.map((header) => {
                return (
                  <option key={header.id} value={header.id === layout.default_header ? '' : header.id}>
                    {header.name} {header.id === layout.default_header && '(Default)'}
                  </option>
                );
              })}
              {Object.keys(headerTypes).map((headerTypeKey) => {
                const headerTypeVal = headerTypes[headerTypeKey];

                return (
                  <option
                    key={headerTypeKey}
                    value={defaultBuiltin === headerTypeKey && layout.default_header === null ? '' : headerTypeKey}
                    selected={pageHeaderSelectedValue === headerTypeKey}
                  >
                    {headerTypeVal}{' '}
                    {!!(layout.default_header === null && defaultBuiltin === headerTypeKey) && '(Default)'}
                  </option>
                );
              })}
            </FormControl>
          </fieldset>
        }
        {
          <fieldset>
            <label htmlFor="page_header_layout_input">Footer layout</label>
            <FormControl
              id="page_footer_layout_input"
              name="footer"
              onChange={this.handleChange}
              value={pageFooterSelectedValue}
              autoComplete="off"
              tag="select"
              placeholder="Footer layout"
            >
              {footerSections.map((footer) => {
                return (
                  <option
                    key={footer.id}
                    value={footer.id === layout.default_footer ? '' : footer.id}
                    selected={pageHeaderSelectedValue === footer.component}
                  >
                    {footer.name} {footer.id === layout.default_footer && '(Default)'}
                  </option>
                );
              })}
              {Object.keys(footerTypes).map((footerTypeKey) => {
                const footerTypeVal = footerTypes[footerTypeKey];
                return (
                  <option
                    key={footerTypeKey}
                    value={defaultBuiltin === footerTypeKey && layout.default_footer === null ? '' : footerTypeKey}
                    selected={pageFooterSelectedValue === footerTypeKey}
                  >
                    {footerTypeVal}{' '}
                    {!!(layout.default_footer === null && defaultBuiltin === footerTypeKey) && '(Default)'}
                  </option>
                );
              })}
            </FormControl>
          </fieldset>
        }
        {isPdp && <TemplateUsage handleChange={this.handleChange} templateObjects={page.template_objects} />}
        {!isPdp && (
          <>
            {hasPermission('Payments:*') && (
              <fieldset>
                <label>Designate this as premium content?</label>
                <FormControl
                  tag="select"
                  type="text"
                  name="meta.is_premium"
                  value={page.meta.is_premium || 'no'}
                  onChange={this.handleChange}
                >
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </FormControl>
              </fieldset>
            )}

            <fieldset className={settingStyles.imageUploader}>
              <label>Featured image</label>
              <div onClick={() => this.setState({ isDrawerOpen: true })}>
                <span className={settingStyles.fileName}>
                  {featuredMedia
                    ? `Change image - ${featuredMedia.metadata.file_name || featuredMedia.file.split('/').slice(-1)[0]}`
                    : 'Choose image (720px by 400px)'}
                </span>
                <span
                  className={settingStyles.imageViewer}
                  style={{
                    ...(featuredMedia && {
                      backgroundImage: `url(${featuredMedia.thumbnail || featuredMedia.url})`,
                    }),
                  }}
                />
              </div>
              <BottomDrawer
                extendBody
                hideScroll
                isOpen={this.state.isDrawerOpen}
                close={() => this.setState({ isDrawerOpen: false })}
              >
                <MediaDrawerBottom
                  close={() => this.setState({ isDrawerOpen: false })}
                  category="image"
                  entity={{
                    src: page.meta.image,
                  }}
                  onChange={(media) => {
                    this.handleChange('meta.image', media && media.src);
                  }}
                  hideVideo
                  hideEmbeds
                />
              </BottomDrawer>
            </fieldset>
          </>
        )}

        {(hasPermission('Page:deindex') || hasPermission('Site:set_homepage')) && (
          <>
            <h3 className={styles.dangerHeader}>Danger zone</h3>
            <div className={styles.dangerDiv}>
              {hasPermission('Page:deindex') && (
                <fieldset>
                  <label className="checkbox" data-test-id="hide-from-search-engine-toggle">
                    <FormControl
                      aria-label="Hide page from search engine"
                      type="checkbox"
                      name="is_indexable"
                      checked={page.is_indexable === undefined ? page.item_type === 'landing_page' : !page.is_indexable}
                      onChange={this.handleChange}
                    />
                    <Checkbox checked={!page.is_indexable} />
                    <span>Hide this page from search engines</span>
                  </label>
                  <small className={settingStyles.helpText}>This will block search engines from this page.</small>
                </fieldset>
              )}
            </div>
          </>
        )}
        <NonFieldErrors errors={errors} />
        {hasPermission('Page:update') && (
          <>
            {hasPermission('Page:publish') && (
              <button type="button" className="button button-primary button-full" onClick={this.handlePublish}>
                {submittingStateAndEvent.isSubmitting && submittingStateAndEvent.publish
                  ? 'Saving and publish...'
                  : 'Save and publish'}
              </button>
            )}
            <input
              type="submit"
              className="button button-full"
              value={
                submittingStateAndEvent.isSubmitting && !submittingStateAndEvent.publish ? 'Saving...' : 'Save only'
              }
            />
          </>
        )}
      </form>
    );
  }
}

export default connect(mapStateToProps, null)(PageForm);
