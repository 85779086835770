/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import withIntegrations from '../../../containers/withIntegrations';
import { selectHasPermission } from '../../../reducers/policyReducer';
import SideDrawer from '../../base/SideDrawer';
import FormControl from '../../base/FormHelpers/FormControl';
import SparkForm from './Forms/SparkForm';
import { FormStyling } from './FormStyling';

import { SmartIntegrationFormInput as SmartFormInput } from '../../base/FormHelpers/SmartFormInput';

import styles from './FormEditor.module.scss';

function mapStateToProps(state) {
  return {
    hasPermission: selectHasPermission(state),
  };
}

class FormEditor extends React.PureComponent {
  constructor(props) {
    super(props);

    const defaultValue = props.unstackOnly ? { type: 'unstack_form' } : {};

    this.state = {
      value: props.value || defaultValue,
      name: '',
    };
  }

  componentDidUpdate(prevProps) {
    // Replace state.value if props.value changes or if the drawer opens.
    if (!prevProps.isOpen && this.props.isOpen) {
      const { value } = this.props;
      this.setState({
        value: !value || value === 'PLACEHOLDER' ? { type: 'unstack_form' } : value,
      });
    }
  }

  updateValue(name, value) {
    this.setState((prevState) => ({
      value: {
        ...prevState.value,
        [name]: value,
      },
    }));
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.updateValue(name, value);
  };

  handleTypeChange = (e) => {
    // Reset/clear the page.content.header.form property when the
    // form type changes. This is because different form types may have
    // different fields required.
    this.setState({
      value: { type: e.target.value, background: this.state.value.background },
    });
  };

  handleSubmit = (e, form) => {
    e.preventDefault();

    let { value } = this.state;

    value = form || value;

    // Remove form if value is an empty object or if it only contains the
    // `type` key. Otherwise, pass value to this.props.onChange.
    const keys = Object.keys(value);
    if (keys.length === 0 || (keys.length === 1 && keys[0] === 'type')) {
      this.remove();
    } else {
      if (value.id || value.type === 'klaviyo' || value.type === 'hubspot') {
        this.props.onChange(value);
        this.setState((prevState) => ({
          ...prevState,
          value: {},
        }));
        this.props.close();
      }
    }
  };

  handleClickRemove = (e) => {
    e.preventDefault();
    this.remove();
  };

  remove() {
    this.props.onChange(null);
    this.props.close();
  }

  clearForm() {
    this.setState((prevState) => ({
      ...prevState,
      name: '',
    }));
  }

  render() {
    const { isOpen, close, integrationsBySlug = {}, hubspotForms, sectionId, hasPermission } = this.props;

    const { value, name } = this.state;
    const hasIntegration = !!integrationsBySlug[value.type];

    return (
      <SideDrawer title="Form options" isOpen={isOpen} close={close}>
        <form onSubmit={this.handleSubmit}>
          {this.props.unstackOnly && (!value || !value.type || value.type === 'unstack_form') ? (
            <fieldset>
              <label>Elastic Path Studio form</label>
            </fieldset>
          ) : (
            <fieldset>
              <label htmlFor="form-type">Form type</label>
              <FormControl
                id="form-type"
                tag="select"
                name="content.header.form.type"
                onChange={this.handleTypeChange}
                value={value.type || ''}
                autoComplete="off"
              >
                <option value=""></option>
                <option value="hubspot">HubSpot</option>
                <option value="klaviyo">Klaviyo</option>
                <option value="unstack_form">Elastic Path Studio</option>
              </FormControl>
            </fieldset>
          )}

          {/* HubSpot */}
          {value.type === 'hubspot' && !hasIntegration && (
            <fieldset>
              <small style={{ textAlign: 'center' }}>
                Before you can use a HubSpot form, you need to{' '}
                {hasPermission('SiteIntegration:create:::hubspot', 'role') ? (
                  <Link to={`/${this.props.urlSite}/integrations`}>add the integration</Link>
                ) : (
                  <>ask your administrator to add the integration</>
                )}{' '}
                to your Elastic Path Studio account.
              </small>
            </fieldset>
          )}
          {value.type === 'hubspot' && hasIntegration && (
            <>
              <>
                <SmartFormInput
                  onSuggestionSelect={(form) => this.updateValue('formId', form.id)}
                  onChange={(name) => this.setState({ name: name })}
                  onNameTagRemove={() => {
                    this.clearForm();
                  }}
                  value={name}
                  formId={value?.formId}
                />
                <small style={{ marginBottom: '15px' }}>
                  Elastic Path Studio pulls in published forms from your connected HubSpot account
                </small>
              </>

              <FormStyling
                onUpdate={(background) => this.setState({ value: { ...value, background } })}
                background={value.background === '' ? '' : 'dark-bg'}
              />
            </>
          )}

          {/* Klaviyo */}
          {value.type === 'klaviyo' && !hasIntegration && (
            <fieldset>
              <small style={{ textAlign: 'center' }}>
                Before you can use a Klaviyo form, you need to{' '}
                {hasPermission('SiteIntegration:create:::klaviyo', 'role') ? (
                  <Link to={`/${this.props.urlSite}/integrations`}>add the integration</Link>
                ) : (
                  <>ask your administrator to add the integration</>
                )}{' '}
                to your Elastic Path Studio account.
              </small>
            </fieldset>
          )}
          {value.type === 'klaviyo' && hasIntegration && (
            <>
              <fieldset>
                <label>Klaviyo Form ID</label>
                <FormControl
                  type="text"
                  name="formId"
                  onChange={this.handleInputChange}
                  value={value.formId}
                  autoComplete="off"
                  autoFocus
                />
                <small>
                  Make sure your form is published in Klaviyo.
                  <br />
                  <a href="https://www.unstack.com/blog/klaviyo-forms" target="_blank" rel="noopener noreferrer">
                    Check out our instructions here.
                  </a>
                </small>
              </fieldset>
              <FormStyling
                onUpdate={(background) => this.setState({ value: { ...value, background } })}
                background={value.background === '' ? '' : 'dark-bg'}
              />
            </>
          )}

          {value.type === 'unstack_form' ? (
            <>
              <SparkForm
                onSave={this.handleSubmit}
                onDelete={this.handleClickRemove}
                onDiscard={close}
                sectionId={sectionId}
                formId={value.id}
                {...(value.id ? { onRemove: this.handleClickRemove } : {})}
                background={value.background === '' ? '' : 'dark-bg'}
                updateBackground={(background) => this.setState({ value: { ...value, background } })}
              />
            </>
          ) : (
            <>
              <fieldset>
                <input type="submit" value="Save" className="button button-primary button-full" />
              </fieldset>
              {this.props.value != null && (
                <fieldset>
                  <a href="#" role="button" className={styles.remove} onClick={this.handleClickRemove}>
                    Remove
                  </a>
                </fieldset>
              )}
            </>
          )}
        </form>
      </SideDrawer>
    );
  }
}

export default withIntegrations(connect(mapStateToProps)(FormEditor));
