import React, { MouseEvent, useCallback } from 'react';
import classNames from 'classnames';
import { useParams } from 'react-router';
import { NavLink, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectHasPermission } from 'reducers/policyReducer';
import ExpansionPanel from 'components/AdminPanel/Quickstart/ExpansionPanel';
import styles from 'components/AdminPanel/Quickstart/CreateFirstPagePanel.module.scss';
import { getIsFirstPageEditedOrCreated } from 'components/AdminPanel/Quickstart/helpers';
import { ReactComponent as NavArrowIcon } from 'assets/images/nav-arrow-icon.svg';
import { ReactComponent as ContentLinksBackground } from 'assets/images/content-links-background.svg';
import { selectAccount } from 'reducers/accountReducer';
import {
  CREATE_OR_EDIT_FIRST_PAGE_PENDING,
  CREATE_OR_EDIT_FIRST_PAGE_COMPLETE,
} from 'components/AdminPanel/Quickstart/constants';
import { patchOnboarding, removeOnboardingStep } from 'actions/accountActions';
import { useQuickstartStep } from 'components/AdminPanel/Quickstart/hooks/useQuickstartStep';
import useActiveSite from 'hooks/useActiveSite';
import * as integrationSelection from 'reducers/integrationsReducer';
import NavOption from '../NavOption';

import navStyles from '../Nav.module.scss';
import { ONBOARDING_SHOPIFY_CONTENT_NAV_ITEM } from '../Nav/NavItems';
import { ONBOARDING_BASIC_CONTENT_NAV_ITEM } from '../Nav/NavItems';

const noop = () => {};

const CreateOrEditContent = (props: any) => {
  const hasPermission = useSelector(selectHasPermission);
  const navigate = useNavigate();
  const { urlSite } = useParams<{ urlSite: string }>();

  const handleNavigationItemClick = useCallback((e: MouseEvent, path: string) => {
    e.preventDefault();
    navigate(path);
  }, []);

  return (
    <div className={styles.createFirstPageContent}>
      <div>
        <p>
          The page editor is the heart of the Elastic Path Studio experience and is a big part of what so many people
          love about Elastic Path Studio!
        </p>

        {props.isShopifyConnected ? (
          <p>
            Click <span>Content</span>, then <span>Landing pages</span> in the sidebar and create your first landing
            page for a product or campaign.
          </p>
        ) : (
          <p>
            Go to <span>Website</span> in the sidebar and create or edit your first page. You may also want to explore{' '}
            <span>Landing pages</span> and <span>Blog</span>.
          </p>
        )}
        {props.isShopifyConnected ? (
          <NavLink style={{ marginTop: '20px' }} className="button button-secondary" to={`/${urlSite}/landing-pages`}>
            Get started
          </NavLink>
        ) : (
          <NavLink to={`/${urlSite}/pages`}>Learn more</NavLink>
        )}
      </div>
      <div className={classNames(styles.navContainer, navStyles.quickStartItems)}>
        {props.isShopifyConnected ? (
          <>
            <NavArrowIcon className={styles.navArrowIcon} />
            {hasPermission('Item:list', 'role') && (
              <NavOption
                setPanel={() => {}}
                panel={ONBOARDING_SHOPIFY_CONTENT_NAV_ITEM.panel}
                item={ONBOARDING_SHOPIFY_CONTENT_NAV_ITEM}
                handleNavigationItemClick={() => {}}
              />
            )}
          </>
        ) : (
          <>
            <NavArrowIcon className={styles.navArrowIcon} />
            {hasPermission('Item:list', 'role') && (
              <NavOption
                setPanel={() => {}}
                panel={ONBOARDING_BASIC_CONTENT_NAV_ITEM.panel}
                item={ONBOARDING_BASIC_CONTENT_NAV_ITEM}
                handleNavigationItemClick={() => {}}
              />
            )}
          </>
        )}
      </div>
      <ContentLinksBackground className={styles.navLinkBackground} />
    </div>
  );
};

export default function CreateFirstPagePanel() {
  const account = useSelector(selectAccount);
  const isStepComplete = getIsFirstPageEditedOrCreated();
  const activeSite = useActiveSite();
  const accountMetadata = account.metadata?.onboarding || [];
  const isShopifyConnected = Boolean(
    useSelector((state) =>
      integrationSelection.selectIntegrationBySlug(
        state,
        //  @ts-ignore
        activeSite.id,
        'shopify'
      )
    )
  );
  const { open, handleExpansionPanelClick, handleIconClick } = useQuickstartStep({
    isStepComplete,
    metadata: accountMetadata,
    id: account.id,
    pendingDescription: CREATE_OR_EDIT_FIRST_PAGE_PENDING,
    completedDescription: CREATE_OR_EDIT_FIRST_PAGE_COMPLETE,
    patchOnboarding,
    removeOnboardingStep,
  });

  return (
    <ExpansionPanel
      title={`Create or edit your first ${isShopifyConnected ? 'landing' : ''} page`}
      isComplete={isStepComplete}
      open={open}
      onClick={handleExpansionPanelClick}
      onIconClick={handleIconClick}
    >
      <CreateOrEditContent isShopifyConnected={isShopifyConnected} />
    </ExpansionPanel>
  );
}
