/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import SideDrawer from '../../../base/SideDrawer';
import { ReactComponent as SvgCopy } from '../../../../assets/images/icon-copy.svg';
import { ReactComponent as SvgCheckboxChecked } from '../../../../assets/images/icon-copied.svg';
import styles from '../../Integrations.module.scss';
import withSiteId from 'containers/withSiteId';
import { compose } from 'redux';
import { selectSiteIntegrationsByType } from 'reducers/integrationsReducer';
import { receiveUpdate, deleteIntegrationRequest, patch, create } from '../../../../actions/integrationActions';
import { connect } from 'react-redux';
import idx from 'lodash/get';

const mapStateToProps = (state, ownProps) => {
  const integrations = selectSiteIntegrationsByType(state, ownProps.siteId, ownProps.id);
  let integration = idx(integrations, 0);
  return {
    siteId: ownProps.siteId,
    config: integration ? integration.config : { api_key: '', url: '' },
    integration,
  };
};
const mapDispatchToProps = {
  receiveUpdate,
  deleteIntegrationRequest,
  create,
  patch,
};
class ZapierDrawer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      siteIdCopied: false,
      apiKeyCopied: false,
      isSubmitting: false,
      showError: false,
      config: 'api_key' in props.config ? { ...props.config, refresh_api_key: true } : props.config,
    };
  }

  handleSubmit = (e, config) => {
    e.preventDefault();
    this.setState({ isSubmitting: true });
    (this.props.integration == null ? this.createIntegration(config) : this.updateIntegration(config)).promise
      .then((response) => this.props.receiveUpdate(response))
      .catch((err) => console.error(err))
      .finally(() => this.setState({ isSubmitting: false, showError: false }));
  };
  createIntegration = (config) => {
    const { siteId, id } = this.props;
    return this.props.create({
      integration: id,
      siteId,
      config,
    });
  };
  updateIntegration = (config) => {
    const { id } = this.props.integration;

    return this.props.patch({ id, config });
  };
  deleteIntegration = () => {
    const { id } = this.props.integration;

    this.props
      .deleteIntegrationRequest(id)
      .promise.then((response) => {
        if (!response.ok) {
          console.error(response);
        }
      })
      .catch((err) => console.error(err))
      .finally(() => {
        this.props.close();
      });
  };

  render() {
    const { config, isOpen, close } = this.props;
    const isEmpty = !config['api_key'];

    return (
      <SideDrawer isOpen={isOpen} title="Zapier settings" close={close}>
        <form onSubmit={(e) => this.handleSubmit(e, this.state.config)}>
          <fieldset>
            <small>
              Connect Zapier with Elastic Path Studio to instantly send customer information and Elastic Path Studio
              events to your other third-party tools.{' '}
              <a
                href="https://elasticpath.dev/docs/cx-studio/users/Integrations/Integrating-Zapier"
                target="_blank"
                rel="noopener noreferrer"
              >
                Read more
              </a>{' '}
              about Elastic Path Studio's Zapier integration.
            </small>
          </fieldset>
          {config.api_key && (
            <fieldset>
              <label>API Key</label>
              <p className={styles.key} ref={(apiKey) => (this.apiKey = apiKey)}>
                {config.api_key}
                {this.state.apiKeyCopied ? (
                  <SvgCheckboxChecked height={16} width={16} data-tip="copied" />
                ) : (
                  <SvgCopy
                    onClick={() => {
                      window.getSelection().removeAllRanges();
                      var range = document.createRange();
                      range.selectNode(this.apiKey);
                      window.getSelection().addRange(range);
                      document.execCommand('copy');
                      window.getSelection().removeAllRanges();
                      this.setState({ apiKeyCopied: true });
                      setTimeout(() => this.setState({ apiKeyCopied: false }), 3500);
                    }}
                    height={14}
                    width={16}
                  />
                )}
              </p>
            </fieldset>
          )}
          <fieldset>
            <label>Site ID</label>
            <p className={styles.key} ref={(siteId) => (this.siteId = siteId)}>
              {this.props.siteId}
              {this.state.siteIdCopied ? (
                <SvgCheckboxChecked height={16} width={16} data-tip="copied" />
              ) : (
                <SvgCopy
                  onClick={() => {
                    window.getSelection().removeAllRanges();
                    var range = document.createRange();
                    range.selectNode(this.siteId);
                    window.getSelection().addRange(range);
                    document.execCommand('copy');
                    window.getSelection().removeAllRanges();
                    this.setState({ siteIdCopied: true });
                    setTimeout(() => this.setState({ siteIdCopied: false }), 3500);
                  }}
                  height={14}
                  width={16}
                />
              )}
            </p>
          </fieldset>
          <fieldset>
            <input
              type="submit"
              value={this.state.isSubmitting ? 'Generating API key' : isEmpty ? 'Generate API key' : 'Refresh API key'}
              disabled={this.state.isSubmitting}
              className="button button-primary button-full"
            />
            {!isEmpty && (
              <a
                className={`button button-full ${styles.remove}`}
                onClick={(e) => {
                  e.preventDefault();
                  const remove = window.confirm(`Are you sure you want to disconnect from Zapier?`);
                  if (remove) {
                    this.deleteIntegration();
                  }
                }}
                role="button"
              >
                Remove
              </a>
            )}
          </fieldset>
        </form>
      </SideDrawer>
    );
  }
}
export default compose(withSiteId, connect(mapStateToProps, mapDispatchToProps))(ZapierDrawer);
