import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import DocumentTitle from 'react-document-title';

import * as api from '../../services/spark-api';
import * as appActions from '../../actions/appActions';
import * as accountActions from '../../actions/accountActions';

import idx from 'lodash/get';

import styles from './Login.module.scss';

import Spinner from '../base/Spinner';
import FormControl from '../base/FormHelpers/FormControl';
import NonFieldErrors from '../base/FormHelpers/NonFieldErrors';
import LogoBanner from '../base/LogoSimpleBanner';
import { loginSuccess } from '../../actions/accountActions';
import { initializeApp } from '../../actions/appActions';

const mapDispatchToProps = {
  initializeApp: appActions.initializeApp,
  loginSuccess: accountActions.loginSuccess,
};

const ForgotPassword = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [state, setState] = useState({
    password: '',
    hasValidToken: null,
    isFetching: false,
    errors: {} as any,
  });

  useEffect(() => {
    checkToken();
  }, []);

  const checkToken = () => {
    api.checkPasswordResetToken(token).then(({ status }) => {
      setState((prevState) => ({
        ...prevState,
        hasValidToken: status === 200,
      }));
    });
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setState((prevState) => ({
      ...prevState,
      isFetching: true,
    }));

    api.resetPassword(token, state.password).then((response) => {
      if (response.status === 200) {
        const jwtToken = response.json.token;
        dispatch(loginSuccess(jwtToken));
        dispatch(initializeApp());
        navigate('/');
      } else {
        const errors = response.json.errors;
        setState((prevState) => ({
          ...prevState,
          isFetching: false,
          errors,
        }));
      }
    });
  };

  const { password, hasValidToken, isFetching, errors } = state;
  const password_errors = idx(errors, 'password');

  return (
    <div className="admin">
      <DocumentTitle title="Elastic Path Studio | Forgot password" />
      <main className={styles.Login}>
        <LogoBanner />
        {hasValidToken === null && <Spinner />}
        {hasValidToken && (
          <>
            {/* @ts-ignore*/}
            <form onSubmit={handleSubmit} disabled={isFetching}>
              <fieldset>
                <FormControl
                  type="password"
                  name="password"
                  value={password}
                  placeholder="New password"
                  autoComplete="new-password"
                  onChange={handleChange}
                  disabled={isFetching}
                  errors={password_errors}
                  autoFocus
                  className="ph-redact-input"
                />
              </fieldset>
              {/* <NonFieldErrors errors={errors} /> */}
              <input
                type="submit"
                className="button button-primary button-full"
                value={isFetching ? 'Loading...' : 'Reset password'}
              />
              <small className={styles.terms}>Enter a new password for your account.</small>
            </form>
            <footer>
              Remember your password? <Link to="/login">Login</Link>.
            </footer>
          </>
        )}
        {hasValidToken === false && (
          <p className={styles.centerText}>
            Sorry, that token is no longer valid.
            <br />
            <Link to="/password">Request a new one.</Link>
          </p>
        )}
      </main>
    </div>
  );
};

export default connect(null, mapDispatchToProps)(ForgotPassword);
