import React from 'react';
import useUrlSite from '../../../hooks/useUrlSite';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Spinner from '../../base/Spinner';
import ZeroState, { Image, Header, Muted, Group } from '../ZeroState';
import { selectHasPermission } from '../../../reducers/policyReducer';
import icon from '../../../assets/images/icon-contact-zero-state.svg';

export default function ContactsListZeroState() {
  const urlSite = useUrlSite();
  const hasPermission = useSelector((state) => selectHasPermission(state));
  if (!hasPermission('Contact:List')) {
    return hasPermission('Account') ? (
      <p>You don't have permission to access customers.</p>
    ) : (
      <Spinner className="fixed" />
    );
  }
  return (
    <ZeroState>
      <Image src={icon} />
      <Header>Publish a form or import customers</Header>
      <Muted>
        You'll see customer information here once a visitor fills out an Elastic Path Studio form or you import HubSpot
        contacts via Integrations.
      </Muted>
      {hasPermission('SiteIntegration:') && (
        <Group>
          <Link to={`/${urlSite}/integrations`} className="button button-primary">
            Manage integrations
          </Link>
        </Group>
      )}
    </ZeroState>
  );
}
