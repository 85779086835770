import React from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { arrayMove } from '../../../../../lib/SortListHoc';
import { EditField, checkValidity } from './EditField';
import { FieldBuilder } from './FieldBuilder';
import SideDrawer from '../../../../base/SideDrawer';
import { FormStyling } from '../../FormStyling';
import ValidationMessage from '../../../../base/FormHelpers/ValidationMessage';
import SmartFormInput from '../../../../base/FormHelpers/SmartFormInput';
import withIntegrations from '../../../../../containers/withIntegrations';

import styles from './Form.module.scss';
import { someFields } from 'components/AdminPanel/Forms/utils';
import { selectFieldsFromForm } from 'reducers/sparkFormsReducer';

class SparkForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formUpdated: false,
      showSecondaryDrawer: false,
    };
  }

  componentDidMount() {
    this.props.formId ? this.props.populateForm() : this.props.clearForm();
  }

  componentDidUpdate(prevProps) {
    const { selectedFields } = this.props;
    if (
      selectedFields &&
      prevProps.selectedFields.length !== selectedFields.length &&
      selectedFields.slice(-1)[0].isNew
    ) {
      this.setState({
        showSecondaryDrawer: true,
        showFieldOptions: true,
        fieldId: selectedFields.slice(-1)[0].id,
      });
    }
  }

  componentWillUnmount() {
    this.props.clearForm();
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.props.reorderField(arrayMove(this.props.selectedFields, oldIndex, newIndex));
  };

  render() {
    const {
      addField,
      addGenericField,
      formId,
      isSubmitting,
      lists,
      mailchimpLists,
      activecampaignLists,
      klaviyoLists,
      integrationsBySlug,
      name,
      onSave,
      onDelete,
      removeField,
      saveForm,
      selectedFields,
      selectedList,
      responders,
      siteId,
      submissionAction,
      submitButtonValue,
      updateField,
      updateForm,
      updateFormName,
      updateSubmissionAction,
      updateSubmitButtonValue,
      updateSelectedList,
      updateSubmittingState,
      updateResponder,
      undoFieldChanges,
      background,
      updateBackground,
      subscription,
      onRemove,
      hasZap,
    } = this.props;

    const { fieldError, fieldId, securityError, showErrors, showFieldOptions, showSecondaryDrawer } = this.state;

    const hasEmail = someFields(selectedFields, (field) => field.type === 'email');

    return showSecondaryDrawer ? (
      <SideDrawer
        close={() => {
          if (this.state.showFieldOptions) {
            const field = selectedFields.find((field) => field.id === fieldId);
            const fieldError = !Boolean(
              field.name && !selectedFields.some((f) => f.name === field.name && f.id !== fieldId)
            );
            const securityError = checkValidity(field);
            if (!fieldError && !securityError)
              this.setState({
                showSecondaryDrawer: false,
                showFieldOptions: false,
                fieldError: false,
                securityError: false,
              });
            else
              this.setState({
                fieldError,
                securityError,
              });
          } else {
            this.setState({
              showSecondaryDrawer: false,
              showFieldOptions: false,
            });
          }
        }}
        closeElement={
          <a href="/" onClick={(e) => e.preventDefault()}>
            Done
          </a>
        }
        avoidBackdrop
        isOpen={showSecondaryDrawer}
        persistOnBackdropClick
        title={showFieldOptions ? 'Field options' : 'Add form fields'}
      >
        {showFieldOptions ? (
          <EditField
            field={selectedFields.find((field) => field.id === fieldId)}
            update={updateField}
            fieldError={fieldError}
            securityError={securityError}
            undoFieldChanges={(field) => {
              this.setState({
                showFieldOptions: false,
                showSecondaryDrawer: false,
                fieldId: undefined,
                securityError: false,
              });
              undoFieldChanges(field);
            }}
          />
        ) : (
          <FieldBuilder
            subscription={subscription}
            addField={addField}
            addGenericField={addGenericField}
            closeBuilder={() =>
              this.setState({
                showSecondaryDrawer: false,
                showFieldOptions: false,
              })
            }
            selectedFields={selectedFields}
          />
        )}
      </SideDrawer>
    ) : (
      <>
        <SmartFormInput
          onSuggestionSelect={(name) => {
            this.props.populateUsingFormName(name);
          }}
          onNameTagRemove={() => {
            this.props.clearForm();
          }}
          onChange={updateFormName}
          value={name}
          formId={formId}
          isSparkForm
          validationMessage={showErrors && !name && <ValidationMessage message="You must select a form." />}
        />
        {/* <SortableContainer
          helperClass="dragging-helper-class"
          onSortEnd={this.onSortEnd}
          useDragHandle
        >
          <label>Fields</label>
          {this.props.selectedFields.map((field, index) => (
            <SortableField
              key={`item-${field.id}`}
              index={index}
              field={field}
              fieldsCount={this.props.selectedFields.length}
              onEditClick={() =>
                this.setState({
                  showSecondaryDrawer: true,
                  showFieldOptions: true,
                  fieldId: field.id,
                })
              }
              onRemoveField={removeField}
            />
          ))}
        </SortableContainer>
        <div className={styles.add}>
          <label
            onClick={() =>
              this.setState(state => ({
                showSecondaryDrawer: !state.showSecondaryDrawer,
              }))
            }
          >
            <SvgIconAdd role="button" />
            Add Field
          </label>
        </div>
        <SubmitButtonValue
          onChange={updateSubmitButtonValue}
          value={submitButtonValue}
          label="Submit button text"
          validationMessage={
            showErrors &&
            !submitButtonValue && (
              <ValidationMessage message="This field is required" />
            )
          }
        /> 
        <SubmissionOptions
          onUpdate={updateSubmissionAction}
          submissionAction={submissionAction}
          validationMessage={
            showErrors &&
            !(submissionAction.message || submissionAction.url) && (
              <ValidationMessage message="Select an option" />
            )
          }
        />
      */}
        <br />
        <FormStyling onUpdate={updateBackground} background={background} />

        {/*  
        {!!hasPhone && !!integrationsByType['twilio'] && (
          <fieldset>
            <label>SMS Message</label>
            <FormControl
              tag="textarea"
              name="sms_responder"
              value={responders ? responders.sms : ''}
              onChange={e => updateResponder('sms', e.target.value)}
              autoComplete="off"
              cols={65}
              rows={3}
            />
            <small>
              This message will be sent to the contact's phone number entered in
              the form after submitting.
            </small>
          </fieldset>
        )}

        {Boolean(lists.length) && !!hasEmail && (
          <fieldset>
            <label>Add to HubSpot list</label>
            <FormControl
              tag="select"
              name="hubspot"
              onChange={e => updateSelectedList(e.target.name, e.target.value)}
              value={selectedList.hubspot || ''}
              autoComplete="off"
            >
              <option value="">Select</option>
              {lists.map(list => (
                <option key={list.listId} value={list.listId}>
                  {list.name}
                </option>
              ))}
            </FormControl>
            <small>
              Add anyone who submits this form to a static list in your HubSpot
              account.
            </small>
          </fieldset>
        )}

        {Boolean(mailchimpLists.length) && !!hasEmail && (
          <fieldset>
            <label>Add to MailChimp list</label>
            <FormControl
              tag="select"
              name="mailchimp"
              onChange={e => updateSelectedList(e.target.name, e.target.value)}
              value={selectedList.mailchimp || ''}
              autoComplete="off"
            >
              <option value="">Select</option>
              {mailchimpLists.map(list => (
                <option key={list.id} value={list.id}>
                  {list.name}
                </option>
              ))}
            </FormControl>
            <small>
              Add anyone who submits this form to a list in your MailChimp
              account.
            </small>
          </fieldset>
        )}

        {Boolean(klaviyoLists.length) && !!hasEmail && (
          <fieldset>
            <label>Add to Klaviyo list</label>
            <FormControl
              tag="select"
              name="klaviyo"
              onChange={e => updateSelectedList(e.target.name, e.target.value)}
              value={selectedList.klaviyo || ''}
              autoComplete="off"
            >
              <option value="">Select</option>
              {klaviyoLists.map(list => (
                <option key={list.list_id} value={list.list_id}>
                  {list.list_name}
                </option>
              ))}
            </FormControl>
            <small>
              Add anyone who submits this form to a list in your Klaviyo
              account.
            </small>
          </fieldset>
        )}

        {Boolean(activecampaignLists.length) && !!hasEmail && (
          <fieldset>
            <label>Add to Active Campaign list</label>
            <FormControl
              tag="select"
              name="activecampaign"
              onChange={e => updateSelectedList(e.target.name, e.target.value)}
              value={selectedList.activecampaign || ''}
              autoComplete="off"
            >
              <option value="">Select</option>
              {activecampaignLists.map(list => (
                <option key={list.id} value={list.id}>
                  {list.name}
                </option>
              ))}
            </FormControl>
            <small>
              Add anyone who submits this form to a list in your Active Campaign
              account
            </small>
          </fieldset>
        )}
        */}

        <fieldset>
          <input
            onClick={(e) => {
              if (
                !hasEmail &&
                !window.confirm(
                  'Without an email, Elastic Path Studio will try to send form data to any configured integrations, but submissions will not be saved as customers'
                )
              )
                return;

              if (
                !this.state.isSaving &&
                ((hasZap &&
                  window.confirm('Updating this form may cause your zap to break?.Do you still want to continue?')) ||
                  !hasZap)
              ) {
                this.setState({
                  showErrors: false,
                  isSaving: true,
                });
                onSave(e, {
                  type: 'unstack_form',
                  id: formId,
                  background,
                });
                this.setState({ isSaving: false });
                updateSubmittingState(false);
              } else {
                this.setState({
                  showErrors: true,
                });
              }
            }}
            onChange={() => {}}
            value={isSubmitting ? 'Saving...' : 'Save'}
            className="button button-primary button-full"
            readOnly="readonly"
          />
          <button
            onClick={() => {
              this.props.onDiscard();
            }}
            className="button button-secondary button-full"
          >
            Discard Changes
          </button>
          {this.state.error && <small className="errorMessage">{this.state.error}</small>}
        </fieldset>
        {formId && (
          <div className={styles.deleteActionLink} ref={this.ref}>
            {onRemove && (
              <p>
                <a href="/" key="delete" onClick={onRemove}>
                  Remove this form
                </a>{' '}
                from this content.
              </p>
            )}
          </div>
        )}
      </>
    );
  }
}

export default compose(withIntegrations)(SparkForm);
