import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useParams } from 'react-router-dom';

import ExpansionPanel from 'components/AdminPanel/Quickstart/ExpansionPanel';
import styles from 'components/AdminPanel/Quickstart/InviteCollaboratorsPanel.module.scss';
import { getIsCollaboratorInvited } from 'components/AdminPanel/Quickstart/helpers';
import * as uiUserSettingsActions from 'actions/uiUserSettingsActions';
import CheckPermissionOnClick from 'components/SubscriptionManager/CheckPermissionOnClick';
import { ReactComponent as CollaborateIcon } from 'assets/images/collaborate-icon.svg';
import { patchOnboarding, removeOnboardingStep } from 'actions/accountActions';
import {
  INVITE_COLLABORATORS_PENDING,
  INVITE_COLLABORATORS_COMPLETE,
} from 'components/AdminPanel/Quickstart/constants';
import { useQuickstartStep } from 'components/AdminPanel/Quickstart/hooks/useQuickstartStep';
import { selectAccount } from 'reducers/accountReducer';

const InviteCollaboratorContent = () => {
  const { urlSite } = useParams();
  const dispatch = useDispatch();
  const handleInviteTeamMemberOnClick = useCallback(
    // @ts-ignore
    () => dispatch(uiUserSettingsActions.open({ accountUserId: null })),
    []
  );

  return (
    <div className={styles.inviteCollaboratorContent}>
      <div>
        <p>
          We've designed Elastic Path Studio to be a collaborative experience for your team. Invite a few people to give
          you a hand!
        </p>
        <div>
          <CheckPermissionOnClick
            action="AccountUser:create"
            onClick={handleInviteTeamMemberOnClick}
            render={(handleClick: () => void) => (
              <button onClick={handleClick} className="button button-primary">
                Invite team member
              </button>
            )}
          />
          <NavLink to={`/${urlSite}/settings`} className={styles.quickstartLink}>
            Learn more
          </NavLink>
        </div>
        <p className={styles.footnote}>
          You can always add more people in <NavLink to={`/${urlSite}/settings`}>Settings</NavLink>.
        </p>
      </div>
      <div>
        <CollaborateIcon />
      </div>
    </div>
  );
};

export default function InviteCollaboratorsPanel() {
  const account = useSelector(selectAccount) || {};
  const isStepComplete = getIsCollaboratorInvited();
  const accountMetadata = account?.metadata?.onboarding || [];
  const { open, handleExpansionPanelClick, handleIconClick } = useQuickstartStep({
    isStepComplete,
    metadata: accountMetadata,
    id: account.id,
    pendingDescription: INVITE_COLLABORATORS_PENDING,
    completedDescription: INVITE_COLLABORATORS_COMPLETE,
    patchOnboarding,
    removeOnboardingStep,
  });

  return (
    <ExpansionPanel
      title="Invite collaborators"
      isComplete={isStepComplete}
      open={open}
      onClick={handleExpansionPanelClick}
      onIconClick={handleIconClick}
    >
      <InviteCollaboratorContent />
    </ExpansionPanel>
  );
}
