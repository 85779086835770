import React, { ReactNode } from 'react';

import { compose } from 'redux';
import withSiteProps from '../../containers/withSiteProps';
import { connect } from 'react-redux';
import * as uiBlogSettingsActions from '../../actions/uiBlogSettingsActions';
import { selectProps } from '../../reducers/uiBlogSettingsReducer';
import { selectHasPermission } from '../../reducers/policyReducer';

import SideDrawer from '../base/SideDrawer';
import FormControl from '../base/FormHelpers/FormControl';
import SlugFormControl from '../base/FormHelpers/SlugFormControl';
import NonFieldErrors from '../base/FormHelpers/NonFieldErrors';
import PrefixedInput from '../base/FormHelpers/PrefixedInput';

import { set } from '../../lib/immutable-operations/operations';
import { Checkbox } from '../Checkbox';

import styles from '../DangerZone.module.scss';
import LinkField from 'components/Pages/LinkField';

function mapStateToProps(state: any) {
  return {
    hasPermission: selectHasPermission(state),
    ...selectProps(state),
  };
}

const mapDispatchToProps = uiBlogSettingsActions;

export const ARTICLE_URL_STRUCTURE_FORMATS = {
  'blog/category/article': '/{blog}/{category}/{article}',
  'blog/article': '/{blog}/{article}',
  'category/article': '/{category}/{article}',
  article: '/{article}',
};

class BlogSettings extends React.PureComponent<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      oldSlug: null,
      oldStructure: null,
    };
  }

  // TODO: Daniel upgrade shouldn't use location
  // componentDidUpdate(prevProps: any) {
  //   if (prevProps.location !== this.props.location && prevProps.location) {
  //     this.props.close();
  //   }
  // }

  handleChange = (nameOrEvent: any, value: any) => {
    if (this.props.isFetching) return false;

    // Accept (name, value) argument pair or event
    let name;
    if (nameOrEvent.target) {
      name = nameOrEvent.target.name;
      value = nameOrEvent.target.value;
    } else {
      name = nameOrEvent;
    }

    if (name === 'is_indexable') {
      value = !nameOrEvent.target.checked;
    }
    if (name.includes('tag')) {
      value = nameOrEvent.target.checked;
    }

    this.props.addOps(set(name, value));
  };

  handleSave = (e: any) => {
    e.preventDefault();
    const { blog } = this.props;
    const { oldSlug, oldStructure } = this.state;
    if (
      (oldSlug !== null && oldSlug !== blog.slug) ||
      (oldStructure !== null && oldStructure !== blog.config.urls.article)
    ) {
      if (
        window.confirm(
          "Are you sure you want to do this? This will change your URL structure and 301 redirect content. This could hurt SEO if you don't intend to do this"
        )
      ) {
        this.setState({ oldSlug: blog.slug });
        this.props.save();
      }
    } else this.props.save();
  };

  // handlePublish = (e) => {
  //   e.preventDefault();
  //   this.props.publish();
  // }

  render() {
    const { isOpen, close, blog, errors, fullDomain, hasArticles, hasPermission } = this.props;

    const isHomePage = blog && blog.slug === '' && blog.status === 'published';

    return (
      <SideDrawer
        isOpen={isOpen}
        close={close}
        title="Blog settings"
        render={() =>
          blog && (
            <>
              <form onSubmit={this.handleSave}>
                <fieldset>
                  <label htmlFor="elastic_path_studio_name_input">Elastic Path Studio name</label>
                  <FormControl
                    type="text"
                    id="elastic_path_studio_name_input"
                    name="name"
                    value={blog.name || ''}
                    errors={errors.name}
                    onChange={this.handleChange}
                    autoComplete="off"
                    maxLength={65}
                    autoFocus
                  />
                  <small>This will be used in your Elastic Path Studio portal only.</small>
                </fieldset>
                <fieldset>
                  <label htmlFor="title_input">Title</label>
                  <FormControl
                    type="text"
                    id="title_input"
                    name="meta.title"
                    value={blog.meta.title || ''}
                    onChange={this.handleChange}
                    imitate={blog.name}
                    errors={errors.title}
                    autoComplete="off"
                    maxLength={65}
                  />
                  <small>This will be used in the browser tab and should be 65 characters or less.</small>
                </fieldset>
                <fieldset>
                  <label htmlFor="category_url_structure_input">Category URL structure</label>
                  <FormControl type="text" id="category_url_structure_input" value={'/{blog}/{category}/'} disabled />
                  <small>This will be the URL structure for your categories.</small>
                </fieldset>
                <fieldset>
                  <label htmlFor="article_url_structure_input">Article URL structure</label>
                  <FormControl
                    tag="select"
                    type="text"
                    id="article_url_structure_input"
                    onFocus={(e: any) => {
                      this.state.oldStructure === null && this.setState({ oldStructure: e.target.value });
                    }}
                    value={blog.config.urls.article}
                    name="config.urls.article"
                    onChange={this.handleChange}
                  >
                    <option value="" />
                    {Object.entries(ARTICLE_URL_STRUCTURE_FORMATS).map((entry) => (
                      <option key={entry[0]} value={entry[0]}>
                        {entry[1]}
                      </option>
                    ))}
                  </FormControl>
                  <small>This will be the URL Article for your categories.</small>
                </fieldset>
                <LinkField
                  id="page_url_input"
                  isHomePage={isHomePage}
                  PrefixedInput={PrefixedInput as unknown as ReactNode}
                  prefix=""
                  page={blog}
                  handleChange={this.handleChange}
                  errors={errors}
                />
                <fieldset>
                  <label htmlFor="description_input">Description</label>
                  <FormControl
                    tag="textarea"
                    type="text"
                    id="description_input"
                    name="meta.description"
                    value={blog.meta.description || ''}
                    onChange={this.handleChange}
                    errors={errors.description}
                    autoComplete="off"
                  />
                  <small>Text used in search engine descriptions.</small>
                </fieldset>
                <fieldset>
                  <label className="checkbox">
                    <FormControl
                      type="checkbox"
                      name="config.disableTOC"
                      checked={!blog.config.disableTOC}
                      onChange={(e: any) => this.handleChange('config.disableTOC', !e.target.checked)}
                    />
                    <Checkbox checked={!blog.config.disableTOC} />
                    <span>Table of contents</span>
                  </label>
                  <small>Adds a table of contents to all articles in this blog.</small>
                </fieldset>
                {(hasPermission('Blog:deindex') || hasPermission('Site:set_homepage')) && (
                  <>
                    <h3 className={styles.dangerHeader}>Danger zone</h3>
                    <div className={styles.dangerDiv}>
                      {hasPermission('Blog:deindex') && (
                        <fieldset>
                          <label className="checkbox">
                            <FormControl
                              type="checkbox"
                              name="is_indexable"
                              checked={blog.is_indexable === undefined ? false : !blog.is_indexable}
                              onChange={this.handleChange}
                            />
                            <Checkbox checked={!blog.is_indexable} />
                            <span>Hide this blog from search engines</span>
                          </label>
                          <small>This will block search engines from this blog.</small>
                        </fieldset>
                      )}
                    </div>
                  </>
                )}
                <NonFieldErrors errors={errors} />
                {hasPermission('Blog:update') && (
                  <>
                    {/*{hasPermission('Blog:publish') && (*/}
                    {/*<button*/}
                    {/*type="button"*/}
                    {/*className="button button-primary button-full"*/}
                    {/*onClick={this.handlePublish}>Save and publish</button>*/}
                    {/*)}*/}
                    <input type="submit" className="button button-full" value="Save" />
                  </>
                )}
              </form>
            </>
          )
        }
      />
    );
  }
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(BlogSettings);
