import React, { ChangeEvent, LegacyRef, useState } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';

import Select from './Select';
import MarginField from './MarginField';
import ColorSelector from './StyleGuideEditor/ColorSelector';
import { selectHasPermission } from 'reducers/policyReducer';
import { FontOptions, ColorOptions, Heading } from './types';

import { ReactComponent as SvgIconDesktopThumbnail } from '../../../assets/images/icon-desktop-styleguide.svg';
import { ReactComponent as SvgIconMobileThumbnail } from '../../../assets/images/icon-mobile-styleguide.svg';

import styles from './StyleGuideEditor.module.scss';

export default React.memo(
  ({
    fontFamilyOptions,
    styleGuide,
    fontWeightOptions,
    handleChange,
    handleChangeEvent,
    darkBgColor,
    ...props
  }: Heading) => {
    const hasPermission = useSelector(selectHasPermission);
    const [activeBackground, setActiveBackground] = useState({
      blog_paragraph: 'light',
    });

    return (
      <>
        {hasPermission('Article:create', 'subscription') && (
          <>
            <header ref={props.scrollRef}>
              <h1>Blog Text</h1>
              <small>Set the text styles used across your site.</small>
            </header>

            {/* Blog paragraph */}
            <article className={styles.StyleGuideEditor}>
              <section className={styles.styleSection}>
                <div className={styles.styleSectionHeader}>
                  <p>Blog paragraph</p>
                  <div className={styles.bgToggles}>
                    <div className={styles.background}>
                      <span
                        className={classnames(styles.lightBackground, {
                          [styles.active]: activeBackground.blog_paragraph === 'light',
                        })}
                        onClick={() => setActiveBackground({ ...activeBackground, blog_paragraph: 'light' })}
                      >
                        Light
                      </span>
                      <span
                        className={classnames(styles.darkBackground, {
                          [styles.active]: activeBackground.blog_paragraph === 'dark',
                        })}
                        onClick={() => setActiveBackground({ ...activeBackground, blog_paragraph: 'dark' })}
                      >
                        Dark
                      </span>
                    </div>

                    <div className={styles.device}>
                      <span
                        onClick={() => setActiveBackground({ ...activeBackground, blog_paragraph: 'light' })}
                        className={classnames(styles.lightBackground, {
                          [styles.active]: activeBackground.blog_paragraph === 'light',
                        })}
                      >
                        <SvgIconDesktopThumbnail />
                      </span>
                      <span
                        onClick={() => setActiveBackground({ ...activeBackground, blog_paragraph: 'mobile' })}
                        className={classnames(styles.darkBackground, {
                          [styles.active]: activeBackground.blog_paragraph === 'mobile',
                        })}
                      >
                        <SvgIconMobileThumbnail />
                      </span>
                    </div>
                  </div>
                </div>
                <div>
                  <div
                    className={`sriracha ${
                      activeBackground.blog_paragraph === 'dark'
                        ? 'dark-bg p'
                        : activeBackground.blog_paragraph === 'mobile'
                        ? 'mobile p'
                        : ''
                    }`}
                    style={{
                      padding: activeBackground.blog_paragraph === 'dark' ? '1rem 2rem 0.5rem' : '0',
                      backgroundColor: activeBackground.blog_paragraph === 'dark' ? darkBgColor.code : 'inherit',
                    }}
                  >
                    <div className="blog-content">
                      <p
                        style={{
                          color:
                            activeBackground.blog_paragraph === 'dark'
                              ? styleGuide.blog_paragraph_dark_bg_color.code
                              : styleGuide.blog_paragraph_color.code,
                        }}
                      >
                        This is an example of a paragraph that can go in any blog. Make sure it there's enough room
                        between lines to improve readibility.
                      </p>
                    </div>
                  </div>
                </div>
                <div className={styles.styleSectionOptions}>
                  {activeBackground.blog_paragraph === 'light' ? (
                    <>
                      <div className={styles.styleOption}>
                        <span>Font family</span>
                        <Select
                          options={fontFamilyOptions}
                          defaultValue={fontFamilyOptions[styleGuide.blog_paragraph_font_family_index]}
                          value={fontFamilyOptions[styleGuide.blog_paragraph_font_family_index]}
                          onChange={(option: FontOptions) =>
                            handleChange(['blog_paragraph', 'font_family_index'], option.value)
                          }
                        />
                      </div>
                      <div className={styles.styleOption}>
                        <span>Font Weight</span>
                        <Select
                          options={fontWeightOptions}
                          defaultValue={fontWeightOptions.find(
                            (weight) => weight.value === Number(styleGuide.blog_paragraph_font_weight)
                          )}
                          value={fontWeightOptions.find(
                            (weight) => weight.value === Number(styleGuide.blog_paragraph_font_weight)
                          )}
                          onChange={(option: FontOptions) =>
                            handleChange(['blog_paragraph', 'font_weight'], option.value)
                          }
                        />
                      </div>
                      <div className={styles.styleOption}>
                        <span>Font size</span>
                        <input
                          type="number"
                          name="blog_paragraph_font_size"
                          value={styleGuide.blog_paragraph_font_size}
                          min="0.8"
                          max="2.5"
                          step="0.1"
                          onChange={(e) => handleChangeEvent(e, ['blog_paragraph', 'font_size'])}
                        />
                      </div>
                      <div className={styles.styleOption}>
                        <span>Line height</span>
                        <input
                          type="number"
                          name="blog_paragraph_line_height"
                          value={styleGuide.blog_paragraph_line_height}
                          min="0.8"
                          max="2"
                          step="0.1"
                          onChange={(e) => handleChangeEvent(e, ['blog_paragraph', 'line_height'])}
                        />
                      </div>
                      <MarginField
                        name="blog_paragraph_margin"
                        value={styleGuide.blog_paragraph_margin}
                        handleChange={(value) => handleChange(['blog_paragraph', 'margin'], value)}
                      />
                      <div className={styles.styleOption}>
                        <span>Color</span>
                        <ColorSelector
                          name="blog_paragraph_color"
                          onChange={(value: ColorOptions) => handleChange(['blog_paragraph', 'color'], value)}
                          value={styleGuide.blog_paragraph_color}
                          placeholder="Color"
                          handleColorSelection={(value: ColorOptions) =>
                            handleChange(['blog_paragraph', 'color'], value)
                          }
                        />
                      </div>
                    </>
                  ) : activeBackground.blog_paragraph === 'dark' ? (
                    <div className={styles.styleOption}>
                      <span>Color</span>
                      <ColorSelector
                        name="blog_paragraph_dark_bg_color"
                        onChange={(value: ColorOptions) => handleChange(['blog_paragraph', 'dark_bg_color'], value)}
                        value={styleGuide.blog_paragraph_dark_bg_color}
                        placeholder="Color"
                        handleColorSelection={(value: ColorOptions) =>
                          handleChange(['blog_paragraph', 'dark_bg_color'], value)
                        }
                      />
                    </div>
                  ) : (
                    <>
                      <div className={styles.styleOption}>
                        <span>Font size</span>
                        <input
                          type="number"
                          name="blog_paragraph_font_size_mobile"
                          value={styleGuide.blog_paragraph_font_size_mobile}
                          min="0.8"
                          max="2.5"
                          step="0.1"
                          onChange={(e) => handleChangeEvent(e, ['blog_paragraph', 'mobile', 'font_size'])}
                        />
                      </div>
                      <div className={styles.styleOption}>
                        <span>Line height</span>
                        <input
                          type="number"
                          name="blog_paragraph_line_height_mobile"
                          value={styleGuide.blog_paragraph_line_height_mobile || styleGuide.blog_paragraph_line_height}
                          min="0.8"
                          max="2"
                          step="0.1"
                          onChange={(e) => handleChangeEvent(e, ['blog_paragraph', 'mobile', 'line_height'])}
                        />
                      </div>
                      <MarginField
                        name="blog_paragraph_margin"
                        value={styleGuide.blog_paragraph_margin_mobile || styleGuide.blog_paragraph_margin}
                        handleChange={(value) => handleChange(['blog_paragraph', 'mobile', 'margin'], value)}
                      />
                    </>
                  )}
                </div>
              </section>
            </article>
          </>
        )}
      </>
    );
  }
);
