import React, { useState, useEffect, Dispatch } from 'react';
import ReactDOM from 'react-dom';
import classnames from 'classnames';
import { connect, useDispatch, useSelector } from 'react-redux';

import RequireLogin from './RequireLogin';
import { CSSTransition } from 'react-transition-group';

import * as api from '../services/spark-api';
import * as siteSelectors from '../reducers/sitesReducer';
import * as pageSelectors from '../reducers/pagesReducer';
import * as pageActions from '../actions/pageActions';
import * as mediaSelectors from '../reducers/mediaReducer';
import * as mediaActions from '../actions/mediaActions';
import * as integrationActions from '../actions/integrationActions';
import * as contentTestSelectors from '../reducers/contentTestsReducer';
import * as contentTestActions from '../actions/contentTestActions';
import * as paymentAction from '../actions/paymentActions';
import * as templateActions from '../actions/templateActions';
import * as uiPageSettingsActions from '../actions/uiPageSettingsActions';

import AdminBar from './Editor/EditorAdminBar';
import PageSettings from './Pages/PageSettings';
import TemplateSettings from './Pages/CreateTemplate';
import DynamicPageCanvas from './Editor/DynamicPageCanvas';
import withDataTables from 'containers/withDataTables';

import idx from 'lodash/get';

import styles from './Editor.module.scss';
import { compose } from 'redux';
import FormControl from './base/FormHelpers/FormControl';
import { selectDynamicContentErrors } from 'reducers/uiPageSettingsReducer';
import { useNavigate, useParams } from 'react-router-dom';
import { OnMount } from 'hooks/mountUnmountHooks';

type ErrorBarProps = {
  errors: any[];
};
export function ErrorBar(props: ErrorBarProps) {
  const [showErrors, setShowErrors] = useState(false);

  useEffect(() => {
    if (!showErrors && props?.errors?.length) {
      setShowErrors(true);
      setTimeout(() => setShowErrors(false), 2000);
    }
  }, [props.errors]);
  const errorClasses = classnames(styles.Errors, {
    [styles.show]: showErrors,
  });

  if (!props.errors) return null;

  return ReactDOM.createPortal(
    <>
      <div className={errorClasses}>
        <div>{props.errors[0] && <div>{props.errors[0]}</div>}</div>
      </div>
    </>,
    document.body
  );
}

type TableSelectorProps = {
  createPage: () => void;
  onChange: (e: any) => void;
  data_table: any;
  tables: any[];
};

const TableSelector = (props: TableSelectorProps) => {
  return (
    <section className={styles.TableSelector} data-test-id="table-selector">
      <article className="admin">
        <h3>Connect a data table to begin</h3>
        <p>
          Create one global layout and generate many pages based on your Elastic Path Studio DataTables. See a demo here
        </p>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            props.createPage();
          }}
        >
          <fieldset>
            <FormControl
              name="data_table"
              aria-label="Table selector"
              onChange={props.onChange}
              value={props.data_table || ''}
              tag="select"
            >
              <option value=""></option>
              {props.tables.map((table) => (
                <option value={table.id}>{table.slug}</option>
              ))}
            </FormControl>
          </fieldset>
          <input
            className="button button-primary button-full"
            data-test-id="choose"
            value="Choose this DataTable"
            type="submit"
          />
        </form>
      </article>
    </section>
  );
};

/**
 *  TODO:
 *  This component should only be responsible for handling the creation
 *  of data_page type item and after creating a new page should redirect
 *  to the base Editor component.
 */

type Props = {
  isNew: boolean;
  pageType: string;
  tables: any;
  isFetching: boolean;
};

function Editor(props: Props) {
  const { urlSite, pageId } = useParams();
  // @ts-ignore
  const site = useSelector((state) => siteSelectors.selectSiteByDomain(state, urlSite));
  const page = useSelector((state) => pageSelectors.selectPage(state, pageId));
  const siteId = site.id;
  const pageType = props.pageType || idx(page, 'item_type');
  const errors = useSelector(selectDynamicContentErrors);
  const [dataTable, setDataTable] = useState('');
  const dispatch: Dispatch<any> = useDispatch();
  const navigate = useNavigate();

  OnMount(() => {
    dispatch(mediaActions.requestPDFs());
    dispatch(paymentAction.requestProductsIfNeeded());
    dispatch(templateActions.requestAll());
    dispatch(integrationActions.requestAllIfNeeded());
    dispatch({
      type: 'READ_RESOURCES_REQUEST_IF_NEEDED',
      resourceType: 'sectionTypes',
      requestKey: 'fetch',
    });
  });

  useEffect(() => {
    if (pageId) dispatch(pageActions.requestPage(pageId));
  }, [pageId]);

  const createPage = () => {
    const page: { [key: string]: any } = {
      site: siteId,
      data_table: dataTable,
      item_type: pageType,
      slug: null,
    };

    api.createPage(page).then((response) => {
      if (response.ok) {
        const page = response.json;
        const entities = { pages: { [page.id]: page } };
        dispatch(pageActions.receivePage(entities));
        navigate(`/${urlSite}/editor/${page.id}`);
      }
    });
  };

  return (
    <RequireLogin>
      <div className="Editor">
        <div className="admin">
          <AdminBar pageType="data_page" pageId={pageId} />
        </div>
        {props.isNew ? (
          <TableSelector
            tables={props.tables}
            data_table={dataTable}
            onChange={(e) => setDataTable(e.target.value)}
            createPage={createPage}
          />
        ) : (
          <CSSTransition
            in={!props.isFetching}
            timeout={500}
            classNames={styles.canvas}
            mountOnEnter
            unmountOnExit
            appear
          >
            {() => (
              <>
                {pageType === 'landing_page' && <DynamicPageCanvas pageId={pageId} />}
                <PageSettings />
                <TemplateSettings />
              </>
            )}
          </CSSTransition>
        )}
      </div>
      <ErrorBar errors={errors} />
    </RequireLogin>
  );
}

export default compose(withDataTables)(Editor);
