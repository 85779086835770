import React from 'react';
import { useParams } from 'react-router-dom';

import styles from 'components/AdminPanel/Quickstart/HelpLinks.module.scss';
import { ReactComponent as DocumentationIcon } from 'assets/images/documentation-icon.svg';
import { ReactComponent as SlackIcon } from 'assets/images/slack-icon.svg';
import { ReactComponent as IntegrationsIcon } from 'assets/images/integrations-icon.svg';
import * as integrationSelection from 'reducers/integrationsReducer';
import { useSelector } from 'react-redux';
import useActiveSite from 'hooks/useActiveSite';

export default function HelpLinks() {
  const { urlSite } = useParams();
  const activeSite = useActiveSite();
  const isShopifyConnected: boolean = Boolean(
    useSelector((state) =>
      integrationSelection.selectIntegrationBySlug(
        state,
        //  @ts-ignore
        activeSite.id,
        'shopify'
      )
    )
  );
  const HELP_DOCS = [
    {
      label: 'Advanced page editing',
      link: ' https://www.unstack.com/goto?advanced-page-editing',
      icon: DocumentationIcon,
    },
    {
      label: 'Creating your first A/B test',
      link: 'https://www.unstack.com/goto?creating-an-ab-test',
      icon: DocumentationIcon,
    },
    {
      label: 'Personalized content',
      link: 'https://www.unstack.com/goto?personalized-content',
      icon: DocumentationIcon,
    },
    isShopifyConnected === true && {
      label: 'Shopify + Elastic Path Studio FAQ',
      link: 'https://www.unstack.com/goto?shopify-faq',
      icon: DocumentationIcon,
    },
  ].filter(Boolean);
  const LINKS = [
    {
      label: 'Documentation',
      link: 'https://www.unstack.com/goto?documentation',
      icon: DocumentationIcon,
      openInNewTab: true,
    },
    {
      label: 'Slack Community',
      link: 'https://www.unstack.com/goto?slack-community',
      icon: SlackIcon,
      openInNewTab: true,
    },
    {
      label: 'Integrations',
      link: `/${urlSite}/integrations`,
      icon: IntegrationsIcon,
      openInNewTab: false,
    },
  ];
  return (
    <div className={styles.helpLinksContainer}>
      <h4 className={styles.helpLinksHeader}>Diving deeper</h4>
      <ul>
        {HELP_DOCS.map(({ label, link, icon: Icon }) => (
          <li key={label}>
            <a target="_blank" href={link}>
              <Icon />
              <span>{label}</span>
            </a>
          </li>
        ))}
      </ul>
      <h4 className={styles.helpLinksHeader}>Learn more</h4>
      <ul>
        {LINKS.map(({ label, link, icon: Icon, openInNewTab }) => (
          <li key={label}>
            <a {...(openInNewTab && { target: '_blank' })} href={link}>
              <Icon />
              <span>{label}</span>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
}
