import React, { useMemo } from 'react';

import AlignButton from './AlignButton';
import BackgroundStylesTray from '../../Toolbars2/components/BackgroundStylesTray';
import MediaBackgroundImageUploader from '../../Toolbars2/components/MediaBackgroundImageUploader';
import AnimateButton from './AnimateButton';
import BrightnessTheme from 'components/unstack-components/Component/elements/block/SectionElement/Toolbar/BrightnessTheme';
import TextAlignButton from 'components/Editor/Toolbars2/components/TextAlignButton';
import VerticalAlignButton from 'components/Editor/Toolbars2/components/VerticalAlignButton';
import AnchorLink from 'components/unstack-components/Component/elements/block/SectionElement/Toolbar/AnchorLink';
import PropertiesButton from './PropertiesButton';
import { VisibilityOptions } from 'components/unstack-components/Component/elements/block/SectionElement/Toolbar/VisibilityOptions';
import PaddingAndWidthButton from './PaddingAndWidthButton';
import { UBoxInfo, USectionDevices } from 'types/USection';
import { set } from 'object-path-immutable';
import { useSelector } from 'react-redux';
import { getDevice } from 'reducers/uiReducer';
import { getDeviceTypeToSaveTo, setInDynamic } from 'components/unstack-components/Component/util/helpers/deviceHelper';
import { ContainerInfo } from 'components/unstack-components/types';
import { getItemKey } from 'components/unstack-components/Component/util';

type BoxButtonProps = {
  dataRef: UBoxInfo;
  onChange: (content: USectionDevices, key?: string, multi?: boolean) => void;
  isSectionToolbar?: boolean;
  enableTextAlignment?: boolean;
  enableVerticalAlignment?: boolean;
  properties?: { [key: string]: any }[];
  content: USectionDevices;
  defaults: UBoxInfo;
  closeTray?: () => {};
  contentKey: string;
  containerInfo: ContainerInfo;
};
export default function BoxButtons(props: BoxButtonProps) {
  const {
    dataRef,
    onChange,
    isSectionToolbar,
    enableTextAlignment,
    enableVerticalAlignment,
    properties,
    content,
    defaults,
    closeTray,
    contentKey,
    containerInfo,
  } = props;
  const device = useSelector(getDevice);
  const key = containerInfo ? getItemKey(containerInfo, contentKey) : contentKey;

  const handleSinglePropChange = (prop: string) => (newValue: any) => {
    const splitKey = key.split('.');
    const path = [getDeviceTypeToSaveTo(device, false, splitKey), ...splitKey.slice(1), prop];
    const newContent = setInDynamic(content, path, newValue);
    onChange(newContent, `content.${splitKey[0]}`);
  };
  const handleSinglePropChangeBase = (prop: string) => (newValue: any) => {
    const splitKey = key.split('.');
    onChange(
      set(content, [getDeviceTypeToSaveTo(device, true), ...splitKey.slice(1), prop], newValue),
      `content.${splitKey[0]}`
    );
  };

  return (
    <>
      {props.properties && props.properties.length > 0 && (
        <PropertiesButton
          properties={properties}
          onChange={onChange}
          dataRef={dataRef}
          content={content}
          contentKey={key}
        />
      )}
      {!isSectionToolbar && (
        <>
          {/* <MediaForegroundImageUploader
            value={valueWithDefaults.foregroundImage}
            onChange={handleSinglePropChange('foregroundImage')}
          /> */}
          <>
            <MediaBackgroundImageUploader
              value={dataRef}
              closeTray={closeTray}
              onChange={onChange}
              contentKey={key}
              content={content}
            />
            <BackgroundStylesTray
              startOpen={!props.properties}
              dataRef={dataRef}
              onChange={handleSinglePropChangeBase}
            />
          </>
          <AnimateButton value={dataRef.animate} onChange={handleSinglePropChangeBase('animate')} />
        </>
      )}
      <PaddingAndWidthButton
        dataRef={dataRef}
        onChange={onChange}
        defaults={defaults}
        isSectionToolbar={isSectionToolbar}
        content={content}
        contentKey={key}
      />
      <AlignButton value={dataRef.align} onChange={handleSinglePropChangeBase('align')} />
      {enableVerticalAlignment && (
        <VerticalAlignButton value={dataRef.verticalAlign} onChange={handleSinglePropChangeBase('verticalAlign')} />
      )}
      {!isSectionToolbar && (
        <BrightnessTheme dataRef={dataRef} content={content} onChange={onChange} contentKey={key} />
      )}
      {enableTextAlignment && (
        <TextAlignButton value={dataRef.textAlign} onChange={handleSinglePropChangeBase('textAlign')} />
      )}
      {!isSectionToolbar && <AnchorLink dataRef={dataRef} content={content} contentKey={key} onChange={onChange} />}
      {!isSectionToolbar && !props.containerInfo && (
        <VisibilityOptions
          dataRef={dataRef}
          content={content}
          onChange={onChange}
          isSectionToolbar={isSectionToolbar}
          contentKey={key}
        />
      )}
    </>
  );
}
