/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useEffect } from 'react';

import { compose } from 'redux';
import { Link } from 'react-router-dom';

import withUrlSite from '../../containers/withUrlSite';
import { connect } from 'react-redux';
import { selectHomePageUrl } from '../../reducers/uiReducer';
import { requestListIfNeeded } from '../../actions/pageActions';
import * as siteSelectors from '../../reducers/sitesReducer';
import { updateWithOps as updateSite } from '../../actions/siteActions';
import { set, del } from '../../lib/immutable-operations/operations';
import { selectHasPermission } from '../../reducers/policyReducer';
import { selectHasSubscription } from '../../reducers/subscriptionReducer';
import { open as openSubscriptionManager } from '../../actions/uiSubscriptionManagerActions';

import styles from './SetupGuide.module.scss';
import { ReactComponent as SvgIconVideoThumbnail } from '../../assets/images/icon-video-thumbnail.svg';
import { ReactComponent as SvgIconChevron } from '../../assets/images/icon-cheveron-left.svg';
import ImageWelcome from '../../assets/images/setup-welcome@2x.png';
import ImageStyles from '../../assets/images/setup-styles@2x.png';
import ImageHomePage from '../../assets/images/setup-home-page@2x.png';
import ImageBlog from '../../assets/images/setup-blog@2x.png';
import ImageIntegrations from '../../assets/images/setup-integrations@2x.png';
import ImageInvite from '../../assets/images/setup-invite@2x.png';
import ImageConnectDomain from '../../assets/images/setup-connect-domain@2x.png';

// Setup guide container
// ----------------------------------------------------------------------------

function mapStateToProps(state: any, ownProps: any) {
  // @ts-ignore
  const site = siteSelectors.selectSiteByDomain(state, ownProps.urlSite);

  return {
    step: site && site.metadata && site.metadata.setup_guide_step,
    homePageUrl: selectHomePageUrl(state),
    hasPermission: selectHasPermission(state),
    hasSubscription: selectHasSubscription(state),
  };
}

const mapDispatchToProps = {
  requestPageListIfNeeded: requestListIfNeeded,
  updateSite: updateSite,
  openSubscriptionManager: openSubscriptionManager,
};

function SetupGuideContainer(props: any) {
  // Provide a global function we can use to test the setup guide until Steve
  // updates the backend to initialize site.metadata on account create.
  (window as any).startSetupGuide = function () {
    props.updateSite(set('metadata.setup_guide_step', 0));
  };

  // Only render if these conditions are met:
  // - the user has not subscribed yet
  // - the user is an account owner or admin (permission: 'Account:setup_guide')
  // - the current step is an integer
  if (props.hasSubscription === false) {
    const userHasPermission = props.hasPermission('Account:setup_guide');

    if (userHasPermission && Number.isInteger(props.step)) return <SetupGuide {...props} />;
  }

  return null;
}

export default compose(
  withUrlSite,
  connect(mapStateToProps, mapDispatchToProps)
)(SetupGuideContainer) as () => JSX.Element;

// Setup guide
// ----------------------------------------------------------------------------

function SetupGuide(props: any) {
  const { step, homePageUrl, requestPageListIfNeeded, updateSite, openSubscriptionManager, urlSite } = props;

  // Append wistia script tags to body on step 0.
  useEffect(() => {
    if (step === 0) {
      const wistiaScripts = [
        'https://fast.wistia.com/embed/medias/o9zg8jru1z.jsonp',
        'https://fast.wistia.com/assets/external/E-v1.js',
      ];
      const fragment = document.createDocumentFragment();
      const scriptElements: any[] = [];

      for (let script of wistiaScripts) {
        const scriptElement = document.createElement('script');
        scriptElements.push(scriptElement);
        scriptElement.src = script;
        scriptElement.async = true;
        fragment.appendChild(scriptElement);
      }

      document.body.appendChild(fragment);

      // Return the cleanup function.
      return () => {
        scriptElements.forEach((el) => document.body.removeChild(el));
      };
    }
  }, [step]);

  // Request page list for the homePageUrl selector on step 1 if needed.
  useEffect(() => {
    if (step === 1) requestPageListIfNeeded();
  }, [step, requestPageListIfNeeded]);

  const setStep = (nextStep: number) => {
    if (nextStep < content.length) {
      updateSite(set('metadata.setup_guide_step', nextStep));
    } else {
      updateSite(del('metadata.setup_guide_step'));
    }
  };

  const handleClickNext = (e: any) => {
    e.preventDefault();
    setStep(step + 1);
  };

  const content = [
    {
      title: 'Welcome to Elastic Path Studio',
      subtext:
        'You’re a few steps away from publishing better digital experiences that will help market and grow your business. Get started by uploading your company logos.',
      buttonText: 'Upload your logos',
      buttonLink: `/${urlSite}/elements`,
      image: ImageWelcome,
    },
    {
      title: 'Set your styles',
      subtext: 'Add your brand colors and choose the Google fonts that best represent your business.',
      buttonText: 'Go to Styles',
      buttonLink: `/${urlSite}/styles`,
      image: ImageStyles,
    },
    {
      title: 'Edit your home page',
      subtext:
        'Update the content and graphics of your home page to tell your story and get visitors interested in what you have to offer.',
      buttonText: 'Edit home page',
      buttonLink: `/${urlSite}${homePageUrl}`,
      image: ImageHomePage,
    },
    {
      title: 'Publish a blog post',
      subtext:
        'Attract people searching the web. Offering useful information for free is a great way to build trust with future customers.',
      buttonText: 'Go to your blogs',
      buttonLink: `/${urlSite}/blogs`,
      image: ImageBlog,
    },
    {
      title: 'Connect Google Analytics',
      subtext:
        'Connect your favorite tools to Elastic Path Studio: Google Analytics, HubSpot, Klaviyo, Intercom, and Drift, to name a few.',
      buttonText: 'View integrations',
      buttonLink: `/${urlSite}/integrations`,
      image: ImageIntegrations,
    },
    {
      title: 'Invite collaborators',
      subtext:
        'Make it a team effort by giving access to your content creators, designers, developers, and administrators.',
      buttonText: 'View team settings',
      buttonLink: `/${urlSite}/settings`,
      image: ImageInvite,
    },
    {
      title: 'Upgrade to connect your domain',
      subtext:
        'Publish to your Elastic Path Studio domain for free or upgrade to a paid plan to connect your custom domain with SSL.',
      buttonText: 'View payment plans',
      buttonOnClick: (e: any) => {
        e.preventDefault();
        openSubscriptionManager();
      },
      image: ImageConnectDomain,
    },
  ];

  if (step < content.length) {
    return (
      <section style={{ marginBottom: `30px` }}>
        <div className={styles.container}>
          <div className={styles.progressbar}>
            <div style={{ width: `${step * 14.285714285714286}%` }} />
          </div>
          <div className={styles.content}>
            <h2>{content[step].title}</h2>
            <p>{content[step].subtext}</p>

            {step === 10 ? (
              <div className={styles.videobutton}>
                <span
                  className="wistia_embed wistia_async_o9zg8jru1z popover=true popoverContent=link"
                  style={{ display: `inline`, position: `relative` }}
                >
                  <a href="#">
                    <SvgIconVideoThumbnail />
                    Watch a short intro video
                    <span>(1:27)</span>
                  </a>
                </span>
              </div>
            ) : null}

            {content[step].buttonLink && (
              <Link to={content[step].buttonLink || '/'} className={'button button-primary ' + styles.cta}>
                {content[step].buttonText}
              </Link>
            )}
            {content[step].buttonOnClick && (
              <button onClick={content[step].buttonOnClick} className={'button button-primary ' + styles.cta}>
                {content[step].buttonText}
              </button>
            )}
            {step < content.length - 1 ? (
              <a className={styles.next} onClick={handleClickNext} href="#" role="button">
                Next <SvgIconChevron />
              </a>
            ) : (
              <a className={styles.next} onClick={handleClickNext} href="#" role="button">
                Hide setup guide
              </a>
            )}
          </div>
          <img src={content[step].image} alt="" />
        </div>
      </section>
    );
  } else {
    return null;
  }
}
