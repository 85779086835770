import React, { useState } from 'react';
import Modal from 'components/base/Modal';

import styles from 'components/AdminPanel/Quickstart/ScheduleTimeLink.module.scss';
import { ReactComponent as UnstackExpertIcon } from 'assets/images/unstack-expert-icon.svg';
import { ReactComponent as PricingIcon } from 'assets/images/pricing-icon.svg';
import { ReactComponent as UnstackIcon } from 'assets/images/unstack-icon.svg';
import { ReactComponent as LifeSaverIcon } from 'assets/images/lifesaver-icon.svg';
import { ReactComponent as CaretUpIcon } from 'assets/images/caret-up-icon.svg';
import { ReactComponent as CloseIcon } from 'assets/images/close-icon.svg';

const SCHEDULES = [
  {
    label: 'Get help on a feature',
    link: 'https://www.unstack.com/goto?feature-help',
    icon: LifeSaverIcon,
  },
  {
    label: 'Schedule a demo of Studio',
    link: 'https://www.unstack.com/goto?schedule-demo',
    icon: UnstackIcon,
  },
  {
    label: 'Talk to someone about pricing',
    link: 'https://www.unstack.com/goto?pricing-help',
    icon: PricingIcon,
  },
];

export default function ScheduleTimeLink() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const setModalOpen = () => setIsModalOpen(true);
  const setModalClose = () => setIsModalOpen(false);
  return (
    <>
      <section className={styles.scheduleTimeContainer}>
        <div className={styles.scheduleTimeContent}>
          <h3>Want to talk to an Elastic Path expert?</h3>
          <p>Learn how to get the most out of our platform.</p>
          <button onClick={setModalOpen} className="button button-primary">
            Schedule time with us!
          </button>
        </div>
        <div className={styles.iconContainer}>
          <UnstackExpertIcon />
        </div>
      </section>
      <Modal isOpen={isModalOpen} close={setModalClose} className={styles.scheduleTimeModal}>
        <div className={styles.talkToExpertContent}>
          <h1>
            Schedule time with us!
            <CloseIcon className={styles.closeIcon} onClick={setModalClose} />
          </h1>

          {SCHEDULES.map(({ label, link, icon: Icon }) => (
            <a href={link} target="_blank" className={styles.scheduleLinkContainer} key={label}>
              <div className={styles.linkLabel}>
                <Icon />
                <span>{label}</span>
              </div>
              <CaretUpIcon className={styles.caretIcon} />
            </a>
          ))}
        </div>
      </Modal>
    </>
  );
}
