import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import DocumentTitle from 'react-document-title';
import queryString from 'query-string';
import idx from 'lodash/get';

import { useDispatch, useSelector } from 'react-redux';
import * as accountActions from '../../actions/accountActions';
import * as accountSelectors from '../../reducers/accountReducer';

import FormControl from '../base/FormHelpers/FormControl';
import NonFieldErrors from '../base/FormHelpers/NonFieldErrors';
import LogoBanner from '../base/LogoSimpleBanner';

import { IS_COACHUP } from '../Coachup/isCoachup';

import styles from './Login.module.scss';
import { ReactComponent as SvgIconShopify } from 'assets/images/icon-shopify.svg';
import { ReactComponent as IconArrowRight } from 'assets/images/icon-arrow-right.svg';
import { OnMount } from 'hooks/mountUnmountHooks';

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const account = useSelector(accountSelectors.selectAccount);
  const errors = account.loginErrors;

  const { isLoggedIn, isFetching, isChallenged } = useSelector((state) => ({
    isLoggedIn: accountSelectors.selectIsLoggedIn(state),
    isChallenged: accountSelectors.selectIsChallenged(state),
    isFetching: accountSelectors.selectAccountIsFetching(state),
  }));

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    if (name === 'username') {
      setUsername(value);
    } else if (name === 'password') {
      setPassword(value);
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    dispatch(accountActions.requestLogin(username, password));
  };

  OnMount(() => {
    if (isLoggedIn) {
      const { ref } = queryString.parse(window.location.search);
      navigate((ref as string) || '/');
    }

    if (isChallenged) {
      navigate('/verify');
    }
  });

  const username_errors = idx(errors, 'username');
  const password_errors = idx(errors, 'password');
  const general_errors =
    errors &&
    Object.keys(errors).reduce(
      (accumulator, id) => (id === 'password' || id === 'username' ? accumulator : `${errors[id]} ${accumulator}`),
      ''
    );

  return (
    <div className="admin">
      <DocumentTitle title="Log in to Elastic Path Studio" />
      <main className={styles.Login} data-test-id="login-page-container">
        <LogoBanner />
        <div className={styles.container}>
          {/* @ts-ignore */}
          <form onSubmit={handleSubmit} disabled={isFetching}>
            <fieldset>
              <FormControl
                type="email"
                aria-label="email"
                placeholder="Email"
                name="username"
                autoComplete="email"
                onChange={handleInputChange}
                errors={username_errors}
                autoFocus
                className="ph-redact-input"
              />
            </fieldset>
            <fieldset>
              <FormControl
                type="password"
                aria-label="password"
                placeholder="Password"
                name="password"
                autoComplete="current-password"
                onChange={handleInputChange}
                errors={password_errors}
                className="ph-redact-input"
              />
            </fieldset>
            <small className={styles.forgotPassword}>
              <Link to="/password">Forgot password?</Link>
            </small>
            <input
              type="submit"
              className="button button-primary button-full"
              value={isFetching ? 'Signing in...' : 'Sign in'}
            />
            {general_errors && (
              <small key="FormControl-errorMessage" className="errorMessage" data-test-id="error">
                {general_errors}
              </small>
            )}
            <small className={styles.terms}>
              By using Elastic Path you agree to our{' '}
              <a href="https://www.unstack.com/terms-of-use/" target="_blank" rel="noopener noreferrer">
                Terms of Service
              </a>{' '}
              and{' '}
              <a href="https://www.unstack.com/privacy-policy/" target="_blank" rel="noopener noreferrer">
                Privacy Policy
              </a>
              .
            </small>
          </form>
          <div className={styles.shopifyContainer}>
            <div className={styles.shopifyText}>
              <SvgIconShopify width={40} height={40} /> Shopify Merchant?
            </div>
            <button
              className={styles.shopifyButton}
              onClick={(e) => {
                e.preventDefault();
                window.location.href = 'https://accounts.shopify.com/store-login';
              }}
            >
              Sign in here
            </button>
          </div>
        </div>
        {!IS_COACHUP && (
          <footer className={styles.loginFooter}>
            Don't have an account?{' '}
            <Link to="/signup">
              Get started for free <IconArrowRight />
            </Link>
          </footer>
        )}
      </main>
    </div>
  );
};

export default Login;
