import React, { useState } from 'react';
import idx from 'lodash/get';
import DocumentTitle from 'react-document-title';
import { useSelector, useDispatch } from 'react-redux';

import FormControl from '../base/FormHelpers/FormControl';
import NonFieldErrors from '../base/FormHelpers/NonFieldErrors';
import LogoBanner from '../base/LogoSimpleBanner';

import * as accountSelectors from '../../reducers/accountReducer';
import * as accountActions from '../../actions/accountActions';

import styles from './Login.module.scss';
import { useNavigate } from 'react-router-dom';

export default () => {
  const [code, setCode] = useState('');
  const dispatch = useDispatch();

  const isChallenged = useSelector(accountSelectors.selectIsChallenged);
  const isFetching = useSelector(accountSelectors.selectAccountIsFetching);
  const isLoggedIn = useSelector(accountSelectors.selectIsLoggedIn);
  const errors = useSelector(accountSelectors.selectVerificationErrors);
  const navigate = useNavigate();

  if (!isChallenged) {
    navigate('login');
  }

  if (isLoggedIn) {
    navigate('/');
  }

  return (
    <div className="admin">
      <DocumentTitle title="Log in to Elastic Path Studio" />
      <main className={styles.Login}>
        <LogoBanner />
        <form
          className="dark-bg"
          onSubmit={(e) => {
            e.preventDefault();
            dispatch(accountActions.verifyLoginCode(code));
          }}
          /* @ts-ignore */
          disabled={isFetching}
        >
          <fieldset>
            <label>Enter verification code</label>
            <FormControl
              type="text"
              placeholder="123456"
              name="code"
              onChange={(e: any) => setCode(e.target.value)}
              errors={idx(errors, 'code')}
              autoFocus
              value={code}
            />
          </fieldset>
          <NonFieldErrors errors={errors} />
          <fieldset>
            <input
              type="submit"
              className="button button-primary button-full"
              value={isFetching ? 'Verifying...' : 'Verify and log in'}
            />
          </fieldset>
        </form>
      </main>
    </div>
  );
};
