import React, { Dispatch, ReactNode, useState } from 'react';

import snakeCase from '../../lib/snakeCase';
import createFormatDate from 'date-fns/fp/format';
import * as uiArticleSettingsActions from '../../actions/uiArticleSettingsActions';
import { selectProps, selectArticleBlog } from '../../reducers/uiArticleSettingsReducer';
import { selectHasPermission } from '../../reducers/policyReducer';
import { selectSubmissionStateAndEvent } from '../../reducers/uiSubmitButtonsStateReducer';

import SideDrawer from '../base/SideDrawer';
import FormControl from '../base/FormHelpers/FormControl';
import { Checkbox } from '../Checkbox';
import NonFieldErrors from '../base/FormHelpers/NonFieldErrors';
import PrefixedInput from '../base/FormHelpers/PrefixedInput';
import DatePicker from '../../lib/react-datepicker';
import PageActionLinks from '../Pages/PageSettings/PageActionLinks';
import { isBefore, isEqual } from 'date-fns';
import BottomDrawer from '../base/BottomDrawer';
import MediaDrawerBottom from '../base/Media/Drawer/MediaDrawerBottom';
import MultiSelect from '../MultiSelect';

import { selectAllSorted } from '../../reducers/accountUsersReducer';
import { selectOne } from '../../reducers/mediaReducer';
import { set } from '../../lib/immutable-operations/operations';

import styles from '../DangerZone.module.scss';
import settingStyles from './ArticleSetting.module.scss';
import { compose } from 'redux';
import withBlogs from '../../containers/withBlogs';
import withSiteProps from '../../containers/withSiteProps';
import { selectByBlogIdInOptionsFormat } from '../../reducers/categoriesReducer';
import { selectAllActiveProducts } from '../../reducers/paymentReducer';
import * as siteSelectors from '../../reducers/sitesReducer';
import * as tagSelector from '../../reducers/tagReducer';
import { selectCurrentPlanName } from '../../reducers/subscriptionReducer';
import VersionSelector from 'components/Pages/PageSettings/VersionSelector';
import LinkField from 'components/Pages/LinkField';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { USite } from 'types/USite';

function mapSiteToProps(site: USite) {
  return {
    timezone: siteSelectors.getSiteTimezoneFromSite(site),
    siteTagDynamicPages: siteSelectors.getIsDynamicTagsPages(site),
  };
}

const formatDateWithoutYear = createFormatDate('LLLL d');
const CURRENT_DATE = new Date(new Date().toDateString());

const TIME_OPTIONS = [
  { value: '00:00:00Z', label: '12AM / 00:00' },
  { value: '01:00:00Z', label: '1AM / 01:00' },
  { value: '02:00:00Z', label: '2AM / 02:00' },
  { value: '03:00:00Z', label: '3AM / 03:00' },
  { value: '04:00:00Z', label: '4AM / 04:00' },
  { value: '05:00:00Z', label: '5AM / 05:00' },
  { value: '06:00:00Z', label: '6AM / 06:00' },
  { value: '07:00:00Z', label: '7AM / 07:00' },
  { value: '08:00:00Z', label: '8AM / 08:00' },
  { value: '09:00:00Z', label: '9AM / 09:00' },
  { value: '10:00:00Z', label: '10AM / 10:00' },
  { value: '11:00:00Z', label: '11AM / 11:00' },
  { value: '12:00:00Z', label: '12PM / 12:00' },
  { value: '13:00:00Z', label: '1PM / 13:00' },
  { value: '14:00:00Z', label: '2PM / 14:00' },
  { value: '15:00:00Z', label: '3PM / 15:00' },
  { value: '16:00:00Z', label: '4PM / 16:00' },
  { value: '17:00:00Z', label: '5PM / 17:00' },
  { value: '18:00:00Z', label: '6PM / 18:00' },
  { value: '19:00:00Z', label: '7PM / 19:00' },
  { value: '20:00:00Z', label: '8PM / 20:00' },
  { value: '21:00:00Z', label: '9PM / 21:00' },
  { value: '22:00:00Z', label: '10PM / 22:00' },
  { value: '23:00:00Z', label: '11PM / 23:00' },
];

type Props = {
  errors: any;
  siteDomain: string;
  blogSlug: string;
  blogsById: any;
  timezone: string;
  siteTagDynamicPages: any;
  blogId: string;
};

function ArticleSettings(props: Props) {
  const { article, isOpen, isFetching, blogSlug, errors } = useSelector(selectProps);
  let featuredMedia: any;
  const img = article?.meta?.image;
  featuredMedia = useSelector((state) => {
    if (img) {
      const imgId = img.split(':');
      if (imgId.length) {
        return selectOne(state, img.split(':')[1]);
      }
    }
    return '';
  });

  const [schedule, setSchedule] = useState<boolean>();
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>();
  const accountUsers = useSelector(selectAllSorted);
  const hasPermission = useSelector(selectHasPermission);
  const submitState = useSelector(selectSubmissionStateAndEvent);
  const currentPlanName = useSelector(selectCurrentPlanName);
  const categoriesById = useSelector((state) =>
    // @ts-ignore
    selectByBlogIdInOptionsFormat(state, props.blogId || selectArticleBlog(state).id)
  );
  const tags = useSelector(tagSelector.selectTags);
  const products = useSelector(selectAllActiveProducts);
  const dispatch: Dispatch<any> = useDispatch();
  const { blogsById, timezone, siteTagDynamicPages } = props;
  const { urlSite } = useParams();
  const navigate = useNavigate();

  const handleChange = (nameOrEvent: any, value: any) => {
    if (isFetching) return false;

    // Accept (name, value) argument pair or event
    let name;
    if (nameOrEvent.target) {
      name = nameOrEvent.target.name;
      value = nameOrEvent.target.value;
    } else {
      name = nameOrEvent;
    }

    if (name === 'is_indexable') {
      value = !nameOrEvent.target.checked;
    }

    if (name === 'meta.is_premium') dispatch(uiArticleSettingsActions.addOps(set('is_restricted', value === 'yes')));

    if (name === 'is_restricted') {
      value = nameOrEvent.target.checked;
      if (!value) dispatch(uiArticleSettingsActions.addOps(set('required_subscriptions', [])));
    }

    if (name === 'meta.image') {
      value = value || null;
    }

    dispatch(uiArticleSettingsActions.addOps(set(name, value)));
  };

  const handleSave = (e: any) => {
    e.preventDefault();
    dispatch(uiArticleSettingsActions.save());
  };

  const handlePublish = (e: any) => {
    e.preventDefault();
    dispatch(uiArticleSettingsActions.publish(schedule));
  };

  const upgrade = (event: any) => {
    event.preventDefault();

    navigate(`/${urlSite}/settings?call=openSubscriptionManager`);
  };

  const blog = (article && blogsById[article.blog]) || {};
  const categoriesList = Object.values(categoriesById);

  const categorySlug =
    article && article.categories?.length && categoriesList.find((c) => c.value === article.categories[0])?.slug;

  const mergedSlug =
    article && blog.config
      ? blog.config.urls.article === 'blog/category/article'
        ? `${blogsById[article.blog].slug}/${categorySlug || 'uncategorized'}/`
        : blog.config.urls.article === 'blog/article'
        ? `${blogsById[article.blog].slug}/`
        : blog.config.urls.article === 'category/article'
        ? `${categorySlug || 'uncategorized'}/`
        : ''
      : '';

  return (
    <SideDrawer
      isOpen={isOpen}
      close={() => dispatch(uiArticleSettingsActions.close())}
      showAmpLabel={hasPermission('AMP') && currentPlanName !== 'Full+'}
      upgrade={upgrade}
      title="Blog post settings"
      headerActions={
        !!(article && !article.is_deleted) &&
        (() => <VersionSelector itemId={article.id} onRestoreSuccess={close} itemType="article" />)
      }
      render={() => {
        if (article) {
          const articleSlug = article.slug == null ? blogSlug + '/' : article.slug;
          const featuredImage = featuredMedia;

          const authored_at = article.authored_at;

          const [authored_on_, publish_time = ''] = authored_at ? authored_at.split('T') : [];

          let authored_on = authored_on_ ? authored_on_.split('-') : [];
          authored_on = new Date(authored_on[0], authored_on[1] - 1, authored_on[2]);

          let timezonedDate = authored_on
            .toLocaleString('en-US', {
              timeZone: timezone,
            })
            .split(', ')[0]
            .split('/');
          timezonedDate = new Date(timezonedDate[2], timezonedDate[0] - 1, timezonedDate[1]);

          const isScheduled =
            article.status !== 'published' &&
            isBefore(CURRENT_DATE, authored_on) &&
            !isEqual(CURRENT_DATE, authored_on);

          const isScheduling = article.status === 'published' && isBefore(CURRENT_DATE, authored_on);

          if (article.is_deleted)
            return <PageActionLinks is_deleted={article.is_deleted} entity={article} entityType="article" />;

          return (
            <>
              <form onSubmit={handleSave}>
                <fieldset>
                  <label htmlFor="name_input">Elastic Path Studio name (for internal reference)</label>
                  <FormControl
                    id="name_input"
                    type="text"
                    name="name"
                    value={article.name || ''}
                    errors={errors.name}
                    onChange={handleChange}
                    autoComplete="off"
                    maxLength={65}
                    autoFocus
                  />
                </fieldset>
                <fieldset>
                  <label htmlFor="title_input">Title Tag (limit to 65 characters)</label>
                  <FormControl
                    id="title_input"
                    type="text"
                    name="meta.title"
                    value={article.meta.title || ''}
                    onChange={handleChange}
                    imitate={article.name}
                    errors={errors.title}
                    autoComplete="off"
                    maxLength={65}
                  />
                </fieldset>
                <fieldset>
                  <label htmlFor="keywords_input">Meta Keywords</label>
                  <FormControl
                    id="keywords_input"
                    tag="textarea"
                    type="text"
                    name="meta.keywords"
                    value={article.meta.keywords || ''}
                    onChange={handleChange}
                    errors={errors.keywords}
                    autoComplete="off"
                  />
                </fieldset>
                <fieldset>
                  <label htmlFor="description_input">Meta Description</label>
                  <FormControl
                    id="description_input"
                    tag="textarea"
                    type="text"
                    name="meta.description"
                    value={article.meta.description || ''}
                    onChange={handleChange}
                    errors={errors.description}
                    autoComplete="off"
                  />
                  <small>Text used in search engine descriptions.</small>
                </fieldset>
                <fieldset>
                  <label htmlFor="excerpt_input">Excerpt</label>
                  <FormControl
                    id="excerpt_input"
                    tag="textarea"
                    type="text"
                    name="meta.excerpt"
                    value={article.meta.excerpt || ''}
                    onChange={handleChange}
                    errors={errors.excerpt}
                    autoComplete="off"
                  />
                </fieldset>
                <fieldset data-test-id="categories-input-wrapper">
                  <label htmlFor="category_input">Categories</label>
                  <MultiSelect
                    options={categoriesList}
                    value={article.categories.map((c: any) => ({
                      value: categoriesById[c]?.value,
                      label: categoriesById[c]?.label,
                    }))}
                    onChange={(categoriesSelected: any) => {
                      handleChange('categories', categoriesSelected ? categoriesSelected.map((c: any) => c.value) : []);
                    }}
                    isMulti
                    name="categories"
                    id="category_input"
                    placeholder="Select categories"
                  />
                </fieldset>
                {siteTagDynamicPages && (
                  <fieldset>
                    <label>Tags</label>
                    <MultiSelect
                      isCreatable
                      placeholder="Pick tags"
                      options={tags}
                      value={article.tags.map((tag: any) => ({
                        label: tag.tag || tag.label,
                        value: tag.id || snakeCase(tag.tag || tag.label),
                      }))}
                      onTagCreate={(tag: any) => {
                        dispatch(uiArticleSettingsActions.addOps(set('tags', [...article.tags, { tag, value: tag }])));
                      }}
                      onChange={(values: any, meta: any) => {
                        const { action } = meta;
                        if (action === 'remove-value') {
                          dispatch(
                            uiArticleSettingsActions.addOps(
                              set(
                                'tags',
                                article.tags.filter((tag: any) =>
                                  tag.id ? tag.id !== meta.removedValue.value : tag.value !== meta.removedValue.value
                                )
                              )
                            )
                          );
                        } else
                          dispatch(
                            uiArticleSettingsActions.addOps(
                              set('tags', [
                                ...values.map((value: any) => ({
                                  tag: value.label,
                                  ...value,
                                })),
                              ])
                            )
                          );
                      }}
                    />
                  </fieldset>
                )}
                <fieldset>
                  <label>Author</label>
                  <FormControl
                    name="author"
                    onChange={handleChange}
                    value={article.author || ''}
                    errors={errors.author}
                    tag="select"
                  >
                    <option value=""></option>
                    {accountUsers.map((user) => (
                      <option key={user.id} value={user.id}>
                        {user.name || user.email}
                      </option>
                    ))}
                  </FormControl>
                </fieldset>
                <LinkField
                  id="page_url_input"
                  isHomePage={false}
                  PrefixedInput={PrefixedInput as unknown as ReactNode}
                  prefix={mergedSlug}
                  page={{ ...article, status: article.status || 'draft' }}
                  handleChange={handleChange}
                  errors={errors}
                />
                <fieldset>
                  <label htmlFor="canonical_input">Canonical URL</label>
                  <FormControl
                    id="canonical_input"
                    type="text"
                    name="meta.canonical"
                    value={article.meta.canonical || ''}
                    onChange={handleChange}
                    autoComplete="off"
                  />
                  <small>Leave blank if you want page's URL to used</small>
                </fieldset>
                <form style={{ marginBottom: 0 }} className="form-inline">
                  <fieldset>
                    <label htmlFor="authored_date">Authored date</label>
                    <FormControl
                      id="authored_date"
                      tag={DatePicker}
                      name="authored_on"
                      value={authored_on_}
                      onChange={(name: string, value: any) => {
                        handleChange('authored_at', value ? `${value}T${publish_time || '00:00:00Z'}` : undefined);
                      }}
                      asIsoDateStr
                      dateFormat="MMMM dd, yyyy"
                      errors={errors.authored_on}
                      todayButton="Today"
                      autoComplete="off"
                    />
                  </fieldset>
                  <fieldset>
                    <label>Publish time</label>
                    <FormControl
                      name="publish_time"
                      onChange={(event: any) => {
                        handleChange('authored_at', `${authored_on_}T${event.target.value}`);
                      }}
                      value={publish_time || '00:00:00Z'}
                      tag="select"
                      disabled={!Boolean(isScheduled || isScheduling) || !schedule}
                    >
                      <option value=""></option>
                      {TIME_OPTIONS.map((time) => (
                        <option key={time.value} value={time.value}>
                          {time.label}
                        </option>
                      ))}
                    </FormControl>
                  </fieldset>
                </form>
                {Boolean(isScheduled || isScheduling) && (
                  <fieldset>
                    <label>Scheduling options</label>
                    <div className={settingStyles.radioGroup}>
                      <label htmlFor="schedule_input">
                        <input
                          type="radio"
                          id="schedule_input"
                          aria-label="Schedule"
                          checked={schedule}
                          onChange={() => setSchedule(true)}
                        />
                        <div className={settingStyles.title}>
                          Schedule this post for {formatDateWithoutYear(authored_on)}
                        </div>
                      </label>
                      <label>
                        <input type="radio" checked={!schedule} onChange={() => setSchedule(false)} />
                        <div className={settingStyles.title}>Publish now with future date </div>
                      </label>
                    </div>
                  </fieldset>
                )}
                <fieldset className={settingStyles.imageUploader}>
                  <label>Featured image</label>
                  <div onClick={() => setIsDrawerOpen(true)} data-test-id="feature-media-drawer-opener">
                    <span className={settingStyles.fileName} data-test-id="featured-image-element">
                      {featuredImage
                        ? `Change image - ${
                            featuredImage.metadata.file_name || featuredImage?.file?.split('/').slice(-1)[0]
                          }`
                        : 'Choose image (720px by 400px)'}
                    </span>
                    <span
                      className={settingStyles.imageViewer}
                      style={{
                        ...(featuredImage && {
                          backgroundImage: `url(${featuredImage.thumbnail || featuredImage.url})`,
                        }),
                      }}
                    />
                  </div>
                  <BottomDrawer extendBody hideScroll isOpen={isDrawerOpen} close={() => setIsDrawerOpen(false)}>
                    <MediaDrawerBottom
                      close={() => setIsDrawerOpen(false)}
                      category="image"
                      entity={{
                        src: article.meta.image,
                      }}
                      onChange={(media: any) => {
                        handleChange('meta.image', media && media.src);
                      }}
                      hideVideo
                      hideEmbeds
                    />
                  </BottomDrawer>
                </fieldset>
                {hasPermission('Payments:*') && (
                  <fieldset>
                    <label>Designate this as premium content?</label>
                    <FormControl
                      tag="select"
                      type="text"
                      name="meta.is_premium"
                      value={article.meta.is_premium || 'no'}
                      onChange={handleChange}
                    >
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </FormControl>
                  </fieldset>
                )}
                {article.meta.is_premium === 'yes' && (
                  <div className={styles.notifyDiv}>
                    <p className={styles.noMargin}>
                      <label>
                        Premium content:{' '}
                        <small>Please select which products user must have to access the content</small>
                      </label>
                    </p>
                    <fieldset>
                      <label className="checkbox">
                        <FormControl
                          type="checkbox"
                          name="is_restricted"
                          checked={article.is_restricted}
                          onChange={handleChange}
                        />
                        <Checkbox checked={article.is_restricted} />
                        <span>Registered members only</span>
                      </label>
                    </fieldset>
                    {products.map((product: any) => {
                      const isChecked = article.required_subscriptions.find((s: any) => s.id === product.id);
                      return (
                        <fieldset>
                          <label className="checkbox">
                            <FormControl
                              type="checkbox"
                              name="required_subscriptions"
                              checked={isChecked}
                              onChange={(e: any) => {
                                let required_subscriptions = article.required_subscriptions;
                                if (e.target.checked) {
                                  required_subscriptions = [...required_subscriptions, { id: product.id }];
                                } else {
                                  required_subscriptions = [
                                    ...required_subscriptions.filter((s: any) => s.id !== product.id),
                                  ];
                                }
                                handleChange('required_subscriptions', required_subscriptions);
                              }}
                            />
                            <Checkbox checked={isChecked} />
                            <span>
                              {product.name} (stripe id: {product.processor_product_id})
                            </span>
                          </label>
                        </fieldset>
                      );
                    })}
                  </div>
                )}
                {hasPermission('Article:deindex') && (
                  <>
                    <h3 className={styles.dangerHeader}>Danger zone</h3>
                    <div className={styles.dangerDiv}>
                      {hasPermission('Article:deindex') && (
                        <fieldset>
                          <label className="checkbox">
                            <FormControl
                              type="checkbox"
                              name="is_indexable"
                              checked={article.is_indexable === undefined ? false : !article.is_indexable}
                              onChange={handleChange}
                            />
                            <Checkbox checked={!article.is_indexable} />
                            <span>Hide this article from search engines</span>
                          </label>
                          <small>This will prevent search engines from seeing this article.</small>
                        </fieldset>
                      )}
                    </div>
                  </>
                )}
                <NonFieldErrors errors={errors} />
                {hasPermission('Article:update') && (
                  <>
                    {hasPermission('Article:publish') && (
                      <button type="button" className="button button-primary button-full" onClick={handlePublish}>
                        {submitState.isSubmitting && submitState.publish
                          ? 'Saving and publishing...'
                          : 'Save and ' + (schedule ? 'schedule' : 'publish')}
                      </button>
                    )}
                    <input
                      type="submit"
                      className="button button-full"
                      value={submitState.isSubmitting && !submitState.publish ? 'Saving...' : 'Save only'}
                    />
                  </>
                )}
              </form>
              <PageActionLinks entity={article} entityType="article" />
            </>
          );
        }
      }}
    />
  );
}

export default compose(withBlogs, withSiteProps(mapSiteToProps))(ArticleSettings);
