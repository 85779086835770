import React from 'react';

import CheckPermissionOnClick from '../../../SubscriptionManager/CheckPermissionOnClick';
import { ReactComponent as SvgHideBranding } from '../../../../assets/images/icon-hide-branding.svg';

import classnames from 'classnames';

class ToggleUnstackBrandingButton extends React.PureComponent {
  handleClick = (e) => {
    const { value, onChange } = this.props;
    onChange(!value);
  };

  render() {
    const { value } = this.props;

    return (
      <CheckPermissionOnClick
        action="Site:debadge"
        onClick={this.handleClick}
        render={(handleClick) => (
          <button
            data-tip="Toggle Elastic Path Studio branding"
            className={classnames({ active: value })}
            onClick={handleClick}
          >
            <SvgHideBranding />
          </button>
        )}
      />
    );
  }
}

export default ToggleUnstackBrandingButton;
