/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useState } from 'react';
import classnames from 'classnames';

import Select from './Select';
import ColorSelector from './StyleGuideEditor/ColorSelector';

import CapitalizeSelect from './CapitalizeSelect';
import LetterSpacingField from './LetterSpacingField';

import { ReactComponent as SvgIconDesktopThumbnail } from '../../../assets/images/icon-desktop-styleguide.svg';
import { ReactComponent as SvgIconMobileThumbnail } from '../../../assets/images/icon-mobile-styleguide.svg';

import styles from './StyleGuideEditor.module.scss';

const headerActionStyleOptions = [
  { value: 'text-action', label: 'Action link' },
  { value: 'button-primary', label: 'Primary button' },
  { value: 'button-secondary', label: 'Secondary button' },
];

function getActionStyleClassName(actionStyle) {
  switch (actionStyle) {
    case 'button-primary':
      return 'button button-primary';
    case 'button-secondary':
      return 'button button-secondary';
    case 'text-action':
    default:
      return 'text-action';
  }
}

export default ({
  fontFamilyOptions,
  styleGuide,
  fontWeightOptions,
  handleChange,
  handleChangeEvent,
  darkBgColor,
  ...props
}) => {
  const [activeStyleTabs, setActiveStyleTabs] = useState({
    checklist: 'text',
    header: 'link',
    footer: 'links',
  });

  const [activeBackground, setActiveBackground] = useState({
    check_list: 'light',
    header: 'light',
    footer: 'light',
  });

  return (
    <>
      <header ref={props.scrollRef} className={styles.topSpacing}>
        <h1>Lists</h1>
        <small>Set the list styles used across your site.</small>
      </header>

      {/* Check list */}
      <article className={styles.StyleGuideEditor}>
        <section className={styles.styleSection}>
          <div className={styles.styleSectionHeader}>
            <p>Checklist</p>
            <div className={styles.bgToggles}>
              <div className={styles.background}>
                <span
                  className={classnames(styles.lightBackground, {
                    [styles.active]: activeBackground.check_list === 'light',
                  })}
                  onClick={() => setActiveBackground({ ...activeBackground, check_list: 'light' })}
                >
                  Light
                </span>
                <span
                  className={classnames(styles.darkBackground, {
                    [styles.active]: activeBackground.check_list === 'dark',
                  })}
                  onClick={() =>
                    setActiveBackground({
                      ...activeBackground,
                      check_list: 'dark',
                    })
                  }
                >
                  Dark
                </span>
              </div>

              <div className={styles.device}>
                <span
                  onClick={() => setActiveBackground({ ...activeBackground, check_list: 'light' })}
                  className={classnames(styles.lightBackground, {
                    [styles.active]: activeBackground.check_list === 'light',
                  })}
                >
                  <SvgIconDesktopThumbnail />
                </span>
                <span
                  onClick={() => setActiveBackground({ ...activeBackground, check_list: 'mobile' })}
                  className={classnames(styles.darkBackground, {
                    [styles.active]: activeBackground.check_list === 'mobile',
                  })}
                >
                  <SvgIconMobileThumbnail />
                </span>
              </div>
            </div>
          </div>
          <div
            style={{
              ...(activeBackground.check_list === 'dark' && {
                backgroundColor: darkBgColor.code,
                padding: '1rem 2rem 0.1rem',
                marginBottom: '2rem',
                borderRadius: '2px',
              }),
            }}
          >
            <div
              className={`sriracha ${
                activeBackground.check_list === 'dark'
                  ? 'dark-bg'
                  : activeBackground.check_list === 'mobile'
                  ? 'mobile'
                  : 'light-bg'
              }`}
            >
              <div className="rich-text">
                <ul data-checked>
                  <li>This is a check list item</li>
                  <li>This is a check list item</li>
                  <li>This is a check list item</li>
                </ul>
              </div>
            </div>
          </div>
          <div className={styles.optionsHeader}>
            <span
              onClick={() => setActiveStyleTabs({ ...activeStyleTabs, checklist: 'text' })}
              className={classnames({
                [styles.activeStyleTab]: activeStyleTabs.checklist === 'text',
              })}
            >
              Text
            </span>
            <span
              onClick={() => setActiveStyleTabs({ ...activeStyleTabs, checklist: 'icon' })}
              className={classnames({
                [styles.activeStyleTab]: activeStyleTabs.checklist === 'icon',
              })}
            >
              Icon
            </span>
          </div>
          <div className={styles.styleSectionOptions}>
            {activeStyleTabs.checklist === 'text' ? (
              activeBackground.check_list === 'light' ? (
                <>
                  <div className={styles.styleOption}>
                    <span>Font family</span>
                    <Select
                      options={fontFamilyOptions}
                      defaultValue={fontFamilyOptions[styleGuide.check_list_font_family_index]}
                      value={fontFamilyOptions[styleGuide.check_list_font_family_index]}
                      onChange={(option) => handleChange(['check_list', 'font_family_index'], option.value)}
                    />
                  </div>
                  <div className={styles.styleOption}>
                    <span>Font Weight</span>
                    <Select
                      options={fontWeightOptions}
                      defaultValue={fontWeightOptions.find(
                        (weight) => weight.value === Number(styleGuide.check_list_font_weight)
                      )}
                      value={fontWeightOptions.find(
                        (weight) => weight.value === Number(styleGuide.check_list_font_weight)
                      )}
                      onChange={(option) => handleChange(['check_list', 'font_weight'], option.value)}
                    />
                  </div>
                  <div className={styles.styleOption}>
                    <span>Line height</span>
                    <input
                      type="number"
                      name="check_list_line_height"
                      value={styleGuide.check_list_line_height}
                      min="0.8"
                      max="2"
                      step="0.1"
                      onChange={(e) => handleChangeEvent(e, ['check_list', 'line_height'])}
                    />
                  </div>
                  <div className={styles.styleOption}>
                    <span>Font size</span>
                    <input
                      type="number"
                      name="check_list_font_size"
                      value={styleGuide.check_list_font_size}
                      min="0.8"
                      max="3"
                      step="0.1"
                      onChange={(e) => handleChangeEvent(e, ['check_list', 'font_size'])}
                    />
                  </div>
                  <div className={styles.styleOption}>
                    <span>Bottom Spacing</span>
                    <input
                      type="number"
                      name="check_list_bottom_spacing"
                      value={styleGuide.check_list_bottom_spacing}
                      min="0"
                      max="2"
                      step="0.1"
                      onChange={(e) => handleChangeEvent(e, ['check_list', 'bottom_spacing'])}
                    />
                  </div>
                  <div className={styles.styleOption}>
                    <span>Color</span>
                    <ColorSelector
                      name="check_list_color"
                      onChange={(value) => handleChange(['check_list', 'color'], value)}
                      value={styleGuide.check_list_color}
                      placeholder="Color"
                      handleColorSelection={(value) => handleChange(['check_list', 'color'], value)}
                    />
                  </div>
                </>
              ) : activeBackground.check_list === 'dark' ? (
                <div className={styles.styleOption}>
                  <span>Color</span>
                  <ColorSelector
                    name="check_list_dark_bg_color"
                    onChange={(value) => handleChange(['check_list', 'dark_bg_color'], value)}
                    value={styleGuide.check_list_dark_bg_color}
                    placeholder="Color"
                    handleColorSelection={(value) => handleChange(['check_list', 'dark_bg_color'], value)}
                  />
                </div>
              ) : (
                <>
                  <div className={styles.styleOption}>
                    <span>Line height</span>
                    <input
                      type="number"
                      name="check_list_line_height_mobile"
                      value={styleGuide.check_list_line_height_mobile || styleGuide.check_list_line_height}
                      min="0.8"
                      max="2"
                      step="0.1"
                      onChange={(e) => handleChangeEvent(e, ['check_list', 'mobile', 'line_height'])}
                    />
                  </div>
                  <div className={styles.styleOption}>
                    <span>Font size</span>
                    <input
                      type="number"
                      name="check_list_font_size_mobile"
                      value={styleGuide.check_list_font_size_mobile}
                      min="0.8"
                      max="3"
                      step="0.1"
                      onChange={(e) => handleChangeEvent(e, ['check_list', 'mobile', 'font_size'])}
                    />
                  </div>
                  <div className={styles.styleOption}>
                    <span>Bottom Spacing</span>
                    <input
                      type="number"
                      name="check_list_bottom_spacing_mobile"
                      value={styleGuide.check_list_bottom_spacing_mobile || styleGuide.check_list_bottom_spacing}
                      min="0"
                      max="2"
                      step="0.1"
                      onChange={(e) => handleChangeEvent(e, ['check_list', 'mobile', 'bottom_spacing'])}
                    />
                  </div>
                </>
              )
            ) : activeBackground.check_list === 'light' ? (
              <>
                <div className={styles.styleOption}>
                  <span>Icon color</span>
                  <ColorSelector
                    name="check_list_icon_color"
                    onChange={(value) => handleChange(['check_list', 'icon_color'], value)}
                    value={styleGuide.check_list_icon_color}
                    placeholder="Color"
                    handleColorSelection={(value) => handleChange(['check_list', 'icon_color'], value)}
                  />
                </div>
                <div className={styles.styleOption}>
                  <span>Size</span>
                  <input
                    type="number"
                    name="check_list_icon_size"
                    value={styleGuide.check_list_icon_size}
                    min="0.5"
                    max="1.5"
                    step="0.1"
                    onChange={(e) => handleChangeEvent(e, ['check_list', 'icon_size'])}
                  />
                </div>
              </>
            ) : activeBackground.check_list === 'dark' ? (
              <div className={styles.styleOption}>
                <span>Icon color</span>
                <ColorSelector
                  name="check_list_dark_bg_icon_color"
                  onChange={(value) => handleChange(['check_list', 'dark_bg_icon_color'], value)}
                  value={styleGuide.check_list_dark_bg_icon_color}
                  placeholder="Color"
                  handleColorSelection={(value) => handleChange(['check_list', 'dark_bg_icon_color'], value)}
                />
              </div>
            ) : (
              <div className={styles.styleOption}>
                <span>Size</span>
                <input
                  type="number"
                  name="check_list_icon_size_mobile"
                  value={styleGuide.check_list_icon_size_mobile || styleGuide.check_list_icon_size}
                  min="0.5"
                  max="1.5"
                  step="0.1"
                  onChange={(e) => handleChangeEvent(e, ['check_list', 'mobile', 'icon_size'])}
                />
              </div>
            )}
          </div>
        </section>
      </article>

      {/* Header Navigation */}
      <article className={styles.StyleGuideEditor}>
        <section className={styles.styleSection}>
          <div className={styles.styleSectionHeader}>
            <p>Header navigation</p>
            <div className={styles.background}>
              <span
                className={classnames(styles.lightBackground, {
                  [styles.active]: activeBackground.header === 'light',
                })}
                onClick={() => setActiveBackground({ ...activeBackground, header: 'light' })}
              >
                Light
              </span>
              <span
                className={classnames(styles.darkBackground, {
                  [styles.active]: activeBackground.header === 'dark',
                })}
                onClick={() =>
                  setActiveBackground({
                    ...activeBackground,
                    header: 'dark',
                  })
                }
              >
                Dark
              </span>
            </div>
          </div>
          <div>
            <div className="eps sriracha">
              <header
                className={`primary-header ${activeBackground.header === 'dark' ? 'dark-bg' : `light-bg`}`}
                style={{
                  backgroundColor: activeBackground.header === 'dark' ? darkBgColor.code : 'transparent',
                  padding: activeBackground.header === 'dark' ? '1rem 2rem 1rem' : '0',
                  border: 'none',
                  paddingLeft: 0,
                }}
              >
                <nav id="primary-nav">
                  <ul>
                    <li>
                      <a className="link">Link 1</a>
                    </li>
                    <li>
                      <a className="link active">Link 2</a>
                    </li>
                    <li>
                      <a className="link">Link 3</a>
                    </li>
                    <li>
                      <a className="link">Link 4</a>
                    </li>
                    <li>
                      <a className={getActionStyleClassName(styleGuide.header_menu_action_style)}>Action</a>
                    </li>
                  </ul>
                </nav>
              </header>
            </div>
          </div>
          <div className={styles.optionsHeader}>
            <span
              onClick={() => setActiveStyleTabs({ ...activeStyleTabs, header: 'link' })}
              className={classnames({
                [styles.activeStyleTab]: activeStyleTabs.header === 'link',
              })}
            >
              Link
            </span>
            <span
              onClick={() => setActiveStyleTabs({ ...activeStyleTabs, header: 'current' })}
              className={classnames({
                [styles.activeStyleTab]: activeStyleTabs.header === 'current',
              })}
            >
              Current
            </span>
            <span
              onClick={() => setActiveStyleTabs({ ...activeStyleTabs, header: 'hover' })}
              className={classnames({
                [styles.activeStyleTab]: activeStyleTabs.header === 'hover',
              })}
            >
              Hover
            </span>
            <span
              onClick={() => setActiveStyleTabs({ ...activeStyleTabs, header: 'action' })}
              className={classnames({
                [styles.activeStyleTab]: activeStyleTabs.header === 'action',
              })}
            >
              Action
            </span>
          </div>
          <div className={styles.styleSectionOptions}>
            {activeStyleTabs.header === 'link' ? (
              activeBackground.header === 'light' ? (
                <>
                  <div className={styles.styleOption}>
                    <span>Font family</span>
                    <Select
                      options={fontFamilyOptions}
                      defaultValue={fontFamilyOptions[styleGuide.header_menu_font_family_index]}
                      value={fontFamilyOptions[styleGuide.header_menu_font_family_index]}
                      onChange={(option) => handleChange(['header_menu', 'font_family_index'], option.value)}
                    />
                  </div>
                  <div className={styles.styleOption}>
                    <span>Font Weight</span>
                    <Select
                      options={fontWeightOptions}
                      defaultValue={fontWeightOptions.find(
                        (weight) => weight.value === Number(styleGuide.header_menu_font_weight)
                      )}
                      value={fontWeightOptions.find(
                        (weight) => weight.value === Number(styleGuide.header_menu_font_weight)
                      )}
                      onChange={(option) => handleChange(['header_menu', 'font_weight'], option.value)}
                    />
                  </div>
                  <div className={styles.styleOption}>
                    <span>Font size</span>
                    <input
                      type="number"
                      value={styleGuide.header_menu_font_size}
                      min="0.8"
                      max="3"
                      step="0.1"
                      name="header_menu_font_size"
                      onChange={(e) => handleChangeEvent(e, ['header_menu', 'font_size'])}
                    />
                  </div>
                  <CapitalizeSelect
                    value={styleGuide.header_menu_text_transform}
                    handleChange={(value) => handleChange(['header_menu', 'text_transform'], value)}
                  />
                  <LetterSpacingField
                    name="header_menu_letter_spacing"
                    value={styleGuide.header_menu_letter_spacing}
                    handleChange={(value) => handleChange(['header_menu', 'letter_spacing'], value)}
                  />
                  <div className={styles.styleOption}>
                    <span>Color</span>
                    <ColorSelector
                      name="header_menu_color"
                      onChange={(value) => handleChange(['header_menu', 'color'], value)}
                      value={styleGuide.header_menu_color}
                      placeholder="Color"
                      handleColorSelection={(value) => handleChange(['header_menu', 'color'], value)}
                    />
                  </div>
                  <div className={styles.styleOption}>
                    <span>Opacity</span>
                    <input
                      type="number"
                      name={'header_menu_opacity'}
                      value={styleGuide.header_menu_opacity}
                      min="0"
                      max="1"
                      step="0.05"
                      onChange={(e) => handleChangeEvent(e, ['header_menu', 'opacity'])}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className={styles.styleOption}>
                    <span>Color</span>
                    <ColorSelector
                      name="header_menu_dark_bg_color"
                      onChange={(value) => handleChange(['header_menu', 'dark_bg_color'], value)}
                      value={styleGuide.header_menu_dark_bg_color}
                      placeholder="Color"
                      handleColorSelection={(value) => handleChange(['header_menu', 'dark_bg_color'], value)}
                    />
                  </div>
                  <div className={styles.styleOption}>
                    <span>Opacity</span>
                    <input
                      type="number"
                      name={'header_menu_dark_bg_opacity'}
                      value={styleGuide.header_menu_dark_bg_opacity}
                      min="0"
                      max="1"
                      step="0.05"
                      onChange={(e) => handleChangeEvent(e, ['header_menu', 'dark_bg_opacity'])}
                    />
                  </div>
                </>
              )
            ) : null}
            {activeStyleTabs.header === 'action' ? (
              <>
                <div className={styles.styleOption}>
                  <span>Style</span>
                  <Select
                    options={headerActionStyleOptions}
                    defaultValue={headerActionStyleOptions.find(
                      (option) => option.value === styleGuide.header_menu_action_style
                    )}
                    value={headerActionStyleOptions.find(
                      (option) => option.value === styleGuide.header_menu_action_style
                    )}
                    onChange={(option) => handleChange(['header_menu', 'action_style'], option.value)}
                  />
                </div>
                {styleGuide.header_menu_action_style === 'text-action' &&
                  (activeBackground.header === 'light' ? (
                    <>
                      <div className={styles.styleOption}>
                        <span>Font family</span>
                        <Select
                          options={fontFamilyOptions}
                          defaultValue={fontFamilyOptions[styleGuide.header_menu_action_font_family_index]}
                          value={fontFamilyOptions[styleGuide.header_menu_action_font_family_index]}
                          onChange={(option) => handleChange(['header_menu', 'action_font_family_index'], option.value)}
                        />
                      </div>
                      <div className={styles.styleOption}>
                        <span>Font Weight</span>
                        <Select
                          options={fontWeightOptions}
                          defaultValue={fontWeightOptions.find(
                            (weight) => weight.value === Number(styleGuide.header_menu_action_font_weight)
                          )}
                          value={fontWeightOptions.find(
                            (weight) => weight.value === Number(styleGuide.header_menu_action_font_weight)
                          )}
                          onChange={(option) => handleChange(['header_menu', 'action_font_weight'], option.value)}
                        />
                      </div>
                      <div className={styles.styleOption}>
                        <span>Font size</span>
                        <input
                          type="number"
                          value={styleGuide.header_menu_action_font_size}
                          min="0.8"
                          max="3"
                          step="0.1"
                          name="header_menu_action_font_size"
                          onChange={(e) => handleChangeEvent(e, ['header_menu', 'action_font_size'])}
                        />
                      </div>
                      <CapitalizeSelect
                        value={styleGuide.header_menu_action_text_transform}
                        handleChange={(value) => handleChange(['header_menu', 'action_text_transform'], value)}
                      />
                      <LetterSpacingField
                        name="header_menu_action_letter_spacing"
                        value={styleGuide.header_menu_action_letter_spacing}
                        handleChange={(value) => handleChange(['header_menu', 'action_letter_spacing'], value)}
                      />
                      <div className={styles.styleOption}>
                        <span>Color</span>
                        <ColorSelector
                          name="header_menu_action_color"
                          onChange={(value) => handleChange(['header_menu', 'action_color'], value)}
                          value={styleGuide.header_menu_action_color}
                          placeholder="Color"
                          handleColorSelection={(value) => handleChange(['header_menu', 'action_color'], value)}
                        />
                      </div>
                    </>
                  ) : (
                    <div className={styles.styleOption}>
                      <span>Color</span>
                      <ColorSelector
                        name="header_menu_dark_bg_action_color"
                        onChange={(value) => handleChange(['header_menu', 'dark_bg_action_color'], value)}
                        value={styleGuide.header_menu_dark_bg_action_color}
                        placeholder="Color"
                        handleColorSelection={(value) => handleChange(['header_menu', 'dark_bg_action_color'], value)}
                      />
                    </div>
                  ))}
              </>
            ) : null}
            {activeStyleTabs.header === 'hover' ? (
              <>
                <div className={styles.styleOption}>
                  <span>Color</span>
                  <ColorSelector
                    name={
                      activeBackground.header === 'dark' ? 'header_menu_dark_bg_hover_color' : 'header_menu_hover_color'
                    }
                    onChange={(value) =>
                      handleChange(
                        [
                          'header_menu',
                          ...(activeBackground.header === 'dark' ? ['dark_bg_hover_color'] : ['hover_color']),
                        ],
                        value
                      )
                    }
                    value={
                      activeBackground.header === 'dark'
                        ? styleGuide.header_menu_dark_bg_hover_color
                        : styleGuide.header_menu_hover_color
                    }
                    placeholder="Color"
                    handleColorSelection={(value) =>
                      handleChange(
                        [
                          'header_menu',
                          ...(activeBackground.header === 'dark' ? ['dark_bg_hover_color'] : ['hover_color']),
                        ],
                        value
                      )
                    }
                  />
                </div>
                <div className={styles.styleOption}>
                  <span>Opacity</span>
                  <input
                    type="number"
                    name={
                      activeBackground.header === 'dark'
                        ? 'header_menu_dark_bg_hover_opacity'
                        : 'header_menu_hover_opacity'
                    }
                    value={
                      activeBackground.header === 'dark'
                        ? styleGuide.header_menu_dark_bg_hover_opacity
                        : styleGuide.header_menu_hover_opacity
                    }
                    min="0"
                    max="1"
                    step="0.05"
                    onChange={(e) =>
                      handleChangeEvent(e, [
                        'header_menu',
                        ...(activeBackground.header === 'dark' ? ['dark_bg_hover_opacity'] : ['hover_opacity']),
                      ])
                    }
                  />
                </div>
              </>
            ) : null}
            {activeStyleTabs.header === 'current' ? (
              <>
                <div className={styles.styleOption}>
                  <span>Color</span>
                  <ColorSelector
                    name={
                      activeBackground.header === 'dark'
                        ? 'header_menu_dark_bg_current_color'
                        : 'header_menu_current_color'
                    }
                    onChange={(value) =>
                      handleChange(
                        [
                          'header_menu',
                          ...(activeBackground.header === 'dark' ? ['dark_bg_current_color'] : ['current_color']),
                        ],
                        value
                      )
                    }
                    value={
                      activeBackground.header === 'dark'
                        ? styleGuide.header_menu_dark_bg_current_color
                        : styleGuide.header_menu_current_color
                    }
                    placeholder="Color"
                    handleColorSelection={(value) =>
                      handleChange(
                        [
                          'header_menu',
                          ...(activeBackground.header === 'dark' ? ['dark_bg_current_color'] : ['current_color']),
                        ],
                        value
                      )
                    }
                  />
                </div>
                <div className={styles.styleOption}>
                  <span>Opacity</span>
                  <input
                    type="number"
                    name={
                      activeBackground.header === 'dark'
                        ? 'header_menu_dark_bg_current_opacity'
                        : 'header_menu_current_opacity'
                    }
                    value={
                      activeBackground.header === 'dark'
                        ? styleGuide.header_menu_dark_bg_current_opacity
                        : styleGuide.header_menu_current_opacity
                    }
                    min="0"
                    max="1"
                    step="0.05"
                    onChange={(e) =>
                      handleChangeEvent(e, [
                        'header_menu',
                        ...(activeBackground.header === 'dark' ? ['dark_bg_current_opacity'] : ['current_opacity']),
                      ])
                    }
                  />
                </div>
              </>
            ) : null}
          </div>
        </section>
      </article>

      {/* Footer navigation */}
      <article className={styles.StyleGuideEditor}>
        <section className={styles.styleSection}>
          <div className={styles.styleSectionHeader}>
            <p>Footer navigation</p>
            <div className={styles.background}>
              <span
                className={classnames(styles.lightBackground, {
                  [styles.active]: activeBackground.footer === 'light',
                })}
                onClick={() => setActiveBackground({ ...activeBackground, footer: 'light' })}
              >
                Light
              </span>
              <span
                className={classnames(styles.darkBackground, {
                  [styles.active]: activeBackground.footer === 'dark',
                })}
                onClick={() =>
                  setActiveBackground({
                    ...activeBackground,
                    footer: 'dark',
                  })
                }
              >
                Dark
              </span>
            </div>
          </div>
          <div>
            <div className="eps sriracha">
              <footer
                className={`main-footer ${activeBackground.footer === 'dark' ? 'dark-bg' : 'light-bg'}`}
                style={{
                  backgroundColor: activeBackground.footer === 'dark' ? darkBgColor.code : 'transparent',
                  padding: activeBackground.footer === 'dark' ? '1rem 2rem 1rem' : '0',
                  border: 'none',
                  paddingLeft: 0,
                }}
              >
                <div className="primary-footer">
                  <div className="menus" style={{ justifyContent: 'flex-start' }}>
                    <div className="menu">
                      <h4>Footer category</h4>
                      <ul>
                        <li>
                          <a>Footer link</a>
                        </li>
                        <li>
                          <a>Footer link</a>
                        </li>
                        <li>
                          <a>Footer link</a>
                        </li>
                        <li>
                          <a>Footer link</a>
                        </li>
                      </ul>
                    </div>
                    <div className="menu">
                      <h4>Footer category</h4>
                      <ul>
                        <li>
                          <a>Footer link</a>
                        </li>
                        <li>
                          <a>Footer link</a>
                        </li>
                        <li>
                          <a>Footer link</a>
                        </li>
                        <li>
                          <a>Footer link</a>
                        </li>
                      </ul>
                    </div>
                    <div className="menu">
                      <h4>Footer category</h4>
                      <ul>
                        <li>
                          <a>Footer link</a>
                        </li>
                        <li>
                          <a>Footer link</a>
                        </li>
                        <li>
                          <a>Footer link</a>
                        </li>
                        <li>
                          <a>Footer link</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </footer>
            </div>
          </div>
          <div className={styles.optionsHeader}>
            <span
              onClick={() => setActiveStyleTabs({ ...activeStyleTabs, footer: 'links' })}
              className={classnames({
                [styles.activeStyleTab]: activeStyleTabs.footer === 'links',
              })}
            >
              Links
            </span>
            <span
              onClick={() => setActiveStyleTabs({ ...activeStyleTabs, footer: 'category' })}
              className={classnames({
                [styles.activeStyleTab]: activeStyleTabs.footer === 'category',
              })}
            >
              Category
            </span>
            <span
              onClick={() => setActiveStyleTabs({ ...activeStyleTabs, footer: 'hover' })}
              className={classnames({
                [styles.activeStyleTab]: activeStyleTabs.footer === 'hover',
              })}
            >
              Hover
            </span>
          </div>
          <div className={styles.styleSectionOptions}>
            {activeStyleTabs.footer === 'links' ? (
              activeBackground.footer === 'light' ? (
                <>
                  <div className={styles.styleOption}>
                    <span>Font family</span>
                    <Select
                      options={fontFamilyOptions}
                      defaultValue={fontFamilyOptions[styleGuide.footer_menu_font_family_index]}
                      value={fontFamilyOptions[styleGuide.footer_menu_font_family_index]}
                      onChange={(option) => handleChange(['footer_menu', 'font_family_index'], option.value)}
                    />
                  </div>
                  <div className={styles.styleOption}>
                    <span>Font Weight</span>
                    <Select
                      options={fontWeightOptions}
                      defaultValue={fontWeightOptions.find(
                        (weight) => weight.value === Number(styleGuide.footer_menu_font_weight)
                      )}
                      value={fontWeightOptions.find(
                        (weight) => weight.value === Number(styleGuide.footer_menu_font_weight)
                      )}
                      onChange={(option) => handleChange(['footer_menu', 'font_weight'], option.value)}
                    />
                  </div>
                  <div className={styles.styleOption}>
                    <span>Font size</span>
                    <input
                      type="number"
                      value={styleGuide.footer_menu_font_size}
                      min="0.8"
                      max="3"
                      step="0.1"
                      name="footer_menu_font_size"
                      onChange={(e) => handleChangeEvent(e, ['footer_menu', 'font_size'])}
                    />
                  </div>
                  <CapitalizeSelect
                    value={styleGuide.footer_menu_text_transform}
                    handleChange={(value) => handleChange(['footer_menu', 'text_transform'], value)}
                  />
                  <LetterSpacingField
                    name="footer_menu_letter_spacing"
                    value={styleGuide.footer_menu_letter_spacing}
                    handleChange={(value) => handleChange(['footer_menu', 'letter_spacing'], value)}
                  />
                  <div className={styles.styleOption}>
                    <span>Color</span>
                    <ColorSelector
                      name="footer_menu_color"
                      onChange={(value) => handleChange(['footer_menu', 'color'], value)}
                      value={styleGuide.footer_menu_color}
                      placeholder="Color"
                      handleColorSelection={(value) => handleChange(['footer_menu', 'color'], value)}
                    />
                  </div>
                  <div className={styles.styleOption}>
                    <span>Opacity</span>
                    <input
                      type="number"
                      name={'footer_menu_opacity'}
                      value={styleGuide.footer_menu_opacity}
                      min="0"
                      max="1"
                      step="0.05"
                      onChange={(e) => handleChangeEvent(e, ['footer_menu', 'opacity'])}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className={styles.styleOption}>
                    <span>Color</span>
                    <ColorSelector
                      name="footer_menu_dark_bg_color"
                      onChange={(value) => handleChange(['footer_menu', 'dark_bg_color'], value)}
                      value={styleGuide.footer_menu_dark_bg_color}
                      placeholder="Color"
                      handleColorSelection={(value) => handleChange(['footer_menu', 'dark_bg_color'], value)}
                    />
                  </div>
                  <div className={styles.styleOption}>
                    <span>Opacity</span>
                    <input
                      type="number"
                      name={'footer_menu_dark_bg_opacity'}
                      value={styleGuide.footer_menu_dark_bg_opacity}
                      min="0"
                      max="1"
                      step="0.05"
                      onChange={(e) => handleChangeEvent(e, ['footer_menu', 'dark_bg_opacity'])}
                    />
                  </div>
                </>
              )
            ) : null}
            {activeStyleTabs.footer === 'category' ? (
              activeBackground.footer === 'light' ? (
                <>
                  <div className={styles.styleOption}>
                    <span>Font family</span>
                    <Select
                      options={fontFamilyOptions}
                      defaultValue={fontFamilyOptions[styleGuide.footer_category_font_family_index]}
                      value={fontFamilyOptions[styleGuide.footer_category_font_family_index]}
                      onChange={(option) => handleChange(['footer_menu', 'category_font_family_index'], option.value)}
                    />
                  </div>
                  <div className={styles.styleOption}>
                    <span>Font Weight</span>
                    <Select
                      options={fontWeightOptions}
                      defaultValue={fontWeightOptions.find(
                        (weight) => weight.value === Number(styleGuide.footer_category_font_weight)
                      )}
                      value={fontWeightOptions.find(
                        (weight) => weight.value === Number(styleGuide.footer_category_font_weight)
                      )}
                      onChange={(option) => handleChange(['footer_menu', 'category_font_weight'], option.value)}
                    />
                  </div>
                  <div className={styles.styleOption}>
                    <span>Font size</span>
                    <input
                      type="number"
                      value={styleGuide.footer_category_font_size}
                      min="0.8"
                      max="3"
                      step="0.1"
                      name="footer_category_font_size"
                      onChange={(e) => handleChangeEvent(e, ['footer_menu', 'category_font_size'])}
                    />
                  </div>
                  <CapitalizeSelect
                    value={styleGuide.footer_menu_category_text_transform}
                    handleChange={(value) => handleChange(['footer_menu', 'category_text_transform'], value)}
                  />
                  <LetterSpacingField
                    name="footer_menu_category_letter_spacing"
                    value={styleGuide.footer_menu_category_letter_spacing}
                    handleChange={(value) => handleChange(['footer_menu', 'category_letter_spacing'], value)}
                  />
                  <div className={styles.styleOption}>
                    <span>Color</span>
                    <ColorSelector
                      name="footer_category_color"
                      onChange={(value) => handleChange(['footer_menu', 'category_color'], value)}
                      value={styleGuide.footer_category_color}
                      placeholder="Color"
                      handleColorSelection={(value) => handleChange(['footer_menu', 'category_color'], value)}
                    />
                  </div>
                </>
              ) : (
                <div className={styles.styleOption}>
                  <span>Color</span>
                  <ColorSelector
                    name="footer_dark_bg_category_color"
                    onChange={(value) => handleChange(['footer_menu', 'dark_bg_category_color'], value)}
                    value={styleGuide.footer_dark_bg_category_color}
                    placeholder="Color"
                    handleColorSelection={(value) => handleChange(['footer_menu', 'dark_bg_category_color'], value)}
                  />
                </div>
              )
            ) : null}
            {activeStyleTabs.footer === 'hover' && (
              <>
                <div className={styles.styleOption}>
                  <span>Color</span>
                  <ColorSelector
                    name={
                      activeBackground.footer === 'dark' ? 'footer_dark_bg_link_hover_color' : 'footer_link_hover_color'
                    }
                    onChange={(value) =>
                      handleChange(
                        [
                          'footer_menu',
                          ...(activeBackground.footer === 'dark' ? ['dark_bg_link_hover_color'] : ['link_hover_color']),
                        ],
                        value
                      )
                    }
                    value={
                      activeBackground.footer === 'dark'
                        ? styleGuide.footer_dark_bg_link_hover_color
                        : styleGuide.footer_link_hover_color
                    }
                    placeholder="Color"
                    handleColorSelection={(value) =>
                      handleChange(
                        [
                          'footer_menu',
                          ...(activeBackground.footer === 'dark' ? ['dark_bg_link_hover_color'] : ['link_hover_color']),
                        ],
                        value
                      )
                    }
                  />
                </div>
                <div className={styles.styleOption}>
                  <span>Opacity</span>
                  <input
                    type="number"
                    value={
                      activeBackground.footer === 'dark'
                        ? styleGuide.footer_dark_bg_link_hover_opacity
                        : styleGuide.footer_link_hover_opacity
                    }
                    min="0"
                    max="1"
                    step="0.05"
                    name={
                      activeBackground.footer === 'dark'
                        ? 'footer_dark_bg_link_hover_opacity'
                        : 'footer_link_hover_opacity'
                    }
                    onChange={(e) =>
                      handleChangeEvent(e, [
                        'footer_menu',
                        ...(activeBackground.footer === 'dark'
                          ? ['dark_bg_link_hover_opacity']
                          : ['link_hover_opacity']),
                      ])
                    }
                  />
                </div>
              </>
            )}
          </div>
        </section>
      </article>
    </>
  );
};
