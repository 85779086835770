import React, { useMemo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useParams } from 'react-router-dom';

import ExpansionPanel from 'components/AdminPanel/Quickstart/ExpansionPanel';
import { selectAccount } from 'reducers/accountReducer';
import { selectIntegrationTypes } from 'reducers/integrationsReducer';
import { patchOnboarding, removeOnboardingStep } from 'actions/accountActions';
import styles from 'components/AdminPanel/Quickstart/IntegrateContentPanel.module.scss';
import { getAreIntegrationsComplete } from 'components/AdminPanel/Quickstart/helpers';
import { INTEGRATE_UNSTACK_COMPLETE, INTEGRATE_UNSTACK_PENDING } from 'components/AdminPanel/Quickstart/constants';
import Integration from 'components/AdminPanel/Quickstart/Integration';
import { useQuickstartStep } from 'components/AdminPanel/Quickstart/hooks/useQuickstartStep';
import useActiveSite from 'hooks/useActiveSite';
import { selectIntegrationBySlug } from 'reducers/integrationsReducer';

export enum AUTHENTICATOR_TYPE {
  API = 'api',
  OAUTH = 'oauth',
}

const INTEGRATIONS_SLUGS = [
  'google_analytics_4',
  'hubspot',
  'salesforce',
  'mailchimp',
  'drift',
  'semrush',
  'intercom',
  'zapier',
];

const INTEGRATIONS_SLUGS_SHOPIFY = [
  'klaviyo',
  'mailchimp',
  'gorgias',
  'google_analytics_4',
  'facebook_pixel',
  'typekit',
  'zapier',
];

const FULL_PLUS_PLAN_INTEGRATIONS = ['hubspot', 'salesforce'];
const IntegrateContent = () => {
  const { urlSite } = useParams();
  const activeSite = useActiveSite();
  const isShopifyConnected = Boolean(
    useSelector((state) =>
      selectIntegrationBySlug(
        state,
        //  @ts-ignore
        activeSite.id,
        'shopify'
      )
    )
  );
  const [slugs, setSlugs] = useState(INTEGRATIONS_SLUGS);
  useEffect(() => {
    if (isShopifyConnected) setSlugs(INTEGRATIONS_SLUGS_SHOPIFY);
    else setSlugs(INTEGRATIONS_SLUGS);
  }, [isShopifyConnected]);
  const types = useSelector(selectIntegrationTypes);
  const integrationsToShow = useMemo(() => {
    const integrations: any = [];
    if (types) types.forEach((type: any) => integrations.push(type.integrations));
    return integrations
      .flat()
      .filter((integration: any) => slugs.includes(integration.slug))
      .sort((a: any, b: any) => slugs.indexOf(a.slug) - slugs.indexOf(b.slug))
      .map((integration: any) => {
        const authenticator = integration.authenticator;
        return {
          name: integration.name,
          icon: integration.icon,
          id: integration.id,
          slug: integration.id,
          //Need to modify this planTier functionality in future
          requiredPlan: FULL_PLUS_PLAN_INTEGRATIONS.includes(integration.slug) ? 'Full+' : 'Full',
          ...(authenticator && {
            authenticatorType: authenticator.authenticator_type,
            authorizationUrl: authenticator.authorization_url,
            authenticatorId: authenticator.id,
            authorizationSuccessMessage: authenticator.authorization_success_message,
          }),
        };
      });
  }, [types, slugs]);
  return (
    <div className={styles.integrationContent}>
      <p>
        Elastic Path Studio has integrations with many common tools! The more you connect, the more value you get out of
        the platform.
      </p>
      <div className={styles.integrationContainer}>
        {integrationsToShow.map((integration: any) => (
          <Integration {...integration} />
        ))}
      </div>
      <NavLink to={`/${urlSite}/integrations`}>Browse all integrations</NavLink>
    </div>
  );
};

export default function IntegrateContentPanel() {
  const account = useSelector(selectAccount);
  const isStepComplete = getAreIntegrationsComplete();
  const siteMetaData = account.metadata?.onboarding || [];
  const { handleIconClick, open, handleExpansionPanelClick } = useQuickstartStep({
    isStepComplete,
    metadata: siteMetaData,
    id: account.id,
    pendingDescription: INTEGRATE_UNSTACK_PENDING,
    completedDescription: INTEGRATE_UNSTACK_COMPLETE,
    patchOnboarding,
    removeOnboardingStep,
  });

  return (
    <ExpansionPanel
      title="Integrate Elastic Path Studio with your tools"
      isComplete={isStepComplete}
      onClick={handleExpansionPanelClick}
      onIconClick={handleIconClick}
      open={open}
    >
      <IntegrateContent />
    </ExpansionPanel>
  );
}
