import { OnMount } from 'hooks/mountUnmountHooks';
import { FC, ReactNode, useEffect, useRef, useState } from 'react';
import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Frame, { FrameContextConsumer } from 'react-frame-component';
import { useSelector } from 'react-redux';
import { getDevice, getDeviceWidth } from 'reducers/uiReducer';

import styles from './FramePortal.module.scss';
import classnames from 'classnames';
import { selectProps } from 'reducers/uiPageSettingsReducer';
import useActiveSite from 'hooks/useActiveSite';

const FrameBindingContext: FC<{ children?: ReactNode }> = ({ children }) => (
  <FrameContextConsumer>
    {({ window }: any) => (
      <DndProvider backend={HTML5Backend} context={window}>
        {children}
      </DndProvider>
    )}
  </FrameContextConsumer>
);

type FramePortalProps = {
  setIFrameLoaded: () => void;
  children?: ReactNode;
  style?: React.CSSProperties;
  noHeader?: boolean;
};

export const FramePortal: FC<FramePortalProps> = (props: FramePortalProps) => {
  const device: string = useSelector(getDevice);
  const activeSite = useActiveSite();
  const iframe = useRef<HTMLIFrameElement>();
  const [stylesLoaded, setStylesLoaded] = useState(false);
  const width: string | number = useSelector(getDeviceWidth);
  const page = useSelector(selectProps).page;
  const noHeader = page?.config?.header_layout === 'none' || props.noHeader;

  function loadCss() {
    const browserIframe = iframe.current;

    if (browserIframe) {
      let styles = document.querySelectorAll('style, head > *');
      styles.forEach((style) => {
        browserIframe.contentDocument.head.appendChild(style.cloneNode(true));
      });
      setStylesLoaded(true);

      // emit events from the iframe to main document (click and mouse events)
      Object.keys(window).forEach((key) => {
        if (/^on/.test(key)) {
          browserIframe.contentDocument?.addEventListener(key.slice(2), (event) => {
            const new_e = new Event(event.type, event);
            if (event.type === 'selectionchange') {
              try {
                document.dispatchEvent(new_e);
              } catch (e) {}
            }
          });
        }
      });
    }
  }

  useEffect(() => {
    const browserIframe = iframe.current;

    if (browserIframe) {
      const docElement = browserIframe.contentWindow.document.documentElement;
      if (docElement) {
        browserIframe.style.height = docElement.scrollHeight + 'px';
        docElement.style.overflow = 'hidden';
      }
    }
  }, [iframe, device]);

  OnMount(() => {
    // check height on an interval to have iframe reflect height of content
    setInterval(() => {
      const iframeEl = document.getElementById('editorIframe') as HTMLIFrameElement;
      if (iframeEl && iframeEl.contentWindow.document.documentElement) {
        const child = iframeEl.contentWindow.document.documentElement.querySelector('div');
        iframeEl.style.height = child ? child.scrollHeight + 'px' : '0px';
      }
    }, 1000);
    // delay for iframe to process content, no javascript events that properly listen for this event
    setTimeout(() => {
      loadCss();
    }, 500);
  });

  useEffect(() => {
    const iframeDocument = iframe.current.contentDocument || iframe.current.contentWindow.document;
    if (iframeDocument && iframeDocument.body) {
      iframeDocument.body.setAttribute('data-domain', activeSite.full_domain);
    }
    if (stylesLoaded) {
      // slight delay needed for iframe to process header additions before showing content
      setTimeout(() => {
        props.setIFrameLoaded();
      }, 100);
    }
  }, [stylesLoaded]);

  return (
    <>
      <Frame
        onClick={(e) => e.stopPropagation()}
        style={{
          width,
          borderRadius: device !== 'desktop' ? 16 : 0,
          marginTop: device !== 'desktop' ? 48 : 0,
          marginBottom: device !== 'desktop' ? 48 : 0,
          ...props.style,
        }}
        id={'editorIframe'}
        title="iframe"
        // @ts-ignore
        ref={iframe}
        width={width}
        className={classnames(styles.editorIFrame, { [styles.noHeader]: noHeader && device !== 'desktop' })}
        data-test-id="editorIframe"
      >
        <style>
          {`
          html{
            overflow: hidden;
          }
          body{
            margin: 0;
            background: transparent !important;
          }
          #spark{
            background: white;
            margin-top: ${noHeader && device !== 'desktop' ? '20px' : '0'};
          }`}
        </style>
        <FrameBindingContext>{stylesLoaded && props.children}</FrameBindingContext>
      </Frame>
    </>
  );
};
