import React, { useState } from 'react';
import { set, del, insert, push, assign } from 'object-path-immutable';
import classnames from 'classnames';
import times from 'lodash/times';
import { Tray, Label, TextInput, Button } from '../../../../../../Editor/Toolbars2/Toolbar2';
import Drawer from 'components/Editor/AddSection/Drawer';
import useUrlSite from '../../../../../../../hooks/useUrlSite';
import { useSelector } from 'react-redux';
import { selectHasPermission } from 'reducers/policyReducer';
import {
  IconCarousel,
  IconAnimateUp,
  IconAnimateRight,
  IconAnimateDown,
  IconAnimateLeft,
  IconAnimateZoomIn,
} from '../../../../../../Editor/Toolbars2/icons';
import { ReactComponent as SvgIconClose } from '../../../../../../../assets/images/icon-cancel.svg';
import { ReactComponent as SvgIconAdd } from '../../../../../../../assets/images/icon-add-section.svg';

import styles from './Toolbar.module.scss';
import { SectionHandlersInterface } from 'components/unstack-components/types';

type Props = {
  sectionHandlers: SectionHandlersInterface;
  hideIcon?: boolean;
};

export default function Carousel(props: Props) {
  const {
    sectionHandlers: {
      containerIndex,
      containerLength,
      initializeCarousel,
      type,
      isProductComponent,
      onContainerIndexChange,
      addCarouselChild,
      removeCarouselChild,
      updateContainerProps,
      container,
      componentName,
      isBlogFooter,
      isDeleted,
      isActive,
    },
    hideIcon,
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const hasPermission = useSelector(selectHasPermission);
  const hasAccessToEditComponent = hasPermission('Component:create');
  const urlSite = useUrlSite();
  const { component } = container;

  const deprecatedDataTip = `<div class="${styles.deprecatedToolTip}">
    Elastic Path Studio has deprecated this
    component. It will continue to
    work as before, but won't
    receive bug fixes or updates.
  </div>`;

  const openComponentEditor = () => {
    if (component) {
      window.open(`/${urlSite}/sections/${component}`, '_blank');
    }
  };

  if (type === 'carousel' && !!containerLength) {
    const { delay = 3, animation = 'carousel-right-left', transition_time = 1 } = container;
    return (
      <>
        <div
          className={classnames(styles.absoluteLeft, {
            isActive: isActive,
          })}
          data-test-id="slide-options"
        >
          <span className={styles.sectionLabel} style={{ cursor: 'default' }}>
            {componentName}
          </span>
          {isDeleted && (
            <span data-tip={deprecatedDataTip} data-html={true} className={styles.deprecated}>
              DEPRECATED
            </span>
          )}
          {times(containerLength, (i) => (
            <div
              data-test-id="slide"
              onClick={(e) => onContainerIndexChange(i)}
              className={classnames({
                [styles.activeSlide]: i === containerIndex,
              })}
              key={i}
            >
              Slide {i + 1}{' '}
              {Boolean(i === containerIndex && containerLength > 1) && (
                <SvgIconClose
                  onClick={() => {
                    if (window.confirm('Are you sure you want to delete this slide? This cannot be undone'))
                      removeCarouselChild(containerIndex);
                  }}
                  height={8}
                  width={15}
                />
              )}
            </div>
          ))}
          {containerLength < 5 && (
            <SvgIconAdd
              data-test-id="add-carousel-slide"
              className={styles.newSlide}
              width={15}
              height={15}
              onClick={() => setIsOpen(true)}
            />
          )}
        </div>
        <Tray
          renderIcon={(trayIsOpen: boolean) => (
            <IconCarousel width={15} height={15} active={trayIsOpen} data-test-id="carousel-container-icon" />
          )}
          trayLabel="Carousel"
          renderTray={() => (
            <>
              <TextInput
                id="transition_delay_input"
                data-test-id="transition-delay-input"
                className={styles.TextInput}
                name="delay"
                value={delay}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  updateContainerProps(set(container, 'delay', e.target.value))
                }
                autoFocus
                border
              />
              <Label htmlFor="transition_delay_input">Transition delay</Label>
              <Button
                onClick={() => updateContainerProps(set(container, 'animation', 'carousel-bottom-top'))}
                data-test-id="bottom-top-animate"
              >
                <IconAnimateUp active={animation === 'carousel-bottom-top'} />
              </Button>
              <Button
                onClick={() => updateContainerProps(set(container, 'animation', 'carousel-left-right'))}
                data-test-id="left-right-animate"
              >
                <IconAnimateRight active={animation === 'carousel-left-right'} />
              </Button>
              <Button
                onClick={() => updateContainerProps(set(container, 'animation', 'carousel-top-bottom'))}
                data-test-id="top-bottom-animate"
              >
                <IconAnimateDown active={animation === 'carousel-top-bottom'} />
              </Button>
              <Button
                onClick={() => updateContainerProps(set(container, 'animation', 'carousel-right-left'))}
                data-test-id="right-left-animate"
              >
                <IconAnimateLeft active={animation === 'carousel-right-left'} />
              </Button>
              <Button
                onClick={() => updateContainerProps(set(container, 'animation', 'carousel-zoom-in'))}
                data-test-id="zoom-in-animate"
              >
                <IconAnimateZoomIn active={animation === 'carousel-zoom-in'} />
              </Button>
              <TextInput
                id="transition_time_input"
                data-test-id="transition-time-input"
                className={styles.TextInput}
                name="transition_time"
                value={transition_time}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  updateContainerProps(set(container, 'transition_time', e.target.value))
                }
                autoFocus
                border
              />
              <Label htmlFor="transition_time_input">Transition time</Label>
            </>
          )}
        />
        <Drawer
          isOpen={isOpen}
          close={() => {
            setIsOpen(false);
          }}
          addSection={(type: string, section: string) => {
            addCarouselChild(type, section);
            setIsOpen(false);
          }}
          isCarousel
          {...(isBlogFooter ? { allowedTypes: ['text', 'custom', 'cta'] } : {})}
        />
      </>
    );
  } else {
    return (
      <>
        {hasAccessToEditComponent ? (
          <div
            className={classnames(styles.absoluteLeft, {
              isActive: isActive,
            })}
            data-tip="Edit component"
            data-html={true}
            data-place="top"
            onClick={openComponentEditor}
          >
            <span
              className={classnames(styles.sectionLabel, {
                [styles.componentLabel]: componentName.length <= 10,
              })}
            >
              {componentName}
            </span>

            {isDeleted && (
              <span data-tip={deprecatedDataTip} data-html={true} className={styles.deprecated}>
                DEPRECATED
              </span>
            )}
          </div>
        ) : (
          <div
            className={classnames(styles.absoluteLeft, {
              isActive: isActive,
            })}
          >
            <span className={styles.sectionLabel} style={{ cursor: 'default' }}>
              {componentName}
            </span>
            {isDeleted && (
              <span data-tip={deprecatedDataTip} data-html={true} className={styles.deprecated}>
                DEPRECATED
              </span>
            )}
          </div>
        )}
        {!hideIcon && (
          <Button
            disabled={type === 'carousel' || !!containerLength || isProductComponent}
            data-test-id="carousel-container-icon"
            onClick={() => initializeCarousel()}
          >
            <IconCarousel
              data-tip={isProductComponent ? 'Carousels for E-Commerce components coming soon' : 'Carousel'}
              width={15}
              height={45}
            />
          </Button>
        )}
      </>
    );
  }
}
