import React from 'react';

interface IProps {
  service?: string;
  facetsList: string;
}

const SearchFilter: React.FunctionComponent<IProps> = (props) => {
  if (props.service === 'algolia') return null;
  return props.service === 'graphenehc' ? (
    <div
      data-graphenehc
      data-graphenehc-search-filter
      data-widget="refinement"
      data-facets-list={props.facetsList}
    ></div>
  ) : (
    <div data-klevu data-klevu-search-filter data-widget="refinement"></div>
  );
};

export default SearchFilter;
