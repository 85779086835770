import FormControl from 'components/base/FormHelpers/FormControl';
import withDataTables from 'containers/withDataTables';
import { compose } from 'redux';
import { UDatatable } from 'types/UDataTable';
import { UPage } from 'types/UPage';

type Props = {
  page: UPage;
  handleChange: () => void;
  tables: UDatatable[];
};

export function DatatableControl({ page, handleChange, tables }: Props) {
  return (
    <fieldset>
      <label>Datatable</label>
      <FormControl tag="select" type="text" name="data_table" value={page.data_table} onChange={handleChange}>
        {tables.map((table) => (
          <option value={table.id}>{table.slug}</option>
        ))}
      </FormControl>
    </fieldset>
  );
}

export default compose(withDataTables)(DatatableControl);
