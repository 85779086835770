import React, { ChangeEvent, FormEvent } from 'react';
import { Link } from 'react-router-dom';

import createAccountStyles from './CreateAccount.module.scss';
import createSiteFormStyles from './CreateSite.module.scss';

function CreateSiteForm({
  handleSubmit,
  handleChange,
  domain,
  error,
  email,
}: {
  handleSubmit: (e: FormEvent<HTMLFormElement>) => void;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  domain: string;
  error?: boolean;
  email?: string;
}) {
  return (
    <form className={createAccountStyles.form} onSubmit={handleSubmit}>
      <h3>
        <strong>Add a new site</strong>
      </h3>
      <p className={createSiteFormStyles.formDescription}>
        This will create a new Elastic Path Studio site and account linked to <strong>{email}</strong>. Need a
        different? <Link to="/logout">Log out</Link>
      </p>
      <fieldset>
        <input
          autoFocus
          id="domain_input"
          name="domain"
          aria-label="domain"
          value={domain}
          type="text"
          onChange={handleChange}
          className={error ? 'fieldWithErrors' : ''}
          placeholder="Domain name e.g. example.com"
        />
        {error && <small className="errorMessage">{error}</small>}
      </fieldset>
      <input type="submit" className="button button-success button-full disable-silently" value={'Create new site'} />
      <small className={createAccountStyles.terms}>
        By creating an Elastic Path Studio account you agree to its{' '}
        <a href="https://www.unstack.com/terms-of-use/" target="_blank" rel="noopener noreferrer">
          Terms of Service
        </a>{' '}
        and{' '}
        <a href="https://www.unstack.com/privacy-policy/" target="_blank" rel="noopener noreferrer">
          Privacy Policy
        </a>
      </small>
    </form>
  );
}

export default CreateSiteForm;
