import React, { useEffect, useMemo, useState } from 'react';
import { prettifyCode } from 'lazy/prettier';
import { useSelector } from 'react-redux';
import * as siteReducer from 'reducers/sitesReducer';

import { ReinitPlugins } from '../../PluginsRefactored';

interface IProps {
  content: any;
  dataRef: { [key: string]: string };
  service?: string;
  item: any;
  'data-styles': string;
  componentId: string;
  properties: any;
  ['data-product-id']: string;
  style: any;
}

const PROPERTIES_MAP: { [key: string]: string[] } = {
  klevu: ['query_function', 'params', 'queryParam', 'kmcId', 'kmcLogic'],
};

//  @ts-ignore
const SearchResults: React.FunctionComponent<IProps> = (props) => {
  let layoutComponent = useSelector((state: any) => {
    return state?.sectionTypes?.resources ? state?.sectionTypes?.resources[props.componentId] : undefined;
  });

  const [itemTemplate, setItemTemplate] = useState<string>();
  const [emptyTemplate, setEmptyTemplate] = useState<string>();
  const [productData, setProductData] = useState(false);
  const fullDomain = useSelector(siteReducer.selectSiteFullDomain);

  const service = props.service || 'klevu';

  useEffect(() => {
    if (layoutComponent) {
      prettifyCode(layoutComponent.jsx).then((formattedCode) => {
        const cleanedTemplateString = formattedCode.replace(/\{[^{}]*&&\s*\(([\s\S]*?)\)\s*\}/g, (_, group1) =>
          group1.trim()
        );
        const regex = /<SearchResults[^>]*\s+item=\{[^}]*\(([^)]*)\)\s*=>\s*\(([\s\S]*?)\)\s*\}/;
        const regexEmpty = /<SearchResults[^>]*\s+empty=\{[^}]*\(([^)]*)\)\s*=>\s*\(([\s\S]*?)\)\s*\}/;
        const matchItem = cleanedTemplateString.match(regex);
        const matchEmpty = cleanedTemplateString.match(regexEmpty);
        if (props['data-product-id'] && searchPlugin !== 'data-graphenehc') return;
        if (matchItem) {
          setItemTemplate(matchItem[2].trim());
        }
        if (matchEmpty) {
          setEmptyTemplate(matchEmpty[2].trim());
        }
      });
    }
    setTimeout(() => {
      setProductData(
        (window as any).sprk?.UPluginManager?.instances?.searchRecommendation?.instances?.[0]?.latestData?.products
          ?.length
      );
    }, 1000);
  }, [props.content, layoutComponent]);

  // const propertiesToUse: string[] = PROPERTIES_MAP[service];
  const dataAttr: { [key: string]: string } = useMemo(() => {
    return Object.entries(props)
      .filter(([key, _value]) => key.includes('data-'))
      .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
  }, [props]);

  const searchPlugin = dataAttr['data-search-recommendation'] !== undefined ? '' : `data-${service}`;

  useEffect(() => {
    if (itemTemplate && fullDomain) {
      ReinitPlugins();
    }
  }, [itemTemplate, fullDomain]);

  const mappedStyles: string =
    Object.keys(props.style || {})
      .map((style) => `${style}: ${props.style[style]}`)
      .join(' !important;') + ' !important';
  return itemTemplate && fullDomain ? (
    <>
      <style>
        {`
          .search-results.products {
            ${mappedStyles}
          }
      `}
      </style>
      <div
        {...{ [`data-${service}-search-results`]: '' }}
        className="search-results products"
        data-item-template="editor__search_results"
        data-full-domain={fullDomain}
        data-widget="hits"
        {...{ [searchPlugin]: '' }}
        {...dataAttr}
      />
      {!productData && service === 'klevu' && (
        <div className="products">
          <div className="products-generator">
            {[...Array(4)].map(() => (
              <span className="product-container">
                <a className="product" href="">
                  <div className="product-image">
                    <svg
                      className="undefined unstack-media"
                      data-label="media-placeholder"
                      role="img"
                      viewBox="0 0 680 680"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g>
                        <rect width="680" height="680" x="0" y="0" fill="#eefaff" stroke="#5bc0eb"></rect>
                        <text
                          x="340"
                          y="340"
                          fill="#5bc0eb"
                          text-anchor="middle"
                          font-family="sans-serif"
                          font-size="0"
                        >
                          <tspan>image</tspan>
                        </text>
                      </g>
                    </svg>
                    <svg
                      className="undefined unstack-media"
                      data-label="media-placeholder"
                      role="img"
                      viewBox="0 0 680 680"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g>
                        <rect width="680" height="680" x="0" y="0" fill="#eefaff" stroke="#5bc0eb"></rect>
                        <text
                          x="340"
                          y="340"
                          fill="#5bc0eb"
                          text-anchor="middle"
                          font-family="sans-serif"
                          font-size="0"
                        >
                          <tspan>image</tspan>
                        </text>
                      </g>
                    </svg>
                  </div>
                  <span className="product-details">
                    <span className="product-title"></span>
                    <span className="product-price price">
                      <div className="product-price-container">
                        <p className="label" data-product-price="true"></p>
                      </div>
                    </span>
                  </span>
                </a>
              </span>
            ))}
          </div>
        </div>
      )}
      <template
        id="editor__search_results"
        dangerouslySetInnerHTML={{
          __html: `<div data-template-generator="" 
          data-ref="content.${service === 'algolia' ? 'searchResults' : 'results'}" data-item-ref="${
            service === 'algolia' ? 'hit' : 'result'
          }"" data-item-template="editor__search_result" data-empty-template="editor__search_result_empty"></div>`,
        }}
      ></template>
      <template
        id="editor__search_result"
        dangerouslySetInnerHTML={{
          __html: itemTemplate,
        }}
      ></template>
      {/* <template
        id="editor__search_result_empty"
        dangerouslySetInnerHTML={{
          __html: emptyTemplate,
        }}
      ></template> */}
    </>
  ) : null;
};

export default SearchResults;
