import React, { useMemo, useState } from 'react';
import classnames from 'classnames';

import Select from './Select';
import ShadowInput from './ShadowInput';
import ColorSelector from './StyleGuideEditor/ColorSelector';
import CapitalizeSelect from './CapitalizeSelect';
import LetterSpacingField from './LetterSpacingField';

import { ReactComponent as SvgIconDesktopThumbnail } from '../../../assets/images/icon-desktop-styleguide.svg';
import { ReactComponent as SvgIconMobileThumbnail } from '../../../assets/images/icon-mobile-styleguide.svg';

import styles from './StyleGuideEditor.module.scss';

export default ({
  fontFamilyOptions,
  styleGuide,
  fontWeightOptions,
  handleChange,
  handleChangeEvent,
  darkBgColor,
  name,
  ...props
}) => {
  const [activeStyleTabs, setActiveStyleTabs] = useState({
    primary: 'text',
    secondary: 'text',
    actionLink: 'text',
  });

  const [activeBackground, setActiveBackground] = useState({
    primary: 'light',
    secondary: 'light',
    actionLink: 'light',
  });

  return (
    <>
      <header ref={props.scrollRef} className={styles.topSpacing}>
        <h1>Buttons</h1>
        <small>Set the button styles used across your site.</small>
      </header>

      {/* Primary Button */}
      <article className={styles.StyleGuideEditor}>
        <section className={styles.styleSection}>
          <div className={styles.styleSectionHeader}>
            <p>Primary button</p>
            <div className={styles.bgToggles}>
              <div className={styles.background}>
                <span
                  className={classnames(styles.lightBackground, {
                    [styles.active]: activeBackground.primary === 'light',
                  })}
                  onClick={() => setActiveBackground({ ...activeBackground, primary: 'light' })}
                >
                  Light
                </span>
                <span
                  className={classnames(styles.darkBackground, {
                    [styles.active]: activeBackground.primary === 'dark',
                  })}
                  onClick={() => setActiveBackground({ ...activeBackground, primary: 'dark' })}
                >
                  Dark
                </span>
              </div>

              <div className={styles.device}>
                <span
                  onClick={() => setActiveBackground({ ...activeBackground, primary: 'light' })}
                  className={classnames(styles.lightBackground, {
                    [styles.active]: activeBackground.primary === 'light',
                  })}
                >
                  <SvgIconDesktopThumbnail />
                </span>
                <span
                  onClick={() => {
                    setActiveBackground({ ...activeBackground, primary: 'mobile' });
                    if (activeStyleTabs.primary === 'hover')
                      setActiveStyleTabs({ ...activeStyleTabs, primary: 'text' });
                  }}
                  className={classnames(styles.darkBackground, {
                    [styles.active]: activeBackground.primary === 'mobile',
                  })}
                >
                  <SvgIconMobileThumbnail />
                </span>
              </div>
            </div>
          </div>
          <div
            style={{
              ...(activeBackground.primary === 'dark' && {
                backgroundColor: darkBgColor.code,
                padding: '1px 0 0px 10px',
              }),
            }}
          >
            <div className="eps sriracha">
              <div
                className={`button button-primary ${
                  activeBackground.primary === 'dark'
                    ? 'dark-bg'
                    : activeBackground.primary === 'mobile'
                    ? 'mobile'
                    : ''
                }`}
              >
                Primary action
              </div>
            </div>
          </div>
          <div className={styles.optionsHeader}>
            <span
              onClick={() => setActiveStyleTabs({ ...activeStyleTabs, primary: 'text' })}
              className={classnames({
                [styles.activeStyleTab]: activeStyleTabs.primary === 'text',
              })}
            >
              Text
            </span>
            <span
              onClick={() => setActiveStyleTabs({ ...activeStyleTabs, primary: 'shape' })}
              className={classnames({
                [styles.activeStyleTab]: activeStyleTabs.primary === 'shape',
              })}
            >
              Shape
            </span>
            <span
              onClick={() => setActiveStyleTabs({ ...activeStyleTabs, primary: 'border' })}
              className={classnames({
                [styles.activeStyleTab]: activeStyleTabs.primary === 'border',
              })}
            >
              Border
            </span>
            {activeBackground.primary !== 'mobile' && (
              <span
                onClick={() => setActiveStyleTabs({ ...activeStyleTabs, primary: 'hover' })}
                className={classnames({
                  [styles.activeStyleTab]: activeStyleTabs.primary === 'hover',
                })}
              >
                Hover
              </span>
            )}
          </div>
          <div className={styles.styleSectionOptions}>
            {activeStyleTabs.primary === 'text' ? (
              activeBackground.primary === 'light' ? (
                <>
                  <div className={styles.styleOption}>
                    <span>Font family</span>
                    <Select
                      options={fontFamilyOptions}
                      defaultValue={fontFamilyOptions[styleGuide.primary_button_font_family_index]}
                      value={fontFamilyOptions[styleGuide.primary_button_font_family_index]}
                      onChange={(option) => handleChange(['primary', 'font_family_index'], option.value)}
                    />
                  </div>
                  <div className={styles.styleOption}>
                    <span>Font Weight</span>
                    <Select
                      options={fontWeightOptions}
                      defaultValue={fontWeightOptions.find(
                        (weight) => weight.value === Number(styleGuide.primary_button_font_weight)
                      )}
                      value={fontWeightOptions.find(
                        (weight) => weight.value === Number(styleGuide.primary_button_font_weight)
                      )}
                      onChange={(option) => handleChange(['primary', 'font_weight'], option.value)}
                    />
                  </div>
                  <div className={styles.styleOption}>
                    <span>Font size</span>
                    <input
                      type="number"
                      name="primary_button_font_size"
                      value={styleGuide.primary_button_font_size}
                      min="0.8"
                      max="2.5"
                      step="0.1"
                      onChange={(e) => handleChangeEvent(e, ['primary', 'font_size'])}
                    />
                  </div>
                  <CapitalizeSelect
                    value={styleGuide.primary_button_text_transform}
                    handleChange={(value) => handleChange(['primary', 'text_transform'], value)}
                  />
                  <LetterSpacingField
                    name="primary_button_letter_spacing"
                    value={styleGuide.primary_button_letter_spacing}
                    handleChange={(value) => handleChange(['primary', 'letter_spacing'], value)}
                  />
                  <div className={classnames(styles.styleOption)}>
                    <span>Text Shadow</span>
                    <ShadowInput
                      value={styleGuide.primary_button_text_shadow}
                      handleChange={handleChange}
                      handleChangeEvent={handleChangeEvent}
                      name="primary_button_text_shadow"
                      path={['primary', 'text_shadow']}
                    />
                  </div>
                  <div className={styles.styleOption}>
                    <span>Text color</span>
                    <ColorSelector
                      name="primary_button_text_color"
                      onChange={(value) => handleChange(['primary', 'text_color'], value)}
                      value={styleGuide.primary_button_text_color}
                      placeholder="Color"
                      handleColorSelection={(value) => handleChange(['primary', 'text_color'], value)}
                    />
                  </div>
                </>
              ) : activeBackground.primary === 'dark' ? (
                <>
                  <div className={classnames(styles.styleOption, styles.extendWidth)}>
                    <span>Text Shadow</span>
                    <ShadowInput
                      value={styleGuide.primary_button_dark_bg_text_shadow}
                      handleChange={handleChange}
                      handleChangeEvent={handleChangeEvent}
                      name="primary_button_dark_bg_text_shadow"
                      path={['primary', 'dark_bg_text_shadow']}
                    />
                  </div>
                  <div className={styles.styleOption}>
                    <span>Text color</span>
                    <ColorSelector
                      name="primary_button_dark_bg_text_color"
                      onChange={(value) => handleChange(['primary', 'dark_bg_text_color'], value)}
                      value={styleGuide.primary_button_dark_bg_text_color}
                      placeholder="Color"
                      handleColorSelection={(value) => handleChange(['primary', 'dark_bg_text_color'], value)}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className={styles.styleOption}>
                    <span>Font size</span>
                    <input
                      type="number"
                      name="primary_button_font_size_mobile"
                      value={styleGuide.primary_button_font_size_mobile}
                      min="0.8"
                      max="2.5"
                      step="0.1"
                      onChange={(e) => handleChangeEvent(e, ['primary', 'mobile', 'font_size'])}
                    />
                  </div>
                  <LetterSpacingField
                    name="primary_button_letter_spacing_mobile"
                    value={styleGuide.primary_button_letter_spacing_mobile || styleGuide.primary_button_letter_spacing}
                    handleChange={(value) => handleChange(['primary', 'mobile', 'letter_spacing'], value)}
                  />
                </>
              )
            ) : null}
            {activeStyleTabs.primary === 'shape' ? (
              activeBackground.primary === 'light' ? (
                <>
                  <div className={styles.styleOption}>
                    <span>Background color</span>
                    <ColorSelector
                      name="primary_button_box_background_color"
                      onChange={(value) => handleChange(['primary', 'box_background_color'], value)}
                      value={styleGuide.primary_button_box_background_color}
                      placeholder="Color"
                      handleColorSelection={(value) => handleChange(['primary', 'box_background_color'], value)}
                    />
                  </div>
                  <div className={styles.styleOption}>
                    <span>Height</span>
                    <input
                      type="number"
                      name="primary_button_box_line_height"
                      value={styleGuide.primary_button_box_line_height}
                      min="1"
                      max="2.5"
                      step="0.1"
                      onChange={(e) => handleChangeEvent(e, ['primary', 'box_line_height'])}
                    />
                  </div>
                  <div className={classnames(styles.styleOption, styles.extendWidth)}>
                    <span>Shadow</span>
                    <ShadowInput
                      value={styleGuide.primary_button_box_shadow}
                      handleChange={handleChange}
                      handleChangeEvent={handleChangeEvent}
                      name="primary_button_box_shadow"
                      path={['primary', 'box_shadow']}
                    />
                  </div>
                </>
              ) : activeBackground.primary === 'dark' ? (
                <>
                  <div className={styles.styleOption}>
                    <span>Background color</span>
                    <ColorSelector
                      name="primary_button_dark_bg_box_background_color"
                      onChange={(value) => handleChange(['primary', 'dark_bg_box_background_color'], value)}
                      value={styleGuide.primary_button_dark_bg_box_background_color}
                      placeholder="Color"
                      handleColorSelection={(value) => handleChange(['primary', 'dark_bg_box_background_color'], value)}
                    />
                  </div>
                  <div className={classnames(styles.styleOption, styles.extendWidth)}>
                    <span>Box shadow</span>
                    <ShadowInput
                      value={styleGuide.primary_button_dark_bg_box_shadow}
                      handleChange={handleChange}
                      handleChangeEvent={handleChangeEvent}
                      name="primary_button_dark_bg_box_shadow"
                      path={['primary', 'dark_bg_box_shadow']}
                    />
                  </div>
                </>
              ) : (
                <div className={styles.styleOption}>
                  <span>Height</span>
                  <input
                    type="number"
                    name="primary_button_box_line_height_mobile"
                    value={
                      styleGuide.primary_button_box_line_height_mobile || styleGuide.primary_button_box_line_height
                    }
                    min="1"
                    max="2.5"
                    step="0.1"
                    onChange={(e) => handleChangeEvent(e, ['primary', 'mobile', 'box_line_height'])}
                  />
                </div>
              )
            ) : null}
            {activeStyleTabs.primary === 'border' ? (
              activeBackground.primary === 'light' ? (
                <>
                  <div className={styles.styleOption}>
                    <span>Border Width</span>
                    <input
                      type="number"
                      name="primary_button_box_border_width"
                      value={styleGuide.primary_button_box_border_width}
                      min="0"
                      max="5"
                      step="1"
                      onChange={(e) => handleChangeEvent(e, ['primary', 'box_border_width'])}
                    />
                  </div>
                  <div className={styles.styleOption}>
                    <span>Border Color</span>
                    <ColorSelector
                      name="primary_button_box_border_color"
                      onChange={(value) => handleChange(['primary', 'box_border_color'], value)}
                      value={styleGuide.primary_button_box_border_color}
                      placeholder="Color"
                      handleColorSelection={(value) => handleChange(['primary', 'box_border_color'], value)}
                    />
                  </div>
                  <div className={styles.styleOption}>
                    <span>Border Radius</span>
                    <input
                      type="number"
                      name="primary_button_box_border_radius"
                      value={styleGuide.primary_button_box_border_radius}
                      min="0"
                      step="1"
                      onChange={(e) => handleChangeEvent(e, ['primary', 'box_border_radius'])}
                    />
                  </div>
                </>
              ) : activeBackground.primary === 'dark' ? (
                <>
                  <div className={styles.styleOption}>
                    <span>Border Width</span>
                    <input
                      type="number"
                      name="primary_button_dark_bg_box_border_width"
                      value={styleGuide.primary_button_dark_bg_box_border_width}
                      min="0"
                      max="5"
                      step="1"
                      onChange={(e) => handleChangeEvent(e, ['primary', 'dark_bg_box_border_width'])}
                    />
                  </div>
                  <div className={styles.styleOption}>
                    <span>Border Color</span>
                    <ColorSelector
                      name="primary_button_dark_bg_box_border_color"
                      onChange={(value) => handleChange(['primary', 'dark_bg_box_border_color'], value)}
                      value={styleGuide.primary_button_dark_bg_box_border_color}
                      placeholder="Color"
                      handleColorSelection={(value) => handleChange(['primary', 'dark_bg_box_border_color'], value)}
                    />
                  </div>
                </>
              ) : (
                <div className={styles.styleOption}>
                  <span>Border Radius</span>
                  <input
                    type="number"
                    name="primary_button_box_border_radius_mobile"
                    value={
                      styleGuide.primary_button_box_border_radius_mobile || styleGuide.primary_button_box_border_radius
                    }
                    min="0"
                    step="1"
                    onChange={(e) => handleChangeEvent(e, ['primary', 'mobile', 'box_border_radius'])}
                  />
                </div>
              )
            ) : null}
            {activeStyleTabs.primary === 'hover' && activeBackground.primary !== 'mobile' && (
              <>
                <div className={styles.styleOption}>
                  <span>Background Color</span>
                  <ColorSelector
                    name={
                      activeBackground.primary === 'dark'
                        ? 'primary_button_dark_bg_hover_background_color'
                        : 'primary_button_hover_background_color'
                    }
                    onChange={(value) =>
                      handleChange(
                        [
                          'primary',
                          ...(activeBackground.primary === 'dark'
                            ? ['dark_bg_hover_background_color']
                            : ['hover_background_color']),
                        ],
                        value
                      )
                    }
                    value={
                      activeBackground.primary === 'dark'
                        ? styleGuide.primary_button_dark_bg_hover_background_color
                        : styleGuide.primary_button_hover_background_color
                    }
                    placeholder="Color"
                    handleColorSelection={(value) =>
                      handleChange(
                        [
                          'primary',
                          ...(activeBackground.primary === 'dark'
                            ? ['dark_bg_hover_background_color']
                            : ['hover_background_color']),
                        ],
                        value
                      )
                    }
                  />
                </div>
                <div className={styles.styleOption}>
                  <span>Border Color</span>
                  <ColorSelector
                    name={
                      activeBackground.primary === 'dark'
                        ? 'primary_button_dark_bg_hover_border_color'
                        : 'primary_button_hover_border_color'
                    }
                    onChange={(value) =>
                      handleChange(
                        [
                          'primary',
                          ...(activeBackground.primary === 'dark'
                            ? ['dark_bg_hover_border_color']
                            : ['hover_border_color']),
                        ],
                        value
                      )
                    }
                    value={
                      activeBackground.primary === 'dark'
                        ? styleGuide.primary_button_dark_bg_hover_border_color
                        : styleGuide.primary_button_hover_border_color
                    }
                    placeholder="Color"
                    handleColorSelection={(value) =>
                      handleChange(
                        [
                          'primary',
                          ...(activeBackground.primary === 'dark'
                            ? ['dark_bg_hover_border_color']
                            : ['hover_border_color']),
                        ],
                        value
                      )
                    }
                  />
                </div>
                <div className={styles.styleOption}>
                  <span>Text color</span>
                  <ColorSelector
                    name={
                      activeBackground.primary === 'dark'
                        ? 'primary_button_dark_bg_hover_text_color'
                        : 'primary_button_hover_text_color'
                    }
                    onChange={(value) =>
                      handleChange(
                        [
                          'primary',
                          ...(activeBackground.primary === 'dark'
                            ? ['dark_bg_hover_text_color']
                            : ['hover_text_color']),
                        ],
                        value
                      )
                    }
                    value={
                      activeBackground.primary === 'dark'
                        ? styleGuide.primary_button_dark_bg_hover_text_color
                        : styleGuide.primary_button_hover_text_color
                    }
                    placeholder="Color"
                    handleColorSelection={(value) =>
                      handleChange(
                        [
                          'primary',
                          ...(activeBackground.primary === 'dark'
                            ? ['dark_bg_hover_text_color']
                            : ['hover_text_color']),
                        ],
                        value
                      )
                    }
                  />
                </div>
                <div className={classnames(styles.styleOption, styles.extendWidth)}>
                  <span>Text Shadow</span>
                  <ShadowInput
                    value={
                      activeBackground.primary === 'dark'
                        ? styleGuide.primary_button_dark_bg_hover_text_shadow
                        : styleGuide.primary_button_hover_text_shadow
                    }
                    handleChange={handleChange}
                    handleChangeEvent={handleChangeEvent}
                    name={
                      activeBackground.primary === 'dark'
                        ? 'primary_button_dark_bg_hover_text_shadow'
                        : 'primary_button_hover_text_shadow'
                    }
                    path={[
                      'primary',
                      ...(activeBackground.primary === 'dark' ? ['dark_bg_hover_text_shadow'] : ['hover_text_shadow']),
                    ]}
                  />
                </div>
                <div className={classnames(styles.styleOption, styles.extendWidth)}>
                  <span>Shadow</span>
                  <ShadowInput
                    value={
                      activeBackground.primary === 'dark'
                        ? styleGuide.primary_button_dark_bg_hover_shadow
                        : styleGuide.primary_button_hover_shadow
                    }
                    handleChange={handleChange}
                    handleChangeEvent={handleChangeEvent}
                    name={
                      activeBackground.primary === 'dark'
                        ? 'primary_button_dark_bg_hover_shadow'
                        : 'primary_button_hover_shadow'
                    }
                    path={[
                      'primary',
                      ...(activeBackground.primary === 'dark' ? ['dark_bg_hover_shadow'] : ['hover_shadow']),
                    ]}
                  />
                </div>
                <div className={styles.styleOption}>
                  <span>Opacity</span>
                  <input
                    type="number"
                    name={
                      activeBackground.primary === 'dark'
                        ? 'primary_button_dark_bg_hover_opacity'
                        : 'primary_button_hover_opacity'
                    }
                    value={
                      activeBackground.primary === 'dark'
                        ? styleGuide.primary_button_dark_bg_hover_opacity
                        : styleGuide.primary_button_hover_opacity
                    }
                    min="0"
                    max="1"
                    step="0.05"
                    onChange={(e) =>
                      handleChangeEvent(e, [
                        'primary',
                        ...(activeBackground.primary === 'dark' ? ['dark_bg_hover_opacity'] : ['hover_opacity']),
                      ])
                    }
                  />
                </div>
              </>
            )}
          </div>
        </section>
      </article>

      {/*   Secondary Button    */}
      <article className={styles.StyleGuideEditor}>
        <section className={styles.styleSection}>
          <div className={styles.styleSectionHeader}>
            <p>Secondary button</p>
            <div className={styles.bgToggles}>
              <div className={styles.background}>
                <span
                  className={classnames(styles.lightBackground, {
                    [styles.active]: activeBackground.secondary === 'light',
                  })}
                  onClick={() => setActiveBackground({ ...activeBackground, secondary: 'light' })}
                >
                  Light
                </span>
                <span
                  className={classnames(styles.darkBackground, {
                    [styles.active]: activeBackground.secondary === 'dark',
                  })}
                  onClick={() => setActiveBackground({ ...activeBackground, secondary: 'dark' })}
                >
                  Dark
                </span>
              </div>

              <div className={styles.device}>
                <span
                  onClick={() => setActiveBackground({ ...activeBackground, secondary: 'light' })}
                  className={classnames(styles.lightBackground, {
                    [styles.active]: activeBackground.secondary === 'light',
                  })}
                >
                  <SvgIconDesktopThumbnail />
                </span>
                <span
                  onClick={() => {
                    setActiveBackground({ ...activeBackground, secondary: 'mobile' });
                    if (activeStyleTabs.secondary === 'hover')
                      setActiveStyleTabs({ ...activeStyleTabs, secondary: 'text' });
                  }}
                  className={classnames(styles.darkBackground, {
                    [styles.active]: activeBackground.secondary === 'mobile',
                  })}
                >
                  <SvgIconMobileThumbnail />
                </span>
              </div>
            </div>
          </div>
          <div
            style={{
              ...(activeBackground.secondary === 'dark' && {
                backgroundColor: darkBgColor.code,
                padding: '1px 0 0px 10px',
              }),
            }}
          >
            <div className="eps sriracha">
              <div
                className={`${
                  activeBackground.secondary === 'dark'
                    ? 'dark-bg '
                    : activeBackground.secondary === 'mobile'
                    ? 'mobile '
                    : ''
                }button`}
              >
                Secondary action
              </div>
            </div>
          </div>
          <div className={styles.optionsHeader}>
            <span
              onClick={() => setActiveStyleTabs({ ...activeStyleTabs, secondary: 'text' })}
              className={classnames({
                [styles.activeStyleTab]: activeStyleTabs.secondary === 'text',
              })}
            >
              Text
            </span>
            <span
              onClick={() => setActiveStyleTabs({ ...activeStyleTabs, secondary: 'shape' })}
              className={classnames({
                [styles.activeStyleTab]: activeStyleTabs.secondary === 'shape',
              })}
            >
              Shape
            </span>
            <span
              onClick={() => setActiveStyleTabs({ ...activeStyleTabs, secondary: 'border' })}
              className={classnames({
                [styles.activeStyleTab]: activeStyleTabs.secondary === 'border',
              })}
            >
              Border
            </span>
            {activeBackground.secondary !== 'mobile' && (
              <span
                onClick={() => setActiveStyleTabs({ ...activeStyleTabs, secondary: 'hover' })}
                className={classnames({
                  [styles.activeStyleTab]: activeStyleTabs.secondary === 'hover',
                })}
              >
                Hover
              </span>
            )}
          </div>
          <div className={styles.styleSectionOptions}>
            {activeStyleTabs.secondary === 'text' ? (
              activeBackground.secondary === 'light' ? (
                <>
                  <div className={styles.styleOption}>
                    <span>Font family</span>
                    <Select
                      options={fontFamilyOptions}
                      defaultValue={fontFamilyOptions[styleGuide.secondary_button_font_family_index]}
                      value={fontFamilyOptions[styleGuide.secondary_button_font_family_index]}
                      onChange={(option) => handleChange(['secondary', 'font_family_index'], option.value)}
                    />
                  </div>
                  <div className={styles.styleOption}>
                    <span>Font Weight</span>
                    <Select
                      options={fontWeightOptions}
                      defaultValue={fontWeightOptions.find(
                        (weight) => weight.value === Number(styleGuide.secondary_button_font_weight)
                      )}
                      value={fontWeightOptions.find(
                        (weight) => weight.value === Number(styleGuide.secondary_button_font_weight)
                      )}
                      onChange={(option) => handleChange(['secondary', 'font_weight'], option.value)}
                    />
                  </div>
                  <div className={styles.styleOption}>
                    <span>Font size</span>
                    <input
                      type="number"
                      name="secondary_button_font_size"
                      value={styleGuide.secondary_button_font_size}
                      min="0.8"
                      max="2.5"
                      step="0.1"
                      onChange={(e) => handleChangeEvent(e, ['secondary', 'font_size'])}
                    />
                  </div>
                  <CapitalizeSelect
                    value={styleGuide.secondary_button_text_transform}
                    handleChange={(value) => handleChange(['secondary', 'text_transform'], value)}
                  />
                  <LetterSpacingField
                    name="secondary_button_letter_spacing"
                    value={styleGuide.secondary_button_letter_spacing}
                    handleChange={(value) => handleChange(['secondary', 'letter_spacing'], value)}
                  />
                  <div className={classnames(styles.styleOption, styles.extendWidth)}>
                    <span>Text Shadow</span>
                    <ShadowInput
                      value={styleGuide.secondary_button_text_shadow}
                      handleChange={handleChange}
                      handleChangeEvent={handleChangeEvent}
                      name="secondary_button_text_shadow"
                      path={['secondary', 'text_shadow']}
                    />
                  </div>
                  <div className={styles.styleOption}>
                    <span>Text color</span>
                    <ColorSelector
                      name="secondary_button_text_color"
                      onChange={(value) => handleChange(['secondary', 'text_color'], value)}
                      value={styleGuide.secondary_button_text_color}
                      placeholder="Color"
                      handleColorSelection={(value) => handleChange(['secondary', 'text_color'], value)}
                    />
                  </div>
                </>
              ) : activeBackground.secondary === 'dark' ? (
                <>
                  <div className={classnames(styles.styleOption)}>
                    <span>Text Shadow</span>
                    <ShadowInput
                      value={styleGuide.secondary_button_dark_bg_text_shadow}
                      handleChange={handleChange}
                      handleChangeEvent={handleChangeEvent}
                      name="secondary_button_dark_bg_text_shadow"
                      path={['secondary', 'dark_bg_text_shadow']}
                    />
                  </div>
                  <div className={styles.styleOption}>
                    <span>Text color</span>
                    <ColorSelector
                      name="secondary_button_dark_bg_text_color"
                      onChange={(value) => handleChange(['secondary', 'dark_bg_text_color'], value)}
                      value={styleGuide.secondary_button_dark_bg_text_color}
                      placeholder="Color"
                      handleColorSelection={(value) => handleChange(['secondary', 'dark_bg_text_color'], value)}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className={styles.styleOption}>
                    <span>Font size</span>
                    <input
                      type="number"
                      name="secondary_button_font_size_mobile"
                      value={styleGuide.secondary_button_font_size_mobile}
                      min="0.8"
                      max="2.5"
                      step="0.1"
                      onChange={(e) => handleChangeEvent(e, ['secondary', 'mobile', 'font_size'])}
                    />
                  </div>
                  <LetterSpacingField
                    name="secondary_button_letter_spacing_mobile"
                    value={
                      styleGuide.secondary_button_letter_spacing_mobile || styleGuide.secondary_button_letter_spacing
                    }
                    handleChange={(value) => handleChange(['secondary', 'mobile', 'letter_spacing'], value)}
                  />
                </>
              )
            ) : null}
            {activeStyleTabs.secondary === 'shape' ? (
              activeBackground.secondary === 'light' ? (
                <>
                  <div className={styles.styleOption}>
                    <span>Background color</span>
                    <ColorSelector
                      name="secondary_button_box_background_color"
                      onChange={(value) => handleChange(['secondary', 'box_background_color'], value)}
                      value={styleGuide.secondary_button_box_background_color}
                      placeholder="Color"
                      handleColorSelection={(value) => handleChange(['secondary', 'box_background_color'], value)}
                    />
                  </div>
                  <div className={styles.styleOption}>
                    <span>Height</span>
                    <input
                      type="number"
                      name="secondary_button_box_line_height"
                      value={styleGuide.secondary_button_box_line_height}
                      min="1"
                      max="2.5"
                      step="0.1"
                      onChange={(e) => handleChangeEvent(e, ['secondary', 'box_line_height'])}
                    />
                  </div>
                  <div className={classnames(styles.styleOption, styles.extendWidth)}>
                    <span>Shadow</span>
                    <ShadowInput
                      value={styleGuide.secondary_button_box_shadow}
                      handleChange={handleChange}
                      handleChangeEvent={handleChangeEvent}
                      name="secondary_button_box_shadow"
                      path={['secondary', 'box_shadow']}
                    />
                  </div>
                </>
              ) : activeBackground.secondary === 'dark' ? (
                <>
                  <div className={styles.styleOption}>
                    <span>Background color</span>
                    <ColorSelector
                      name="secondary_button_dark_bg_box_background_color"
                      onChange={(value) => handleChange(['secondary', 'dark_bg_box_background_color'], value)}
                      value={styleGuide.secondary_button_dark_bg_box_background_color}
                      placeholder="Color"
                      handleColorSelection={(value) =>
                        handleChange(['secondary', 'dark_bg_box_background_color'], value)
                      }
                    />
                  </div>
                  <div className={classnames(styles.styleOption, styles.extendWidth)}>
                    <span>Box shadow</span>
                    <ShadowInput
                      value={styleGuide.secondary_button_dark_bg_box_shadow}
                      handleChange={handleChange}
                      handleChangeEvent={handleChangeEvent}
                      name="secondary_button_dark_bg_box_shadow"
                      path={['secondary', 'dark_bg_box_shadow']}
                    />
                  </div>
                </>
              ) : (
                <div className={styles.styleOption}>
                  <span>Height</span>
                  <input
                    type="number"
                    name="secondary_button_box_line_height_mobile"
                    value={
                      styleGuide.secondary_button_box_line_height_mobile || styleGuide.secondary_button_box_line_height
                    }
                    min="1"
                    max="2.5"
                    step="0.1"
                    onChange={(e) => handleChangeEvent(e, ['secondary', 'mobile', 'box_line_height'])}
                  />
                </div>
              )
            ) : null}
            {activeStyleTabs.secondary === 'border' ? (
              activeBackground.secondary === 'light' ? (
                <>
                  <div className={styles.styleOption}>
                    <span>Border Width</span>
                    <input
                      type="number"
                      name="secondary_button_box_border_width"
                      value={styleGuide.secondary_button_box_border_width}
                      min="0"
                      max="5"
                      step="1"
                      onChange={(e) => handleChangeEvent(e, ['secondary', 'box_border_width'])}
                    />
                  </div>
                  <div className={styles.styleOption}>
                    <span>Border Color</span>
                    <ColorSelector
                      name="secondary_button_box_border_color"
                      onChange={(value) => handleChange(['secondary', 'box_border_color'], value)}
                      value={styleGuide.secondary_button_box_border_color}
                      placeholder="Color"
                      handleColorSelection={(value) => handleChange(['secondary', 'box_border_color'], value)}
                    />
                  </div>
                  <div className={styles.styleOption}>
                    <span>Border Radius</span>
                    <input
                      type="number"
                      name="secondary_button_box_border_radius"
                      value={styleGuide.secondary_button_box_border_radius}
                      min="0"
                      step="1"
                      onChange={(e) => handleChangeEvent(e, ['secondary', 'box_border_radius'])}
                    />
                  </div>
                </>
              ) : activeBackground.secondary === 'dark' ? (
                <>
                  <div className={styles.styleOption}>
                    <span>Border Width</span>
                    <input
                      type="number"
                      name="secondary_button_dark_bg_box_border_width"
                      value={styleGuide.secondary_button_dark_bg_box_border_width}
                      min="0"
                      max="5"
                      step="1"
                      onChange={(e) => handleChangeEvent(e, ['secondary', 'dark_bg_box_border_width'])}
                    />
                  </div>
                  <div className={styles.styleOption}>
                    <span>Border Color</span>
                    <ColorSelector
                      name="secondary_button_dark_bg_box_border_color"
                      onChange={(value) => handleChange(['secondary', 'dark_bg_box_border_color'], value)}
                      value={styleGuide.secondary_button_dark_bg_box_border_color}
                      placeholder="Color"
                      handleColorSelection={(value) => handleChange(['secondary', 'dark_bg_box_border_color'], value)}
                    />
                  </div>
                </>
              ) : (
                <div className={styles.styleOption}>
                  <span>Border Radius</span>
                  <input
                    type="number"
                    name="secondary_button_box_border_radius_mobile"
                    value={
                      styleGuide.secondary_button_box_border_radius_mobile ||
                      styleGuide.secondary_button_box_border_radius
                    }
                    min="0"
                    step="1"
                    onChange={(e) => handleChangeEvent(e, ['secondary', 'mobile', 'box_border_radius'])}
                  />
                </div>
              )
            ) : null}
            {activeStyleTabs.secondary === 'hover' && activeBackground.secondary !== 'mobile' && (
              <>
                <div className={styles.styleOption}>
                  <span>Background Color</span>
                  <ColorSelector
                    name={
                      activeBackground.secondary === 'dark'
                        ? 'secondary_button_dark_bg_hover_background_color'
                        : 'secondary_button_hover_background_color'
                    }
                    onChange={(value) =>
                      handleChange(
                        [
                          'secondary',
                          ...(activeBackground.secondary === 'dark'
                            ? ['dark_bg_hover_background_color']
                            : ['hover_background_color']),
                        ],
                        value
                      )
                    }
                    value={
                      activeBackground.secondary === 'dark'
                        ? styleGuide.secondary_button_dark_bg_hover_background_color
                        : styleGuide.secondary_button_hover_background_color
                    }
                    placeholder="Color"
                    handleColorSelection={(value) =>
                      handleChange(
                        [
                          'secondary',
                          ...(activeBackground.secondary === 'dark'
                            ? ['dark_bg_hover_background_color']
                            : ['hover_background_color']),
                        ],
                        value
                      )
                    }
                  />
                </div>
                <div className={styles.styleOption}>
                  <span>Border Color</span>
                  <ColorSelector
                    name={
                      activeBackground.secondary === 'dark'
                        ? 'secondary_button_dark_bg_hover_border_color'
                        : 'secondary_button_hover_border_color'
                    }
                    onChange={(value) =>
                      handleChange(
                        [
                          'secondary',
                          ...(activeBackground.secondary === 'dark'
                            ? ['dark_bg_hover_border_color']
                            : ['hover_border_color']),
                        ],
                        value
                      )
                    }
                    value={
                      activeBackground.secondary === 'dark'
                        ? styleGuide.secondary_button_dark_bg_hover_border_color
                        : styleGuide.secondary_button_hover_border_color
                    }
                    placeholder="Color"
                    handleColorSelection={(value) =>
                      handleChange(
                        [
                          'secondary',
                          ...(activeBackground.secondary === 'dark'
                            ? ['dark_bg_hover_border_color']
                            : ['hover_border_color']),
                        ],
                        value
                      )
                    }
                  />
                </div>
                <div className={styles.styleOption}>
                  <span>Text color</span>
                  <ColorSelector
                    name={
                      activeBackground.secondary === 'dark'
                        ? 'secondary_button_dark_bg_hover_text_color'
                        : 'secondary_button_hover_text_color'
                    }
                    onChange={(value) =>
                      handleChange(
                        [
                          'secondary',
                          ...(activeBackground.secondary === 'dark'
                            ? ['dark_bg_hover_text_color']
                            : ['hover_text_color']),
                        ],
                        value
                      )
                    }
                    value={
                      activeBackground.secondary === 'dark'
                        ? styleGuide.secondary_button_dark_bg_hover_text_color
                        : styleGuide.secondary_button_hover_text_color
                    }
                    placeholder="Color"
                    handleColorSelection={(value) =>
                      handleChange(
                        [
                          'secondary',
                          ...(activeBackground.secondary === 'dark'
                            ? ['dark_bg_hover_text_color']
                            : ['hover_text_color']),
                        ],
                        value
                      )
                    }
                  />
                </div>
                <div className={classnames(styles.styleOption, styles.extendWidth)}>
                  <span>Text Shadow</span>
                  <ShadowInput
                    value={
                      activeBackground.secondary === 'dark'
                        ? styleGuide.secondary_button_dark_bg_hover_text_shadow
                        : styleGuide.secondary_button_hover_text_shadow
                    }
                    handleChange={handleChange}
                    handleChangeEvent={handleChangeEvent}
                    name={
                      activeBackground.secondary === 'dark'
                        ? 'secondary_button_dark_bg_hover_text_shadow'
                        : 'secondary_button_hover_text_shadow'
                    }
                    path={[
                      'secondary',
                      ...(activeBackground.secondary === 'dark'
                        ? ['dark_bg_hover_text_shadow']
                        : ['hover_text_shadow']),
                    ]}
                  />
                </div>
                <div className={classnames(styles.styleOption, styles.extendWidth)}>
                  <span>Shadow</span>
                  <ShadowInput
                    value={
                      activeBackground.secondary === 'dark'
                        ? styleGuide.secondary_button_dark_bg_hover_shadow
                        : styleGuide.secondary_button_hover_shadow
                    }
                    handleChange={handleChange}
                    handleChangeEvent={handleChangeEvent}
                    name={
                      activeBackground.secondary === 'dark'
                        ? 'secondary_button_dark_bg_hover_shadow'
                        : 'secondary_button_hover_shadow'
                    }
                    path={[
                      'secondary',
                      ...(activeBackground.secondary === 'dark' ? ['dark_bg_hover_shadow'] : ['hover_shadow']),
                    ]}
                  />
                </div>
                <div className={styles.styleOption}>
                  <span>Opacity</span>
                  <input
                    type="number"
                    name={
                      activeBackground.secondary === 'dark'
                        ? 'secondary_button_dark_bg_hover_opacity'
                        : 'secondary_button_hover_opacity'
                    }
                    value={
                      activeBackground.secondary === 'dark'
                        ? styleGuide.secondary_button_dark_bg_hover_opacity
                        : styleGuide.secondary_button_hover_opacity
                    }
                    min="0"
                    max="1"
                    step="0.05"
                    onChange={(e) =>
                      handleChangeEvent(e, [
                        'secondary',
                        ...(activeBackground.secondary === 'dark' ? ['dark_bg_hover_opacity'] : ['hover_opacity']),
                      ])
                    }
                  />
                </div>
              </>
            )}
          </div>
        </section>
      </article>

      {/*   Action Link Button    */}
      <article className={styles.StyleGuideEditor}>
        <section className={styles.styleSection}>
          <div className={styles.styleSectionHeader}>
            <p>Action Link</p>
            <div className={styles.bgToggles}>
              <div className={styles.background}>
                <span
                  className={classnames(styles.lightBackground, {
                    [styles.active]: activeBackground.actionLink === 'light',
                  })}
                  onClick={() => setActiveBackground({ ...activeBackground, actionLink: 'light' })}
                >
                  Light
                </span>
                <span
                  className={classnames(styles.darkBackground, {
                    [styles.active]: activeBackground.actionLink === 'dark',
                  })}
                  onClick={() =>
                    setActiveBackground({
                      ...activeBackground,
                      actionLink: 'dark',
                    })
                  }
                >
                  Dark
                </span>
              </div>

              <div className={styles.device}>
                <span
                  onClick={() => setActiveBackground({ ...activeBackground, actionLink: 'light' })}
                  className={classnames(styles.lightBackground, {
                    [styles.active]: activeBackground.actionLink === 'light',
                  })}
                >
                  <SvgIconDesktopThumbnail />
                </span>
                <span
                  onClick={() => {
                    setActiveBackground({ ...activeBackground, actionLink: 'mobile' });
                    if (activeStyleTabs.actionLink === 'hover')
                      setActiveStyleTabs({ ...activeStyleTabs, actionLink: 'text' });
                  }}
                  className={classnames(styles.darkBackground, {
                    [styles.active]: activeBackground.actionLink === 'mobile',
                  })}
                >
                  <SvgIconMobileThumbnail />
                </span>
              </div>
            </div>
          </div>
          <div
            style={{
              ...(activeBackground.actionLink === 'dark' && {
                padding: '1rem 2rem 0.1rem',
                backgroundColor: darkBgColor.code,
                borderRadius: '2px',
                marginBottom: '2rem',
              }),
            }}
          >
            <div className="eps sriracha">
              <div
                className={`text-action ${
                  activeBackground.actionLink === 'dark'
                    ? 'dark-bg'
                    : activeBackground.actionLink === 'mobile'
                    ? 'mobile'
                    : ''
                }`}
              >
                View pricing
              </div>
            </div>
          </div>
          <div className={styles.optionsHeader}>
            <span
              onClick={() => setActiveStyleTabs({ ...activeStyleTabs, actionLink: 'text' })}
              className={classnames({
                [styles.activeStyleTab]: activeStyleTabs.actionLink === 'text',
              })}
            >
              Text
            </span>
            <span
              onClick={() => setActiveStyleTabs({ ...activeStyleTabs, actionLink: 'arrow' })}
              className={classnames({
                [styles.activeStyleTab]: activeStyleTabs.actionLink === 'arrow',
              })}
            >
              Arrow
            </span>
            {activeBackground.actionLink !== 'mobile' && (
              <span
                onClick={() => setActiveStyleTabs({ ...activeStyleTabs, actionLink: 'hover' })}
                className={classnames({
                  [styles.activeStyleTab]: activeStyleTabs.actionLink === 'hover',
                })}
              >
                Hover
              </span>
            )}
          </div>
          <div className={styles.styleSectionOptions}>
            {activeStyleTabs.actionLink === 'text' ? (
              activeBackground.actionLink === 'light' ? (
                <>
                  <div className={styles.styleOption}>
                    <span>Font family</span>
                    <Select
                      options={fontFamilyOptions}
                      defaultValue={fontFamilyOptions[styleGuide.action_link_button_font_family_index]}
                      value={fontFamilyOptions[styleGuide.action_link_button_font_family_index]}
                      onChange={(option) => handleChange(['action_link', 'font_family_index'], option.value)}
                    />
                  </div>
                  <div className={styles.styleOption}>
                    <span>Font Weight</span>
                    <Select
                      options={fontWeightOptions}
                      defaultValue={fontWeightOptions.find(
                        (weight) => weight.value === Number(styleGuide.action_link_button_font_weight)
                      )}
                      value={fontWeightOptions.find(
                        (weight) => weight.value === Number(styleGuide.action_link_button_font_weight)
                      )}
                      onChange={(option) => handleChange(['action_link', 'font_weight'], option.value)}
                    />
                  </div>
                  <div className={styles.styleOption}>
                    <span>Font size</span>
                    <input
                      type="number"
                      name="action_link_button_font_size"
                      value={styleGuide.action_link_button_font_size}
                      min="0.8"
                      max="2.5"
                      step="0.1"
                      onChange={(e) => handleChangeEvent(e, ['action_link', 'font_size'])}
                    />
                  </div>
                  <CapitalizeSelect
                    value={styleGuide.action_link_button_text_transform}
                    handleChange={(value) => handleChange(['action_link', 'text_transform'], value)}
                  />
                  <LetterSpacingField
                    name="action_link_button_letter_spacing"
                    value={styleGuide.action_link_button_letter_spacing}
                    handleChange={(value) => handleChange(['action_link', 'letter_spacing'], value)}
                  />
                  <div className={styles.styleOption}>
                    <span>Text color</span>
                    <ColorSelector
                      name="action_link_button_text_color"
                      onChange={(value) => handleChange(['action_link', 'text_color'], value)}
                      value={styleGuide.action_link_button_text_color}
                      placeholder="Color"
                      handleColorSelection={(value) => handleChange(['action_link', 'text_color'], value)}
                    />
                  </div>
                </>
              ) : activeBackground.actionLink === 'dark' ? (
                <>
                  <div className={styles.styleOption}>
                    <span>Text color</span>
                    <ColorSelector
                      name="action_link_button_dark_bg_text_color"
                      onChange={(value) => handleChange(['action_link', 'dark_bg_text_color'], value)}
                      value={styleGuide.action_link_button_dark_bg_text_color}
                      placeholder="Color"
                      handleColorSelection={(value) => handleChange(['action_link', 'dark_bg_text_color'], value)}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className={styles.styleOption}>
                    <span>Font size</span>
                    <input
                      type="number"
                      name="action_link_button_font_size_mobile"
                      value={styleGuide.action_link_button_font_size_mobile}
                      min="0.8"
                      max="2.5"
                      step="0.1"
                      onChange={(e) => handleChangeEvent(e, ['action_link', 'mobile', 'font_size'])}
                    />
                  </div>
                  <LetterSpacingField
                    name="action_link_button_letter_spacing_mobile"
                    value={
                      styleGuide.action_link_button_letter_spacing_mobile ||
                      styleGuide.action_link_button_letter_spacing
                    }
                    handleChange={(value) => handleChange(['action_link', 'mobile', 'letter_spacing'], value)}
                  />
                </>
              )
            ) : null}
            {activeStyleTabs.actionLink === 'arrow' ? (
              activeBackground.actionLink === 'light' ? (
                <>
                  <div className={styles.styleOption}>
                    <span>Color</span>
                    <ColorSelector
                      name="action_link_button_arrow_color"
                      onChange={(value) => handleChange(['action_link', 'arrow_color'], value)}
                      value={styleGuide.action_link_button_arrow_color}
                      placeholder="Color"
                      handleColorSelection={(value) => handleChange(['action_link', 'arrow_color'], value)}
                    />
                  </div>
                  <div className={styles.styleOption}>
                    <span>Size</span>
                    <input
                      type="number"
                      name="action_link_button_arrow_size"
                      value={styleGuide.action_link_button_arrow_size}
                      min="0.1"
                      max="1"
                      step="0.1"
                      onChange={(e) => handleChangeEvent(e, ['action_link', 'arrow_size'])}
                    />
                  </div>
                </>
              ) : activeBackground.actionLink === 'dark' ? (
                <>
                  <div className={styles.styleOption}>
                    <span>Color</span>
                    <ColorSelector
                      name="action_link_button_dark_bg_arrow_color"
                      onChange={(value) => handleChange(['action_link', 'dark_bg_arrow_color'], value)}
                      value={styleGuide.action_link_button_dark_bg_arrow_color}
                      placeholder="Color"
                      handleColorSelection={(value) => handleChange(['action_link', 'dark_bg_arrow_color'], value)}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className={styles.styleOption}>
                    <span>Size</span>
                    <input
                      type="number"
                      name="action_link_button_arrow_size_mobile"
                      value={
                        styleGuide.action_link_button_arrow_size_mobile || styleGuide.action_link_button_arrow_size
                      }
                      min="0.1"
                      max="1"
                      step="0.1"
                      onChange={(e) => handleChangeEvent(e, ['action_link', 'mobile', 'arrow_size'])}
                    />
                  </div>
                </>
              )
            ) : null}
            {activeStyleTabs.actionLink === 'hover' && activeBackground.actionLink !== 'mobile' && (
              <>
                <div className={styles.styleOption}>
                  <span>Color</span>
                  <ColorSelector
                    name={
                      activeBackground.actionLink === 'dark'
                        ? 'action_link_button_dark_bg_hover_color'
                        : 'action_link_button_hover_color'
                    }
                    onChange={(value) =>
                      handleChange(
                        [
                          'action_link',
                          ...(activeBackground.actionLink === 'dark' ? ['dark_bg_hover_color'] : ['hover_color']),
                        ],
                        value
                      )
                    }
                    value={
                      activeBackground.actionLink === 'dark'
                        ? styleGuide.action_link_button_dark_bg_hover_color
                        : styleGuide.action_link_button_hover_color
                    }
                    placeholder="Color"
                    handleColorSelection={(value) =>
                      handleChange(
                        [
                          'action_link',
                          ...(activeBackground.actionLink === 'dark' ? ['dark_bg_hover_color'] : ['hover_color']),
                        ],
                        value
                      )
                    }
                  />
                </div>
                <div className={styles.styleOption}>
                  <span>Opacity</span>
                  <input
                    type="number"
                    name={
                      activeBackground.actionLink === 'dark'
                        ? 'action_link_button_dark_bg_hover_opacity'
                        : 'action_link_button_hover_opacity'
                    }
                    value={
                      activeBackground.actionLink === 'dark'
                        ? styleGuide.action_link_button_dark_bg_hover_opacity
                        : styleGuide.action_link_button_hover_opacity
                    }
                    min="0"
                    max="1"
                    step="0.05"
                    onChange={(e) =>
                      handleChangeEvent(e, [
                        'action_link',
                        ...(activeBackground.actionLink === 'dark' ? ['dark_bg_hover_opacity'] : ['hover_opacity']),
                      ])
                    }
                  />
                </div>
              </>
            )}
          </div>
        </section>
      </article>
    </>
  );
};
